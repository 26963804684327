import React, { useState, useContext } from 'react';
// Components.
import PatientResults from '../../Search/PatientResults';
// Utils.
import { checkInput } from '../../../utils/globalUtils';
// Context.
import { appointmentContext } from '../../../context/appointmentContext';
// Firebase.
import firebase from '../../../firebase';

const firebaseRef = firebase.firestore();

function Step1(props) {
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState('');
    const [data, setData] = useState([]);
    const [patient, setPatient] = useState();

    // Context.
    let appointmentCon = useContext(appointmentContext);
    let { dispatch, state } = appointmentCon;

    const submitSearch = async (e) => {
        e.preventDefault();
        
        setLoading(true);
        setPatient();

        let query = firebaseRef.collection('patients');

        switch(checkInput(filter)) {
            case 'number':
                // console.log('number')
                query = query.where('phone', '==', filter);
                break;
            case 'email':
                // console.log('email');
                query = query.where('email', '==', filter);
                break;
            case 'name':
                // console.log('name');
                const searchTerm = filter.toUpperCase();
                let searchWords = searchTerm.split(" ").join("");
                query = query.where('searchIndex', 'array-contains', searchWords);
                break;
            case 'empty':
                // console.log('empty')
                setLoading(false);
                dispatch({ type: 'SET_SEARCH_DATA', params: [] });
                return;
        }

        let doc = await query.get();
        let list = [];

        if (!doc.empty) {
            for await (const item of doc.docs) {
                list.push(item.data());
            }
        }

        dispatch({ type: 'SET_SEARCH_DATA', params: list });
        setLoading(false);
    }

    const selectPatient = (patient) => {
        let selectDoctor = {};

        if (patient.doctor !== null && props.doctors[props.doctors.findIndex(el => el.uuid === patient.doctor)]) {
            let doctor = props.doctors[props.doctors.findIndex(el => el.uuid === patient.doctor)];
            let details;

            if (doctor.lname === null) {
                details = doctor.fname
            }else {
                details = doctor.fname + " " + doctor.lname;
            }

            selectDoctor = {
                value: patient.doctor,
                label: details,
                paymentType: doctor.paymentType,
                paymentFee: doctor.paymentFee
            }
        }
        dispatch({ type: 'SELECT_PATIENT', params: { patient: patient, doctor: selectDoctor } });
    }

    return (
        <div>
            <form onSubmit={(e) => submitSearch(e)}>
                <div className="form-row mt-4">

                    <div className="input-group col">
                        <input type="text" 
                            className="form-control" 
                            placeholder="Patient's information"
                            id="app-search-input" 
                            aria-label="Patient's information" 
                            aria-describedby="basic-addon2" 
                            onInput={(e) => setFilter(e.target.value)} />

                        <div className="input-group-append">
                            {loading ? 
                                <button type="button" disabled className="btn btn-primary" style={{ padding: '.375rem .75rem' }}>
                                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                    Loading...
                                </button>
                            :
                                <button 
                                    className="btn btn-primary" 
                                    type="submit"
                                    style={{ padding: '.375rem .75rem' }}>Search</button>
                            }
                        </div>
                    </div>
                </div>
            </form>

            <PatientResults 
                data={state.searchData}
                mode="appointment"
                doctors={props.doctors}
                selectPatient={selectPatient}
                selected={state.selectedPatient?.uuid} />
        </div>
    )
}



export default Step1;