import React, { Component } from 'react';
import SettingsItem from './SettingsItem';

const menu = [
    {
        id: 1,
        title: 'Doctors',
        icon: 'las la-stethoscope'
    },
    {
        id: 2,
        title: 'Patients',
        icon: 'las la-user'
    },
    {
        id: 3,
        title: 'Suppliers',
        icon: 'las la-box'
    },
    {
        id: 4,
        title: 'Metrics',
        icon: 'las la-chart-bar'
    },
    {
        id: 5,
        title: 'Payments',
        icon: 'las la-credit-card'
    },
    {
        id: 6,
        title: 'User Accounts',
        icon: 'las la-users'
    }
]

class SettingsMenu extends Component {
    render() {
        return (
            <div className="d-flex flex-column">
                {menu.map(item => {
                    return (
                        <SettingsItem
                            key={item.id}
                            id={item.id} 
                            title={item.title}
                            icon={item.icon}
                            selected={this.props.selected === item.id ? true : false}
                            onClick={this.props.onClick} />
                    )
                })}
            </div>
        )
    }
}



export default SettingsMenu;