import React, { useState } from 'react';

function PINModal(props) {
    // Hooks.
    const [pin, setPIN] = useState('');
    const [error, setError] = useState(false);

    const submitPIN = () => {
        if (pin === "4652") {
            props.unlockTrigger();
            props.trigger();
            setError(false);
        }else {
            setError(true);
        }

        setPIN('');
    }

    return (
        <div>
            <div className={props.display ? "modal fade show" : "modal fade"} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div class="d-flex mb-4">
                                <p class="flex-grow-1 h1 font-weight-bold">Insert PIN</p>
                                <span class="close" onClick={() => props.trigger()}>×</span>
                            </div>
                            <small class="mb-4">Insert the 4-digit PIN to unlock all Metrics</small>
                            <div className="my-3">
                                <input 
                                    type="password" 
                                    className="form-control" 
                                    maxlength="4" 
                                    onInput={(e) => setPIN(e.target.value)}
                                    value={pin}>    
                                </input>
                                {error && <small class="text-danger">Wrong PIN entered!</small>}
                            </div>
                            <button type="button" className="btn btn-primary btn-sm mt-4" onClick={submitPIN}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default PINModal;