import React, { Component } from 'react';
import Select from 'react-select';
import firebase from '../../firebase';

const firebaseRef = firebase.firestore();

class SupplierModal extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            title: null,
            source: null,
            email: null,
            phone: null,
            phoneNumbers: null,
            propsNumbers: null,
            notes: null,
            balance: null,
            loading: false
        }

        this.baseState = this.state;
    }


    componentDidMount() {
        let modal = document.getElementById("myModal6");

        //Close modal on window click.
        window.onclick = (event) => {
            if (event.target === modal) {
                this.closeModal();
            }
        }
    }


    closeModal = () => {
        this.props.modalTrigger();
        this.setState(this.baseState);
    }


    updateInfo = (e, type) => {
        let value = e.target.value;

        if (type === 'title') {
            this.setState({ title: value });
        }else if (type === 'phone') {
            this.setState({ phone: value });
        }else if (type === 'email') {
            this.setState({ email: value });
        }else if (type === 'note') {
            this.setState({ notes: value });
        }else if (type === 'balance') {
            this.setState({ balance: value });
        }
    }


    updateSource = (info) => {
        this.setState({ source: info });
    }


    addPhoneNumber = () => {
        let phoneNumbers = this.state.phoneNumbers || [];
        
        if (this.state.phone !== null && this.state.phone.trim().length > 0) {
            phoneNumbers.push(this.state.phone);
            this.setState({ phoneNumbers: phoneNumbers, phone: '' });
        }
    }


    deletePhoneNumber = (index) => {
        let phoneNumbers = this.state.phoneNumbers;
        phoneNumbers.splice(index, 1);
        this.setState({ phoneNumbers: phoneNumbers });
    }


    saveSupplier = () => {
        this.setState({ loading: true });

        let data = {};

        let title = this.state.title;
        let source;

        if (this.state.source !== null) {
            source = this.state.source.value;
        }else {
            source = null;
        }

        //Check title.
        if (title !== null && title.trim().length > 0 && source !== null) {
            //Title & Source are okay.
            data.title = title;
            data.source = source;
            data.email = this.state.email;
            data.notes = this.state.notes;
            data.phone = this.state.phoneNumbers || [];
            data.timestamp_created = firebase.firestore.FieldValue.serverTimestamp();
            data.hasArrears = false;
            data.total_arrears = 0;

            const ref = firebaseRef.collection('suppliers').doc();
            const key = ref.id;

            data.uuid = key;

            ref.set(data).then(() => {
                this.setState({ loading: false });

                this.props.addSupplier(data);

                this.props.showToast('success', 'Supplier added!');

                this.closeModal();
            }).catch((error) => {
                console.log(error);

                this.props.showToast('error', 'There was an error...');
            })

        }else {
            alert('Please insert a valid Supplier name / Source!');

            this.setState({ loading: false });
        }
    }


    updateSupplier = () => {
        this.setState({ loading: true });

        let data = {};
        let supplierID = this.props.supplier.uuid;

        //Check title.
        if (this.state.title !== null && this.state.title.trim().length > 0) {
            //Change title.
            data.title = this.state.title;
        }
        
        //Check Source.
        if (this.state.source !== null) {
            data.source = this.state.source.value;
        }

        //Check Email.
        if (this.state.email !== null && this.state.email.trim().length > 0) {
            data.email = this.state.email;
        }

        //Check Notes.
        if (this.state.notes) {
            data.notes = this.state.notes;
        }


        if (this.state.balance) {
            let total_arrears = parseFloat(this.state.balance);
            data.total_arrears = -total_arrears;

            if (total_arrears >= 0) {
                data.hasArrears = false;
            }else {
                data.hasArrears = true;
            }
        }


        //Check phone numbers.
        data.phone = this.state.phoneNumbers;

        data.timestamp_updated = firebase.firestore.FieldValue.serverTimestamp();

        firebaseRef.collection('suppliers').doc(supplierID).update(data)
        .then(() => {
            this.props.updateSupplier(data, this.props.supplier, supplierID);
            this.props.showToast('success', 'Updated successfully');
            this.setState({ loading: false });
            this.closeModal();
        }).catch((error) => {
            this.props.showToast('error', 'There was an error...');
            console.log(error);
        })

    }


    
    render() {

        let sourceOptions = [];

        if (this.props.supplierSource !== null) {
            this.props.supplierSource.map(item => {
                sourceOptions.push({
                    value: item.uuid,
                    label: item.title
                })
            });
        }


        return (
            <div id="myModal6" class={this.props.display ? "modal show" : "modal"}>

            <div class="modal-content shadow">

                <div className="d-flex mb-4">
                    <p className="flex-grow-1 h1 font-weight-bold">{this.props.supplier ? "Edit Supplier" : "New Supplier"}</p>
                    <span class="close" onClick={this.closeModal}>&times;</span>
                </div>


                <div>
                    <div className='form-row'>
                        <div class="col">
                            <label>Supplier Name</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                placeholder="Supplier name"
                                required
                                value={this.props.supplier && this.state.title === null ? this.props.supplier.title : !this.state.title ? "" : this.state.title}
                                onInput={(e) => this.updateInfo(e, "title")} />
                        </div>


                        <div className="col">
                            <label>Source:</label>
                            <Select 
                                value={this.props.supplier && this.state.source === null ? sourceOptions[sourceOptions.findIndex(el => el.value === this.props.supplier.source)] : this.state.source}
                                options={sourceOptions}
                                placeholder="Please select a source option"
                                onChange={this.updateSource} />
                        </div>
                    </div>


                    <div className="form-row mt-4">
                        
                        <div className="col">
                            <label>Phone Number</label>
                            <div className="input-group mb-3">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Phone Number" 
                                    value={this.state.phone}
                                    onInput={(e) => this.updateInfo(e, "phone")} />

                                <div className="input-group-append">
                                    <button className="btn btn-primary" style={{ padding: '.375rem .75rem' }} type="button" onClick={this.addPhoneNumber}>Add New</button>
                                </div>
                            </div>

                            <ul className='list-group'>
                                {this.state.phoneNumbers === null && this.props.supplier && this.props.supplier.phone !== null ?
                                
                                    this.setState({ phoneNumbers: this.props.supplier.phone })
                            
                                : null}


                                {this.state.phoneNumbers !== null ?
                                    this.state.phoneNumbers.map((phone, index) => {
                                        return (
                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                {phone}
                                                <span className="badge badge-pill round-reset" onClick={() => this.deletePhoneNumber(index)}><i className='las la-times'></i></span>
                                            </li>
                                        )
                                    })

                                : null
                                }
                            </ul>
                        </div>


                        <div className="col">
                            <label htmlFor="fname">Email Address</label>
                            <input 
                                type="email" 
                                id="fname" 
                                class="form-control" 
                                placeholder="Email address"
                                value={this.props.supplier && this.state.email === null ? this.props.supplier.email : !this.state.email ? "" : this.state.email}
                                onInput={(e) => this.updateInfo(e, "email")} />
                        </div>

                        
                        {this.props.supplier ?
                            <div className="col-2">
                                <label htmlFor="balance">Balance</label>
                                <input 
                                    type="number" 
                                    id="balance" 
                                    class="form-control" 
                                    placeholder="Balance"
                                    value={this.props.supplier && this.state.balance === null ? -this.props.supplier.total_arrears : this.state.balance}
                                    onInput={(e) => this.updateInfo(e, "balance")} />
                            </div>
                    

                        : null

                        }
                    </div>



                    <div class="form-group mt-3">
                        <label>Additional notes</label>
                        <textarea 
                            className="form-control" 
                            style={{ resize: 'none' }} 
                            rows="3"
                            placeholder="Enter any additional notes here..."
                            value={this.props.supplier && this.state.notes === null ? this.props.supplier.notes : this.state.notes !== null ? this.state.notes : ''} 
                            onInput={(e) => this.updateInfo(e, 'note')}></textarea>
                    </div>

                    {this.state.loading ?
                            
                        <button class="btn btn-primary float-right" type="button" disabled>
                            <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>

                        : 
                        
                        this.props.supplier ?

                        <button type="button" className="btn btn-primary float-right" onClick={this.updateSupplier}>
                            Update Supplier
                        </button>

                        :

                        <button type="button" className="btn btn-primary float-right" onClick={this.saveSupplier}>
                            Save Supplier
                        </button>

                    }

                </div>


            </div>

            </div>
        )
    }
}



export default SupplierModal;