import React, { Component } from 'react';

class SupplierView extends Component {
    render() {
        return (
            <div className="card p-3 table-card">
                <table className="table table-borderless table-hover">
                    <thead>
                    <tr>
                        <th>Supplier</th>
                        <th>Phone number</th>
                        <th>Email address</th>
                        <th>Supplier Type</th>
                        <th className='text-center'>Balance</th>
                        <th className='text-center'>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.suppliers !== null ?

                        this.props.suppliers.map(supplier => {
                            return (
                                <tr>
                                    <td>{supplier.title}</td>
                                    <td>
                                        {supplier.phone.length > 1 ? 
                                            <select className="filter-select" style={{ fontWeight: 'normal' }}>
                                                {supplier.phone.map(el => {
                                                    return (
                                                        <option style={{ paddingLeft: 0 }}>{el}</option>
                                                    )
                                                })}
                                            </select>
                                        : supplier.phone.length === 1 ?
                                            supplier.phone
                                        : null
                                        }
                                    </td>
                                    <td>
                                        <a href={"mailto:" + supplier.email}>{supplier.email}</a>
                                    </td>
                                    <td>{this.props.supplierSource[this.props.supplierSource.findIndex(el => el.uuid === supplier.source)].title}</td>
                                    <td className='text-center'>{supplier.total_arrears ? -Math.round(supplier.total_arrears * 100) / 100 + "€" : "0€"}</td>
                                    <td className='text-center'>
                                        <i className="btn btn-sm btn-warning las la-pen" onClick={() => this.props.modalTrigger(supplier)}></i>

                                        {supplier.status === 'inactive' ?
                                            <i className="btn btn-sm btn-primary las la-history ml-3" onClick={() => this.props.reactivateSupplier(supplier.uuid)}></i>
                                        : 
                                            <i className="btn btn-sm btn-secondary las la-user-times ml-3" onClick={() => this.props.deactivateSupplier(supplier.uuid)}></i>
                                        }
                                    </td>
                                </tr>
                            )
                        })
                        
                        : null
                    }
    
                    </tbody>
                </table>
            </div>
        )
    }
}



export default SupplierView;