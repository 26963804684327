import firebase from '../firebase';

export const checkDoctorChanges = (appointment, state) => {
    return (appointment.doctor_uuid !== state.doctor.value);
}

export const financialsChanged = (appointment, state) => {
    let appointmentPayment = {
        cost: appointment.gross,
        amount_paid: appointment.amount_paid,
        paymentMethod: appointment.paymentMethod,
        paid: appointment.paid
    }

    let stateAppointment = {
        cost: parseFloat(state.payment.cost),
        amount_paid: parseFloat(state.payment.amount_paid),
        paymentMethod: state.payment.paymentMethod,
        paid: state.payment.paid
    }

    return (JSON.stringify(appointmentPayment) !== JSON.stringify(stateAppointment));
}

export const notesChanged = (appointment, state) => {
    return (appointment.notes !== state.notes);
}

export const paymentMethodChanged = (appointment, state) => {
    return (appointment.paymentMethod !== state.payment.paymentMethod.value);
}

export const dateChanged = (appointment, state) => {
    return (firebase.firestore.Timestamp.fromDate(new Date(state.appdate)).seconds !== appointment.date.seconds);
}