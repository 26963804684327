import React, { Component } from 'react';
import PatientResults from '../Search/PatientResults';
import Select from 'react-select';

class PatientSearch extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            term: null,
            doctor: null,
            arrearsFilter: null
        }
    }
    
    updateTerm = (e) => {
        let term = e.target.value;

        if (term.length > 0) {
            this.setState({ term: term });
        }else {
            this.setState({ term: null });
        }

    }

    updateTermCategory = (value) => {
        if (value === "fname") {
            this.setState({ selectTermCategory: 'First Name' });
        }else if (value === "lname") {
            this.setState({ selectTermCategory: 'Last Name' });
        }else if (value === "phone") {
            this.setState({ selectTermCategory: 'Phone' });
        }else if (value === "email") {
            this.setState({ selectTermCategory: "Email" });
        }else if (value === null) {
            this.setState({ selectTermCategory: null });
        }

        this.setState({ termCategory: value });
    }

    updateFilters = (info) => {
        if (info.type === "doctor") {
            if (info.value === "reset") {
                this.setState({ doctor: null, selectDoctorCategory: null });
            }else {
                this.setState({ doctor: info.value, selectDoctorCategory: info });
            }
        }else if (info.type === "term") {
            if (info.value === "reset") {
                this.setState({ termCategory: null, selectTermCategory: null });
            }else {
                this.setState({ termCategory: info.value, selectTermCategory: info });
            }
        }else if (info.type === 'arrears') {
            if (info.value === null) {
                this.setState({ arrearsFilter: null });
            }else {
                this.setState({ arrearsFilter: info.value });
            }
        }
        
    }

    submitSearch = (e) => {
        this.props.submitSearch(e, this.state.term, this.state.doctor, this.state.arrearsFilter);
    }

    render() {
        let options = [{ value: 'reset', label: 'All', type: 'doctor' }];

        let arrearsOptions = [
            { value: null, label: 'All', type: 'arrears' },
            { value: true, label: 'Yes', type: 'arrears' },
            { value: false, label: 'No', type: 'arrears' }
        ];

        if (this.props.doctors !== null) {
            this.props.doctors.map(item => {
                let name = '';

                if (item.lname === null) {
                    name = item.fname;
                }else {
                    name = item.fname + " " + item.lname;
                }

                options.push({
                    value: item.uuid,
                    label: name,
                    type: 'doctor'
                })
            })
        }


        return (
            <div>
                <div className="card p-3 table-card">
                    <form onSubmit={(e) => this.submitSearch(e)}>
                        <div className="form-row">

                        <div className="col">
                            <label for="test">Search term:</label>
                            <div className="input-group">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="test" 
                                    aria-label="Text input with dropdown button"
                                    onInput={(e) => this.updateTerm(e)} />

                            </div>
                        </div>


                        <div className="col">
                            <label>Doctor:</label>
                            <Select 
                                options={options}
                                placeholder="Please select a doctor"
                                onChange={this.updateFilters} />
                        </div>


                        <div className="col">
                            <label>Has Arrears:</label>
                            <Select 
                                options={arrearsOptions}
                                placeholder="Choose an option"
                                onChange={this.updateFilters} />
                        </div>


                        <div className="col">
                            {this.props.loading ?
                                <div>
                                    <label style={{ opacity : 0 }}>I</label>
                                    <br></br>
                                    <button type="button" disabled className="btn btn-primary float-right">
                                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </button>
                                </div>
                        
                                :
                                <div>
                                    <label style={{ opacity : 0 }}>I</label>
                                    <br></br>
                                    <button type="submit" className="btn btn-primary float-right">Search</button>
                                </div>
                            }
                        </div>


                        </div>
                    </form>


                    <PatientResults 
                        data={this.props.searchData}
                        doctors={this.props.doctors}
                        modalTrigger={this.props.modalTrigger}
                        deletePatient={this.props.deletePatient}
                        triggerHistoryModal={this.props.triggerHistoryModal} />


                </div>

            </div>
        )
    }
}



export default PatientSearch;