import React, { useState, useContext } from 'react';
import Select from 'react-select';
import Toggle from 'react-toggle';
// Context.
import { appointmentContext } from '../../../context/appointmentContext';


function Step3(props) {    
    let appointmentCon = useContext(appointmentContext);
    let { dispatch, state } = appointmentCon;

    let paymentOptions = [];

    if (props.paymentMethods !== null) {
        props.paymentMethods.map(el => {
            paymentOptions.push({
                value: el.uuid,
                label: el.title
            })
        })
    }

    const setPaymentTitle = (title) => {
        switch(title) {
            case 'wage':
                return 'Wage (Doctor receives no fee)'
            case 'percentage':
                return 'Percentage'
            case 'fixed':
                return 'Fixed Fee'
            default:
                return ''
        }
    }

    const setDoctorPaymentTitle = (fee, type, cost) => {
        switch(type) {
            case 'wage':
                return 0;
            case 'percentage':
                return `${(parseFloat(fee) / 100) * cost} € (equal to ${parseFloat(fee)}% of the cost of appointment)`
            case 'fixed':
                return `${fee} €`
        }
    }

    const setClinicPaymentTitle = (fee, type, amount_paid, cost) => {
        switch(type) {
            case 'wage':
                return `${amount_paid} €`
            case 'percentage':
                let result;
                let doctorFee = ((parseFloat(fee) / 100) * cost);
                if (amount_paid > doctorFee) {
                    result = amount_paid - doctorFee;
                }else {
                    result = 0
                }
                return `${result} € (Doctor receives their full fee. If our income is 0, then patient has arrears with the clinic)`
            case 'fixed':
                let result1 = parseFloat(amount_paid) > parseFloat(fee) ? parseFloat(amount_paid) - parseFloat(fee) : 0;
                return `${result1} € (Doctor receives their full fee. If our income is 0, then patient has arrears with the clinic)`
        }
    }

    // console.log(state)

    return (
        <div className="row">
            {/* First Col */}
            <div className="col-6">
                <span className="h4 ml-3 font-weight-bold">Set Payment Details</span>

                <div className="form-row col mt-4">
                    <div className="form-group col-5">
                        <label for="exampleInputEmail1">Appointment Cost (€)</label>
                        <input 
                            type="number" 
                            className="form-control" 
                            id="exampleInputEmail1"
                            value={state.payment.cost}
                            required
                            onInput={(e) => dispatch({ type: 'SET_AMOUNT', params: { type: 'cost', data: e.target.value } })} 
                            />
                    </div>


                    <div className="col">
                        <label className="mb-2">Did the patient pay <span className="font-weight-bold">exactly {state.payment.cost + ' €'}</span> ?</label>
                        <br></br>
                        <Toggle 
                            defaultChecked={true}
                            value={state.payment.paid}
                            onChange={(e) => dispatch({ type: 'SET_AMOUNT', params: { type: 'paid', data: e.target.checked } })} />
                    </div>
                </div>

                {!state.payment.paid && 
                    <div className="form-row col my-2">
                        <div className="form-group col">
                            <label for="exampleInputEmail1">Amount Paid (€)</label>
                            <input 
                                type="number" 
                                className="form-control" 
                                id="exampleInputEmail1"
                                value={state.payment.amount_paid}
                                onInput={(e) => dispatch({ type: 'SET_AMOUNT', params: { type: 'amount_paid', data: e.target.value } })} 
                                />
                        </div>
                    </div>
                }

                {/* Payment Method */}
                <div className="form-row col">
                    <div className="col">
                        <label>Payment Method:</label>
                        <Select 
                            value={state.payment.paymentMethod}
                            options={paymentOptions}
                            placeholder="Please select a payment method"
                            onChange={(e) => dispatch({ type: 'SET_AMOUNT', params: { type: 'paymentMethod', data: e } })} 
                            />
                    </div>
                </div>
                {/* End Payment Method */}


                {/* Doctor's Fee */}
                <div className="form-row col">
                    {state.doctor?.paymentType === 'fixed' ? 
                        <div className="form-group col-6 mt-3">
                            <label for="exampleInputEmail1">Doctor's fee (€)</label>
                            <input 
                                type="number" 
                                className="form-control" 
                                id="exampleInputEmail1"
                                value={state.doctor.paymentFee}
                                required
                                onInput={(e) => dispatch({ type: 'SET_DOCTOR_PAYMENT', params: { type: 'paymentFee', data: e.target.value }}) } />
                        </div>
                
                    : state.doctor?.paymentType === 'percentage' &&

                        <div className="form-group col-6 mt-3">
                            <label for="exampleInputEmail1">Doctor's percentage (%)</label>
                            <input 
                                type="number" 
                                className="form-control" 
                                id="exampleInputEmail1"
                                value={state.doctor.paymentFee}
                                required
                                onInput={(e) => dispatch({ type: 'SET_DOCTOR_PAYMENT', params: { type: 'paymentFee', data: e.target.value }}) } />

                            <small>The clinic will get <b>{100 - parseFloat(state.doctor?.paymentFee)}%</b> of the amount paid.</small>
                        </div>

                    }


                    <div class="form-group col-6 mt-3">
                        <label for="exampleFormControlSelect1">Doctor's Payment Type</label>
                        <select class="form-control" id="exampleFormControlSelect1" value={state.doctor?.paymentType} disabled={props.type === 'edit'} onChange={(e) => dispatch({ type: 'SET_DOCTOR_PAYMENT', params: { type: 'paymentType', data: e.target.value } })}>
                            <option value="wage">Wage (No fee for doctor)</option>
                            <option value="percentage">Percentage</option>
                            <option value="fixed">Fixed Fee</option>
                        </select>
                    </div>

                </div>
                



            </div>
            {/* End of first Col */}



            {/* Second Col */}
            <div className="col-6">
                <span className="h4 ml-3 font-weight-bold">Payment Overview</span>

                <ul class="list-group list-group-flush mt-4">
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Appointment Cost
                        <span>{state.payment.cost + ' €'}</span>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Amount Paid
                        <span>{state.payment.paid ? state.payment.cost + ' €' : state.payment.amount_paid + ' €'}</span>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Payment Method
                        <span>{state.payment.paymentMethod?.label}</span>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Doctor's Payment Type
                        <span>{setPaymentTitle(state.doctor.paymentType)}</span>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Doctor's Fee
                        <span>{setDoctorPaymentTitle(state.doctor.paymentFee, state.doctor.paymentType, state.payment.cost)}</span>
                    </li>

                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Clinic Receives (Now)
                        <span>{setClinicPaymentTitle(state.doctor?.paymentFee, state.doctor?.paymentType, state.payment.paid ? state.payment.cost : state.payment.amount_paid, state.payment?.cost)}</span>
                    </li>

                    {/* <li class="list-group-item d-flex justify-content-between align-items-center">
                        Clinic Should Receive (eventually)
                        <span>{setClinicPaymentTitle(state.doctor?.paymentFee, state.doctor?.paymentType, state.payment.paid ? state.payment.cost : state.payment.amount_paid, state.payment?.cost)}</span>
                    </li> */}

                </ul>
            </div>
            {/* End of Second Col */}
        </div>
    )
}



export default Step3;