import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import Select from 'react-select';
import moment from 'moment';
import Swal from 'sweetalert2';
import firebase from '../../firebase';

const firebaseRef = firebase.firestore();


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3500,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})


class AppointmentsTable extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            from: null,
            to: null,
            doctorFilter: [],
            paymentFilter: null,
            arrearsFilter: null,
            loading: false,
            dateTitle: 'Today',
            rotated: false
        };
    }



    toggleRotate = () => {
        this.setState({ rotated: !this.state.rotated });
    }


    displayTimedError = (title) => {
        Toast.fire({
            icon: 'error',
            title: title
        })
    }


    setFilterDate = (date, type) => {
        if (type === "from") {
            this.setState({ from: date });
        }else if (type === "to") {
            this.setState({ to: date });
        }
    }


    updateSelectFilter = (info, type) => {
        if (type === 'doctor') {
            if (info === null) {
                this.setState({ doctorFilter: [] });
            }else {
                if (this.state.doctorFilter.length === 10 && info.length !== 0) {
                    alert('You can only choose up to 10 doctors.');
                }else {
                    this.setState({ doctorFilter: info });
                }
            }
        }else if (type === 'payment') {
            this.setState({ paymentFilter: info });
        }else if (type === 'arrears') {
            this.setState({ arrearsFilter: info });
        }
    }


    setDateTitle = () => {
        if (this.state.from !== null && this.state.to !== null) {
            let from = this.state.from;
            let to = this.state.to;

            if (new Date(from).getTime() === new Date(to).getTime(to)) {
                let title = moment(this.state.from).format('DD/MM/YYYY');
                this.setState({ dateTitle: title });
            }else {
                let title = moment(this.state.from).format('DD/MM/YYYY');
                title = title.concat(" - ");
                title = title.concat(moment(this.state.to).format('DD/MM/YYYY'));
                this.setState({ dateTitle: title });
            }
        }
    }


    searchData = () => {
        this.setState({ loading: true });

        if (this.state.from === null || this.state.to === null) {
            this.displayTimedError('Please enter both dates');
            this.setState({ loading: false });
            return;
        }

        let from = new Date(this.state.from);
        from.setHours(0,0,0,0);

        let to = new Date(this.state.to);
        to.setHours(23,59,59,999)


        if (from > to) {
            Toast.fire({
                icon: 'error',
                title: 'The second date has to be higher than the first.'
            })

            this.setState({ loading: false });
        }else {
            let query = firebaseRef.collection('appointments');

            //Date filters.
            query = query.where('date', '>=', from).where('date', '<=', to);


            //Doctor filter.
            if (this.state.doctorFilter.length > 0) {
                let doctors = [];

                this.state.doctorFilter.forEach(doc => {
                    doctors.push(doc.value);
                })

                query = query.where('doctor_uuid', 'in', doctors);
            }


            //Payment filter.
            if (this.state.paymentFilter !== null) {
                query = query.where('paymentMethod', '==', this.state.paymentFilter.value);
            }


            //Arrears filter.
            if (this.state.arrearsFilter !== null) {
                // console.log('arrears filters');

                if (this.state.arrearsFilter.value === 'yes') {
                    query = query.where('paid', '==', false);
                }else {
                    query = query.where('paid', '==', true);
                }
            }

            this.props.filterAppointments();

            query.get().then(snapshot => {
                this.setDateTitle();

                if (!snapshot.empty) {
                    snapshot.forEach(async (childSnapshot) => {
                        let data = childSnapshot.data();
                        
                        await data.patientRef.get().then((grandSnapshot) => {
                            let patientData = grandSnapshot.data();
                            data.patient = patientData;
                        });
            
                        await data.transactionRef.get().then((grandSnapshot) => {
                            let transData = grandSnapshot.data();
                            data.transaction = transData;
                            this.props.addAppointment(data);
                        });

                    })

                    this.setState({ loading: false });
                }else {
                    alert('No data found');
                    this.setState({ loading: false });
                }
            })
            
        }
    }


    removeFilter = (type) => {
        if (type === 'arrears') {
            this.setState({ arrearsFilter: null });
        }else if (type === 'payment') {
            this.setState({ paymentFilter: null });
        }else if (type === 'doctor') {
            this.setState({ doctorFilter: [] });
        }else if (type === 'from') {
            this.setState({ from: null });
        }else if (type === 'to') {
            this.setState({ to: null });
        }
    }


    render() {
        let options = [];
        let paymentOptions = [];
        let arrearsOptions = [
            { value: null, label: 'All' },
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' }
        ];


        // Doctors options.
        if (this.props.doctors !== null) {
            this.props.doctors.map(doc => {
                let name = '';

                if (doc.lname === null) {
                    name = doc.fname;
                }else {
                    name = doc.fname + " " + doc.lname;
                }

                options.push({
                    value: doc.uuid,
                    label: name,
                    paymentType: doc.paymentType,
                    paymentFee: doc.paymentFee
                })
            })
        }


        if (this.props.paymentMethods !== null) {
            this.props.paymentMethods.map(item => {
                if (item.type !== 'supplier' || item.type === undefined) {
                    paymentOptions.push({
                        value: item.uuid,
                        label: item.title
                    })
                }
            })
        }


        return (
            <div className="card p-3 table-card">

                <div className='d-flex align-items-center'>
                    <h3>Appointments for: <span className="font-weight-bold">{this.state.dateTitle}</span></h3>

                    <div className='flex-grow-1 px-4'>
                        {/* Filter badges */}
                                                
                        {this.state.from ? <span class="badge badge-pill badge-light">from {moment(this.state.from).format('D/M/YYYY')} <i className="las la-times filter-reset" onClick={() => this.removeFilter('from')}></i> </span> : null}

                        {this.state.to ? <span class="badge badge-pill badge-light">to {moment(this.state.to).format('D/M/YYYY')} <i className="las la-times filter-reset" onClick={() => this.removeFilter('to')}></i> </span> : null}

                        {this.state.doctorFilter ? this.state.doctorFilter.length > 0 ? <span class="badge badge-pill badge-light">{this.state.doctorFilter.length === 1 ? this.state.doctorFilter.length + " doctor" : this.state.doctorFilter.length + " doctors"} <i className="las la-times filter-reset" onClick={() => this.removeFilter('doctor')}></i> </span> : null : null}
                        
                        {this.state.paymentFilter !== null ? <span class="badge badge-pill badge-light">{this.state.paymentFilter.label} <i className="las la-times filter-reset" onClick={() => this.removeFilter('payment')}></i> </span> : null}
                        
                        {this.state.arrearsFilter !== null ? <span class="badge badge-pill badge-light">{this.state.arrearsFilter.value === 'yes' ? 'has arrears' : this.state.arrearsFilter.value === 'no' ? 'no arrears' : null} <i className="las la-times filter-reset" onClick={() => this.removeFilter('arrears')}></i> </span> : null}


                    </div>

                    <div style={{ cursor: "pointer" }}>
                        <span className='h5 pr-4' data-toggle="collapse" data-target="#appCollapse" aria-expanded="false" aria-controls="appCollapse" onClick={this.toggleRotate}>Filters <i className={this.state.rotated ? "las la-angle-right ml-2 rotate-90" : "las la-angle-right ml-2 no-rotation"}></i></span>
                    </div>
                </div>

                <div className="my-4">
                    <div className="collapse" id="appCollapse">
                        <div className="card card-body" style={{ backgroundColor: '#f6f8fa' }}>
                            
                            <div className='form-row'>

                                <div className="col">
                                    <p>From</p>
                                    <DayPickerInput 
                                        value={this.state.from}
                                        onDayChange={day => this.setFilterDate(day, "from")} />
                                </div>

                                <div className="col">
                                    <p>To</p>
                                    <DayPickerInput 
                                        value={this.state.to}
                                        onDayChange={day => this.setFilterDate(day, "to")} />
                                </div>


                                <div className="col">

                                    <p>Doctor</p>
                                    <div class="form-group">
                                        <Select
                                            isMulti
                                            name="doctors"
                                            options={options}
                                            value={this.state.doctorFilter}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(e) => this.updateSelectFilter(e, 'doctor')}
                                        />
                                    </div>

                                </div>


                                <div className="col">

                                    <p>Payment Method</p>
                                    <Select
                                        name="paymentMethods"
                                        options={paymentOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={(e) => this.updateSelectFilter(e, 'payment')} />

                                </div>


                                <div className="col">

                                    <p>Has Arrears</p>
                                    <Select
                                        name="hasArrears"
                                        options={arrearsOptions}
                                        value={this.state.arrearsFilter}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={(e) => this.updateSelectFilter(e, 'arrears')} />

                                </div>


                                <div className="col-1">
                                    <p style={{ visibility: 'hidden' }}>Actions</p>
                                    {this.state.loading ? 

                                        <button class="btn btn-primary" type="button" disabled>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </button>
                            
                                    : 
                                        <button type="button" class="btn btn-primary" onClick={this.searchData}>Set filters</button>                                    
                                    }
                                </div>


                            </div>

                        </div>
                    </div>
                </div>


                <table className="table table-borderless">
                    <thead>
                    <tr>
                        <th scope="row">Date</th>
                        <th scope="row">Patient Name</th>
                        <th scope="row">Phone number</th>
                        <th scope="row">Doctor</th>
                        <th scope="row">Payment Method</th>
                        <th scope="row">Cost</th>
                        <th scope="row">Paid</th>
                        <th scope="row">Actions</th>
                    </tr>
                    </thead>
                    <tbody>

                    {this.props.patients ?
                        this.props.patients.map(el=> {
                            return (
                                <tr key={el.uuid}>
                                    <td>
                                        {moment(el.date.toDate()).format('DD/MM/YYYY')}
                                    </td>
                                    <td>
                                        {el.patient.fname + " " + el.patient.lname}
                                    </td>
                                    <td>
                                        {el.patient.phone.constructor === Array ?

                                            el.patient.phone.length > 1 ? 
                                                <select className="filter-select" style={{ fontWeight: 'normal' }}>
                                                    {el.patient.phone.map(item => {
                                                        return (
                                                            <option style={{ paddingLeft: 0 }}>{item}</option>
                                                        )
                                                    })}
                                                </select>
                                            : el.patient.phone.length === 1 ?
                                                el.patient.phone
                                                
                                            : null

                                        : el.patient.phone
                                        }
                                    </td>
                                    <td>
                                        {el.doctor_uuid !== null ? 
                                            this.props.doctors[this.props.doctors.findIndex(doc => doc.uuid === el.doctor_uuid)] ?
                                            
                                                this.props.doctors[this.props.doctors.findIndex(doc => doc.uuid === el.doctor_uuid)].abbreviation
                                            
                                            : "INACTIVE"
                                            
                                        : null}
                                    </td>
                                    <td>
                                        {el.paymentMethod !== null ? this.props.paymentMethods[this.props.paymentMethods.findIndex(item => item.uuid === el.paymentMethod)].title : ""}
                                    </td>
                                    <td>
                                        {el.gross + "€"}
                                    </td>
                                    <td>
                                        {el.amount_paid + '€'}
                                    </td>
                                    <td>
                                        <i className="btn btn-sm btn-warning las la-pen" onClick={() => this.props.modalTrigger(el)}></i>
                                        <i className="btn btn-sm btn-danger las la-trash ml-3" onClick={() => this.props.deleteAppointment(el.uuid)}></i>
                                    </td>
                                </tr>
                            )
                        })
                        
                
                        : null
                    }
    
                    </tbody>
                </table>
            </div>
        )
    }
}



export default AppointmentsTable;