import React, { useContext } from 'react';
import Select from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
// Context.
import { appointmentContext } from '../../../context/appointmentContext';

function Step2(props) {
    // Context.
    let appointmentCon = useContext(appointmentContext);
    let { dispatch, state } = appointmentCon;

    // Doctors options.
    let options = [];
    if (props.doctors !== null) {
        props.doctors.map(doc => {
            let name = '';

            if (doc.lname === null) {
                name = doc.fname;
            }else {
                name = doc.fname + " " + doc.lname;
            }

            options.push({
                value: doc.uuid,
                label: name,
                paymentType: doc.paymentType,
                paymentFee: doc.paymentFee
            })
        })
    }

    return (
        <div>
            <div className="mb-4 form-row col">
                <div className="col">
                    <label for="appdate">Appointment date: <span className="font-weight-bold">{moment(state.appdate).format('dddd, MMMM Do')}</span></label>
                    <br></br>
                    <DayPickerInput 
                        value={state.appdate}
                        onDayChange={day => dispatch({ type: 'SET_DATE', params: day })} />
                </div>
                
                <div className="col">
                    <span>Doctor:</span>
                    <Select 
                        value={state.doctor}
                        options={options}
                        placeholder="Please select a doctor:"
                        onChange={(info) => dispatch({ type: 'SET_DOCTOR', params: info })} />
                </div>
            </div>

            <div class="form-group col mt-3">
                <label>Additional notes</label>
                <textarea 
                    className="form-control" 
                    style={{ resize: 'none' }} 
                    rows="3"
                    placeholder="Enter any additional notes here..."
                    value={state.notes} 
                    onInput={(e) => dispatch({ type: 'SET_NOTES', params: e.target.value })} />
            </div>

        </div>
    )
}



export default Step2;