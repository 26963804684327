import React from "react";
// Redux.
import { RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import { selectMenu } from "reducers/menuReducer";
// Components.
import Tooltip from "../Tooltip";
// Interface.
import { MenuItem } from "constants/menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  data: MenuItem;
  onPressListener?: () => void;
};

const NavbarItem: React.FC<Props> = (props) => {
  const { title, id, icon } = props.data;
  const { onPressListener } = props;

  const menu = useSelector((state: RootState) => state.menu.value);
  const dispatch = useDispatch();

  const trigger = () => {
    if (onPressListener) {
      onPressListener();
    } else {
      dispatch(selectMenu(id));
    }
  };

  return (
    <Tooltip label={title} placement="right">
      <div
        className={`navbar-item p-3 mb-4 rounded icon ${
          id === menu && "icon-primary menu-selected"
        }`}
        onClick={() => trigger()}
      >
        <FontAwesomeIcon icon={icon} size={"lg"} />
      </div>
    </Tooltip>
  );
};

export default NavbarItem;
