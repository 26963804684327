import React, { Component } from 'react';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import Swal from 'sweetalert2';
import Select from 'react-select';
import firebase from 'firebase';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3500,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})


let firebaseRef = firebase.firestore();


class SupplierTable extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            rotated: false,
            date: new Date(),
            supplierFilter: [],
            sourceFilter: [],
            from: null,
            to: null,
            arrearsFilter: null,
            arrearsOption: null,
            dateTitle: null
        }


        this.baseState = this.state;
    }


    componentWillUnmount() {
        //Did switch away from current tab or Menu option.
        
        // Reset data to this month like it loads initially. (Not sure if it's useful!)
        // this.props.loadSupplierData();
    }


    displayTimedError = (title) => {
        Toast.fire({
            icon: 'error',
            title: title
        })
    }


    updateFilters = (value, type) => {
        if (type === 'supplier') {
            if (value === null) {
                this.setState({ supplierFilter: [] });
            }else {
                if (this.state.supplierFilter.length === 10 && value.length !== 0) {
                    alert('You can only choose up to 10 suppliers!');
                }else {
                    this.setState({ supplierFilter: value, sourceFilter: [] });
                }
            }
        }else if (type === 'source') {
            if (value === null) {
                this.setState({ sourceFilter: [] });
            }else {
                if (this.state.sourceFilter.length === 10 && value.length !== 0) {
                    alert('You can only choose up to 10 supplier types!');
                }else {
                    this.setState({ sourceFilter: value, supplierFilter: [] });
                }
            }
        }else if (type === 'arrears') {
            this.setState({ arrearsOption: value });

            if (value.value === null) {
                this.setState({ arrearsFilter: null });
            }else if (value.value === 'yes') {
                this.setState({ arrearsFilter: true });
            }else {
                this.setState({ arrearsFilter: false });
            }
        }
    }


    updateArrears = (e) => {
        let value = e.target.checked;

        this.setState({ hasArrears: value });
    }


    setFilterDate = (date, type) => {
        if (type === "from") {
            this.setState({ from: date });
        }else if (type === "to") {
            this.setState({ to: date });
        }
    }


    setDateTitle = () => {
        if (this.state.from !== null && this.state.to !== null) {
            let from = this.state.from;
            let to = this.state.to;

            if (new Date(from).getTime() === new Date(to).getTime(to)) {
                let title = moment(this.state.from).format('DD/MM/YYYY');
                this.setState({ dateTitle: title });
            }else {
                let title = moment(this.state.from).format('DD/MM/YYYY');
                title = title.concat(" - ");
                title = title.concat(moment(this.state.to).format('DD/MM/YYYY'));
                this.setState({ dateTitle: title });
            }
        }
    }

    
    toggleRotate = () => {
        this.setState({ rotated: !this.state.rotated });
    }


    removeFilter = (type) => {
        if (type === 'from') {
            this.setState({ from: null });
        }else if (type === 'to') {
            this.setState({ to: null });
        }else if (type === 'supplier') {
            this.setState({ supplierFilter: [] });
        }else if (type === 'source') {
            this.setState({ sourceFilter: [] });
        }
    }


    searchData = () => {
        this.setState({ loading: true });

        if (this.state.from === null || this.state.to === null) {
            this.displayTimedError('Please enter both dates');
            this.setState({ loading: false });
            return;
        }

        let from = new Date(this.state.from);
        from.setHours(0,0,0,0);

        let to = new Date(this.state.to);
        to.setHours(23,59,59,999);


        if (from > to) {
            Toast.fire({
                icon: 'error',
                title: 'The second date has to be higher than the first.'
            })

            this.setState({ loading: false });
        }else {
            let query = firebaseRef.collection('transactions').where('type', '==', 'supplier');

            // Date filters.
            query = query.where('date', '>=', from).where('date', '<=', to);


            // Check for Supplier or Supplier Type filter.
            // Only ONE can exist.
            if (this.state.supplierFilter.length > 0) {
                //We have a supplier filter.
                let supplierFilter = [];

                this.state.supplierFilter.forEach(item => {
                    supplierFilter.push(item.value);
                })

                query = query.where('supplier', 'in', supplierFilter);
            }


            // Check if hasArrears.
            if (this.state.arrearsFilter !== null) {
                query = query.where('paid', '==', this.state.arrearsFilter);
            }


            // Set transactions on state to null on App.
            this.props.resetSupplierTransactions();


            query.get().then(snapshot => {
                // Set date title.
                this.setDateTitle();

                if (!snapshot.empty) {
                    snapshot.forEach(childSnapshot => {
                        let data = childSnapshot.data();

                        this.props.addSupplierTransaction(data, 'existing');
                    });

                    this.setState({ loading: false });
                }else {
                    alert('No data found');
                    this.setState({ loading: false });
                }
            });


        }

    }


    formatNumber = (data) => {
        return Math.round(data * 100) / 100;
    }

    showCurrentArrears = (arrears) => {
        if (arrears < 0) {
            return `+ ${this.formatNumber(-arrears)} €`
        }else if (arrears > 0) {
            return `- ${this.formatNumber(arrears)} €`
        }else {
            return '0 €';
        }
    }

    
    render() {
        let supplierOptions = [];
        let typeOptions = [];
        let arrearOptions = [
            {value: null, label: 'All'},
            {value: false, label: 'Did NOT pay in full'},
            {value: true, label: 'Paid in full'}
        ];

        if (this.props.suppliers !== null) {
            this.props.suppliers.map(item => {
                supplierOptions.push({
                    value: item.uuid,
                    label: item.title
                })
            })
        }


        if (this.props.supplierSource !== null) {
            this.props.supplierSource.map(item => {
                typeOptions.push({
                    value: item.uuid,
                    label: item.title
                })
            })
        }


        return (
            <div className="card p-3 table-card">
                <div className='d-flex align-items-center'>
                    <h3>Transactions for: <span className="font-weight-bold">{this.state.dateTitle === null ? moment(this.state.date).format('MMMM, YYYY') : this.state.dateTitle}</span></h3>

                    <div className='flex-grow-1 px-4'>
                        {/* Filter badges */}
                                                
                        {this.state.from ? <span class="badge badge-pill badge-light">from {moment(this.state.from).format('D/M/YYYY')} <i className="las la-times filter-reset" onClick={() => this.removeFilter('from')}></i> </span> : null}

                        {this.state.to ? <span class="badge badge-pill badge-light">to {moment(this.state.to).format('D/M/YYYY')} <i className="las la-times filter-reset" onClick={() => this.removeFilter('to')}></i> </span> : null}

                        {this.state.supplierFilter.length > 0 ? <span class="badge badge-pill badge-light">{this.state.supplierFilter.length === 1 ? this.state.supplierFilter.length + " supplier" : this.state.supplierFilter.length + " suppliers"} <i className="las la-times filter-reset" onClick={() => this.removeFilter('supplier')}></i> </span> : null}
                        
                        {this.state.sourceFilter.length > 0 ? <span class="badge badge-pill badge-light">{this.state.sourceFilter.length === 1 ? this.state.sourceFilter.length + " source" : this.state.sourceFilter.length + " sources"} <i className="las la-times filter-reset" onClick={() => this.removeFilter('source')}></i> </span> : null}

                    </div>

                    <div style={{ cursor: "pointer" }}>
                        <span className='h5 pr-4' data-toggle="collapse" data-target="#appCollapse" aria-expanded="false" aria-controls="appCollapse" onClick={this.toggleRotate}>Filters <i className={this.state.rotated ? "las la-angle-right ml-2 rotate-90" : "las la-angle-right ml-2 no-rotation"}></i></span>
                    </div>
                </div>

                <div className="my-4">
                    <div className="collapse" id="appCollapse">
                        <div className="card card-body" style={{ backgroundColor: '#f6f8fa' }}>
                            
                            <div className='form-row'>

                                <div className="col-2">
                                    <p>From</p>
                                    <DayPickerInput 
                                        value={this.state.from}
                                        onDayChange={day => this.setFilterDate(day, "from")} />
                                </div>

                                <div className="col-2">
                                    <p>To</p>
                                    <DayPickerInput 
                                        value={this.state.to}
                                        onDayChange={day => this.setFilterDate(day, "to")} />
                                </div>


                                <div className="col-2">
                                    <p>Supplier</p>
                                    <Select
                                        isMulti
                                        name="suppliers"
                                        options={supplierOptions}
                                        value={this.state.supplierFilter}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={(e) => this.updateFilters(e, 'supplier')} />
                                </div>


                                <div className="col-2">
                                    <p>Supplier paid?</p>
                                    <Select
                                        name="supplierPaid"
                                        options={arrearOptions}
                                        value={this.state.arrearsOption}
                                        classNamePrefix="select"
                                        onChange={(e) => this.updateFilters(e, 'arrears')} />
                                </div>



                                {/* <div className="col-2">
                                    <p>Supplier Type</p>
                                    <Select
                                        name="supplierSource"
                                        isMulti
                                        options={typeOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={this.state.sourceFilter}
                                        onChange={(e) => this.updateFilters(e, 'source')} />
                                </div> */}

                                <div className="col-2">
                                    <p style={{ visibility: 'hidden' }}>Actions</p>
                                    {this.state.loading ? 

                                        <button class="btn btn-primary" type="button" disabled>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </button>
                            
                                    : 
                                        <button type="button" class="btn btn-primary" onClick={this.searchData}>Set filters</button>                                    
                                    }
                                </div>


                            </div>

                        </div>
                    </div>
                </div>

                <table className="table table-borderless table-hover">
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Supplier</th>
                        <th>Payment</th>
                        <th>Supplier Type</th>
                        <th>Cost</th>
                        <th>Paid</th>
                        <th>Balance</th>
                        <th className='text-center'>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                        {this.props.data !== null ?
                            this.props.data.map(item => {
                                return (
                                    <tr key={item.uuid}>
                                        <td>{moment(item.date.toDate()).format('DD/MM/YYYY')}</td>
                                        <td>{this.props.suppliers[this.props.suppliers.findIndex(el => el.uuid === item.supplier)].title}</td>
                                        <td>
                                            {item.paymentType !== null && item.paymentType !== undefined ?
                                                this.props.paymentMethods[this.props.paymentMethods.findIndex(el => el.uuid === item.paymentType)].title
                                            : null
                                            }</td>
                                        <td>
                                            {this.props.supplierSource[this.props.supplierSource.findIndex(el => el.uuid === (this.props.suppliers[this.props.suppliers.findIndex(el => el.uuid === item.supplier)].source))].title}
                                        </td>
                                        <td>{item.amount + "€"}</td>
                                        <td>{item.amount_paid + "€"}</td>
                                        {/* <td>{this.formatNumber(-this.props.suppliers[this.props.suppliers.findIndex(el => el.uuid === item.supplier)].total_arrears) + " €"}</td> */}
                                        <td>{item.current_arrears !== undefined ? this.showCurrentArrears(item.current_arrears): ''}</td>
                                        <td className='text-center'>
                                            <i className="btn btn-sm btn-warning las la-pen" onClick={() => this.props.modalTrigger(item)}></i>
                                            <i className="btn btn-sm btn-danger las la-trash ml-3" onClick={() => this.props.deleteSupplierTransaction(item)}></i>
                                        </td>
                                    </tr>
                                )
                            })
                    
                        : null}
                    </tbody>
                </table>
            </div>
        )
    }
}



export default SupplierTable;