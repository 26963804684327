import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MenuState {
  value: number;
}

const initialState: MenuState = {
  value: 1,
};

export const menuSlice = createSlice({
  name: "menuSlice",
  initialState,
  reducers: {
    selectMenu: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { selectMenu } = menuSlice.actions;

export default menuSlice.reducer;
