import React, { Component, useState } from 'react';
import firebase from '../../firebase';
import Swal from 'sweetalert2';

const firebaseRef = firebase.firestore();

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
})

class DoctorModal extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            firstname: null,
            lastname: null,
            phone: null,
            email: null,
            docabbreviation: null,
            specialty: null,
            employmentType: null,
            paymentType: null,
            paymentFee: 0,
            feeEdited: false,
            loading: false
        }

        this.baseState = this.state;
    }
    

    componentDidMount() {
        let modal = document.getElementById("myModal1");

        //Close modal on window click.
        window.onclick = (event) => {
            if (event.target === modal) {
                this.closeModal();
            }
        }
    }


    updateInfo = (type, e) => {
        const info = e.target.value;

        if (type === "fname") {
            this.setState({ firstname: info }, () => { this.suggestAbbreviation() });
        }else if (type === "lname") {
            this.setState({ lastname: info }, () => { this.suggestAbbreviation() });
        }else if (type === "abb") {
            this.setState({ docabbreviation: info });
        }else if (type === "specialty") {
            this.setState({ specialty: info });
        }else if (type === "employment") {
            this.setState({ employmentType: info });
        }else if (type === "paymentType") {
            this.setState({ paymentType: info });
        }else if (type === "paymentFee") {
            this.setState({ paymentFee: info, feeEdited: true });
        }else if (type === "phone") {
            this.setState({ phone: info });
        }else if (type === "email") {
            this.setState({ email: info });
        }
        
    }


    closeModal = () => {
        this.props.modalTrigger();
        this.setState(this.baseState);
    }


    suggestAbbreviation = () => {
        let fname = this.state.firstname;
        let lname = this.state.lastname;

        if (fname && lname && fname.trim().length > 0 && lname.trim().length > 0) {
            //Both fields are filled with info.
            let firstChar = fname.charAt(0);
            let secondChar = lname.charAt(0);

            const finalAbb = firstChar.concat(secondChar);

            this.setState({ docabbreviation: finalAbb });
        }
    }



    submitInfo = (e) => {
        e.preventDefault();

        this.setState({ loading: true });


        if (!this.props.doctor) {
            //Add New Doctor.

            const ref = firebaseRef.collection('doctors').doc();
            const newID = ref.id;
    
            const newDoctor = {
                fname: this.state.firstname,
                lname: this.state.lastname,
                phone: this.state.phone,
                email: this.state.email,
                abbreviation: this.state.docabbreviation,
                specialty: this.state.specialty,
                employmentType: this.state.employmentType,
                paymentType: this.state.paymentType,
                paymentFee: parseFloat(this.state.paymentFee),
                uuid: newID,
                status: 'active'
            }
    
            ref.set({
                fname: this.state.firstname,
                lname: this.state.lastname,
                phone: this.state.phone,
                email: this.state.email,
                abbreviation: this.state.docabbreviation,
                specialty: this.state.specialty,
                employmentType: this.state.employmentType,
                paymentType: this.state.paymentType,
                paymentFee: parseFloat(this.state.paymentFee),
                uuid: newID,
                timestamp_added: firebase.firestore.FieldValue.serverTimestamp(),
                status: 'active'
            }).then(() => {
                // console.log("Success!");
    
                this.props.addDoctor(newDoctor);
                this.props.modalTrigger();
                this.setState(this.baseState);
    
                Toast.fire({
                    icon: 'success',
                    title: 'Data added successfully'
                })
            }).catch((error) => {
                this.setState({ loading: false });

                Toast.fire({
                    icon: 'error',
                    title: 'There was an error...'
                })

                console.log(error);
            });
        }else {
            //Update current Doctor.
            let batch = firebaseRef.batch();
            let dbRef = firebaseRef.collection('doctors').doc(this.props.doctor.uuid);
            let updates = 0;
            let updatedDoc = {};

            if (this.state.firstname !== null && this.state.firstname !== this.props.doctor.fname) {
                // console.log("FNAME is different. Update!");
                updates++;
                batch.update(dbRef, {'fname': this.state.firstname});
                updatedDoc.fname = this.state.firstname;
            }

            if (this.state.lastname !== null && this.state.lastname !== this.props.doctor.lname) {
                // console.log("LNAME is different. Update!");
                updates++;
                batch.update(dbRef, {'lname': this.state.lastname});
                updatedDoc.lname = this.state.lastname;
            }

            if (this.state.phone !== null && this.state.phone !== this.props.doctor.phone) {
                // console.log("PHONE is different. Update!");
                updates++;
                batch.update(dbRef, {'phone': this.state.phone});
                updatedDoc.phone = this.state.phone;
            }

            if (this.state.email !== null && this.state.email !== this.props.doctor.email) {
                // console.log("EMAIL is different. Update!");
                updates++;
                batch.update(dbRef, {'email': this.state.email});
                updatedDoc.email = this.state.email;
            }

            if (this.state.specialty !== null && this.state.specialty !== this.props.doctor.specialty) {
                // console.log("SPECIALTY is different. Update!");
                updates++;
                batch.update(dbRef, {'specialty': this.state.specialty});
                updatedDoc.specialty = this.state.specialty;
            }

            if (this.state.employmentType !== null && this.state.employmentType !== this.props.doctor.employmentType) {
                // console.log("EMPLOYMENT_TYPE is different. Update!");
                updates++;
                batch.update(dbRef, {'employmentType': this.state.employmentType});
                updatedDoc.employmentType = this.state.employmentType;
            }

            if (this.state.paymentType !== null && this.state.paymentType !== this.props.doctor.paymentType) {
                // console.log("PAYMENT_TYPE is different. Update!");
                updates++;
                batch.update(dbRef, {'paymentType': this.state.paymentType});
                updatedDoc.paymentType = this.state.paymentType;
            }

            if (this.state.docabbreviation !== null && this.state.docabbreviation !== this.props.doctor.abbreviation) {
                // console.log("ABBREVIATION is different. Update!");
                updates++;
                batch.update(dbRef, {'abbreviation': this.state.docabbreviation});
                updatedDoc.abbreviation = this.state.docabbreviation;
            }


            if (this.state.feeEdited) {
                // console.log("FEE is different. Update!");
                updates++;
                batch.update(dbRef, {'paymentFee': parseFloat(this.state.paymentFee)});
                updatedDoc.paymentFee = parseFloat(this.state.paymentFee);
            }

            this.props.updateDoctor(updatedDoc, this.props.doctor.uuid);
            
            if (updates > 0) {
                batch.update(dbRef, {timestamp_update: firebase.firestore.FieldValue.serverTimestamp()});
                batch.commit().then(() => {
                    
                    this.props.modalTrigger();
                    this.setState(this.baseState);

                    Toast.fire({
                        icon: 'success',
                        title: 'Changes saved'
                    })
                }).catch((error) => {
                    this.setState({ loading: false });

                    Toast.fire({
                        icon: 'error',
                        title: 'There was an error...'
                    });
                });
            }

            
        }


        return false;
    }



    render() {
        return (
            // <!-- The Modal -->
            <div id="myModal1" class={this.props.display ? "modal show" : "modal"}>

                <div class="modal-content shadow">

                    <div className="d-flex mb-4">
                        <p className="flex-grow-1 h1 font-weight-bold">{this.props.doctor ? "Edit Doctor" : "New Doctor"}</p>
                        <span class="close" onClick={this.closeModal}>&times;</span>
                    </div>

                    <form onSubmit={(e) => this.submitInfo(e)}>
                        <div class="form-row">
                            <div class="col">
                                <label for="fname">First Name</label>
                                <input 
                                    type="text" 
                                    id="fname" 
                                    class="form-control" 
                                    placeholder="First name"
                                    required
                                    value={this.props.doctor && this.state.firstname === null ? this.props.doctor.fname : !this.state.firstname ? "" : this.state.firstname}
                                    // value={this.props.doctor ? this.props.doctor.fname : this.state.firstname}
                                    onInput={(e) => this.updateInfo("fname", e)} />
                            </div>
                            <div class="col">
                                <label for="lname">Last Name</label>
                                <input 
                                    type="text" 
                                    id="lname" 
                                    class="form-control" 
                                    placeholder="Last name"
                                    value={this.props.doctor && this.state.lastname === null ? this.props.doctor.lname : !this.state.lastname ? "" : this.state.lastname}
                                    // value={this.state.lastname}
                                    onInput={(e) => this.updateInfo("lname", e)} />
                            </div>
                            <div class="col">
                                <label for="docabb">Abbreviation</label>
                                <input 
                                    type="text" 
                                    id="docabb" 
                                    class="form-control" 
                                    placeholder="Abbreviation"
                                    value={this.props.doctor && this.state.docabbreviation === null ? this.props.doctor.abbreviation : !this.state.docabbreviation ? "" : this.state.docabbreviation}
                                    // value={this.state.docabbreviation}
                                    onInput={(e) => this.updateInfo("abb", e)} />
                            </div>
                        </div>

                        <div class="form-row mt-4">
                            <div class="col">
                                <label for="docnumber">Phone Number</label>
                                <input 
                                    type="text" 
                                    id="docnumber" 
                                    class="form-control" 
                                    placeholder="Phone Number"
                                    value={this.props.doctor && this.state.phone === null ? this.props.doctor.phone : !this.state.phone ? "" : this.state.phone}
                                    // value={this.state.phone}
                                    onInput={(e) => this.updateInfo("phone", e)} />
                            </div>

                            <div class="col">
                                <label for="docemail">Email address</label>
                                <input 
                                    type="email" 
                                    id="docemail" 
                                    class="form-control" 
                                    placeholder="Email address"
                                    value={this.props.doctor && this.state.email === null ? this.props.doctor.email : !this.state.email ? "" : this.state.email}
                                    // value={this.state.email}
                                    onInput={(e) => this.updateInfo("email", e)} />
                            </div>
                        </div>


                        <div className="form-row mt-4">
                            <div class="form-group col">
                                <label for="inputState1">Specialty</label>
                                <select 
                                    id="inputState1" 
                                    className="form-control" 
                                    required
                                    value={this.props.doctor && this.state.specialty === null ? this.props.specialties.findIndex(el => el.uuid === this.props.doctor.specialty) !== -1 ? this.props.doctor.specialty : "" : this.state.specialty ? this.state.specialty : ""} 
                                    onChange={(e) => this.updateInfo("specialty", e)}>
                                    <option value="" disabled>Choose...</option>
                                    {this.props.specialties !== null && this.props.specialties !== undefined ?
                                    
                                        this.props.specialties.map(item => {
                                            return <option value={item.uuid}>{item.title}</option>
                                        })  
                                    
                                    : ""
                                    }
                                </select>
                            </div>

                            <div class="form-group col">
                                <label for="inputState">Employment type</label>
                                <select id="inputState"
                                    required
                                    value={this.props.doctor && this.state.employmentType === null ? this.props.employmentType.findIndex(el => el.uuid === this.props.doctor.employmentType) !== -1 ? this.props.doctor.employmentType : "" : this.state.employmentType ? this.state.employmentType : ""}

                                    className={
                                        this.props.doctor && !this.state.employmentType ?
                                            this.props.employmentType[this.props.employmentType.findIndex(item => item.uuid === this.props.doctor.employmentType)] !== undefined ?

                                            "form-control custom-select custom-select-" + this.props.employmentType[this.props.employmentType.findIndex(item => item.uuid === this.props.doctor.employmentType)].color 
                                            
                                            : this.state.employmentType ? "form-control custom-select custom-select-" + this.props.employmentType[this.props.employmentType.findIndex(item => item.uuid === this.state.employmentType)].color 
                                            
                                            : "form-control custom-select" 
                                            
                                            : 'form-control custom-select'} 

                                    onChange={(e) => this.updateInfo("employment", e)} >
                                        <option value="" disabled>Please select:</option>
                                        {this.props.employmentType !== null && this.props.employmentType !== undefined ?
                                            
                                            this.props.employmentType.map(item => {
                                                return <option value={item.uuid}>{item.title}</option>
                                            })  
                                        
                                        : ""
                                        }
                                </select>
                            </div>
                        </div>



                        <div className="form-row mt-4">
                            <div class="form-group col">
                                <label for="inputState2">Payment type</label>
                                <select 
                                    className="form-control" 
                                    value={this.props.doctor && this.state.paymentType === null ? this.props.doctor.paymentType : this.state.paymentType !== null ? this.state.paymentType : ""} 
                                    id="inputState2" 
                                    onChange={(e) => this.updateInfo("paymentType", e)} required>

                                    <option value="" disabled>Please select:</option>
                                    <option value="wage">Monthly wage</option>
                                    <option value="percentage">Percentage fee</option>
                                    <option value="fixed">Fixed fee</option>
                                </select>
                            </div>

                            {!this.state.paymentType && this.props.doctor === null ? 
                            
                            null :

                            <div class="form-group col">
                                <label for="docpaymentInput">{this.props.doctor && this.state.paymentType === null ? this.props.doctor.paymentType === 'wage' ? 'Monthly Wage' : this.props.doctor.paymentType === 'percentage' ? "Percentage fee (THEIR percentage)" : "Fixed fee (OUR fee)" : this.state.paymentType === 'wage' ? 'Monthly Wage' : this.state.paymentType === 'percentage' ? 'Percentage fee (THEIR percentage)' : "Fixed fee (OUR fee)"}</label>
                                <input 
                                    type="number" 
                                    class="form-control" 
                                    id="docpaymentInput" 
                                    aria-describedby="paymentHelp"
                                    step="0.01" 
                                    required
                                    value={this.props.doctor && !this.state.feeEdited ? this.props.doctor.paymentFee : this.state.paymentFee}
                                    placeholder={this.state.paymentType === 'wage' ? 'Enter monthly wage' : this.state.paymentType === 'percentage' ? 'Enter percentage number' : "Enter fixed fee"}
                                    onInput={(e) => this.updateInfo("paymentFee", e)} />
                                <small id="paymentHelp" class="form-text text-muted">Number only. Do NOT use dot for numbers larger than 1000.</small>
                            </div>
                        
                            }

                        </div>


                        <div className="mt-4 form-row">
                            <p className="text-muted col h-100 my-auto">{this.props.doctor ? "Make sure to save your changes!" : "Please make sure that all fields are filled. Ready? Hit submit!"} </p>

                            {this.state.loading ? 

                            <button type="button" disabled class="btn btn-primary mr-1">
                                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                Loading...
                            </button>

                            :

                            <button type="submit" class="btn btn-primary mr-1">{this.props.doctor ? "Save changes" : "Submit"}</button>

                            }

                        </div>

                    </form>

                </div>

            </div>
        )
    }
}



export default DoctorModal;