import React from "react";
import "./App.scss";
import Navbar from "../general/navbar/Navbar";
// Screens.
import Settings from "../Settings/Settings";
import Doctors from "../Doctors/Doctors";
import Patients from "../Patients/Patients";
import AppointmentsV2 from "../Appointments/AppointmentsV2";
import Suppliers from "../Suppliers/Suppliers";
import MetricsV2 from "../Metrics/MetricsV2";
// Loading Screen.
import Loader from "../Loader/Loader";
// Context.
import { StateProvider as AppointmentProvider } from "../../context/appointmentContext";
// Libraries.
import firebase from "../../firebase";
import Swal from "sweetalert2";
import moment from "moment";

const firebaseRef = firebase.firestore();

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

const SuccessToast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

const ErrorToast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      loadedBasics: false,
      selected: 1,
      doctors: null,
      specialties: [],
      employmentType: [],
      doctorFilter: true,
      patients: null,
      appointments: null,
      paymentMethods: null,
      supplierSource: null,
      suppliers: null,
      supplierData: null,
    };
  }

  componentDidMount() {
    window.onbeforeunload = () => {
      sessionStorage.clear();
    };
    this.loadUserAccount();
    this.getDoctors();
    this.loadSpecialties();
    this.loadEmploymentTypes();
    this.loadPaymentMethods();
    this.loadSupplierSource();
    this.loadAppointments();
    this.loadSuppliers();
    this.loadSupplierData();
  }

  componentDidUpdate() {
    if (this.state.loadedBasics === false) {
      this.basicsLoaded();
    }
  }

  basicsLoaded = () => {
    setTimeout(() => {
      if (
        this.state.doctors !== null &&
        this.state.suppliers !== null &&
        this.state.user !== null
      ) {
        this.setState({ loadedBasics: true });
      }
    }, 2300);
  };

  loadUserAccount = () => {
    firebaseRef
      .collection("users")
      .doc(this.props.user.uid)
      .get()
      .then((snapshot) => {
        this.setState({ user: snapshot.data() });
      });
  };

  logout = () => {
    Swal.fire({
      title: "Sign out?",
      text: "Are you sure you want to sign out?",
      icon: "info",
      showClass: {
        popup: "animated fadeInDown",
      },
      hideClass: {
        popup: "animated fadeOutUp",
      },
      showCancelButton: true,
      confirmButtonText: "Yes, sign out!",
    }).then((result) => {
      if (result.value) {
        firebase
          .auth()
          .signOut()
          .catch((error) => {
            console.log(error);
            this.showToast("error", "There was an error...");
          });
      }
    });
  };

  // Doctors
  makeActiveHandler = (id) => {
    this.setState({ selected: id });
  };

  getDoctors = () => {
    let doctors = [];
    firebaseRef
      .collection("doctors")
      .get()
      .then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          doctors.push(childSnapshot.data());
        });

        this.setState({ doctors: doctors });
      });
  };

  showToast = (icon, title) => {
    return Toast.fire({
      icon: icon,
      title: title,
    });
  };

  addDoctor = (doctor) => {
    let currentDoctors = [];

    if (this.state.doctors !== null && this.state.doctors !== undefined) {
      currentDoctors = this.state.doctors;
    }

    currentDoctors.push(doctor);

    this.setState({ doctors: currentDoctors });
  };

  updateDoctor = (newDoc, index) => {
    let currentDoctors = this.state.doctors;
    let key = currentDoctors.findIndex((item) => item.uuid === index);
    let doctor = this.state.doctors[key];

    for (let x in newDoc) {
      doctor[x] = newDoc[x];
    }

    currentDoctors[key] = doctor;

    this.setState({ doctors: currentDoctors });
  };

  loadSpecialties = () => {
    let specialties = [];
    firebaseRef
      .collection("specialties")
      .get()
      .then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          specialties.push(childSnapshot.data());
        });

        this.setState({ specialties: specialties });
      });
  };

  addSpecialty = async () => {
    const { value: specialty } = await Swal.fire({
      title: "Add New Specialty",
      input: "text",
      inputPlaceholder: "Enter title of specialty",
      confirmButtonText: "Save",
      showClass: {
        popup: "swal2-noanimation",
      },
    });

    if (specialty) {
      if (specialty.trim().length > 0) {
        //Add to database and local state.
        let currentSpecialties = this.state.specialties;

        const ref = firebaseRef.collection("specialties").doc();
        const newID = ref.id;

        let data = {
          title: specialty,
          uuid: newID,
          timestamp_added: firebase.firestore.FieldValue.serverTimestamp(),
        };

        ref
          .set({
            title: specialty,
            uuid: newID,
            timestamp_added: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {
            currentSpecialties.push(data);
            this.setState({ specialties: currentSpecialties });
            SuccessToast.fire({
              icon: "success",
              title: "Added successfully",
            });
          })
          .catch(() => {
            ErrorToast.fire({
              icon: "error",
              title: "There was an error...",
            });
          });
      }
    }
  };

  deleteSettings = (uuid, type) => {
    let collection = "";

    if (type === "specialty") {
      collection = "specialties";
    } else if (type === "employment") {
      collection = "employmentType";
    } else if (type === "payment") {
      collection = "paymentMethods";
    } else if (type === "source") {
      collection = "supplierSource";
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to undo this!",
      icon: "warning",
      showCancelButton: true,
      showClass: {
        popup: "animated fadeInDown",
      },
      hideClass: {
        popup: "animated fadeOutUp",
      },
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        firebaseRef
          .collection(collection)
          .doc(uuid)
          .delete()
          .then(() => {
            if (type === "specialty") {
              let specialties = this.state.specialties;
              specialties.splice(
                specialties.findIndex((el) => el.uuid === uuid),
                1
              );
              this.setState({ specialties: specialties });
            } else if (type === "employment") {
              let employment = this.state.employmentType;
              employment.splice(
                employment.findIndex((el) => el.uuid === uuid),
                1
              );
              this.setState({ employmentType: employment });
            } else if (type === "payment") {
              let paymentMethods = this.state.paymentMethods;
              paymentMethods.splice(
                paymentMethods.findIndex((el) => el.uuid === uuid),
                1
              );
              this.setState({ paymentMethods: paymentMethods });
            } else if (type === "source") {
              let supplierSource = this.state.supplierSource;
              supplierSource.splice(
                supplierSource.findIndex((el) => el.uuid === uuid),
                1
              );
              this.setState({ supplierSource: supplierSource });
            }

            SuccessToast.fire({
              icon: "success",
              title: "Success!",
            });
          })
          .catch((error) => {
            ErrorToast.fire({
              icon: "error",
              title: "There was an error...",
            });

            console.log(error);
          });
      }
    });
  };

  editSettings = async (uuid, type) => {
    let inputValue,
      inputTitle,
      collection = "";

    if (type === "specialty") {
      inputValue =
        this.state.specialties[
          this.state.specialties.findIndex((el) => el.uuid === uuid)
        ].title;
      inputTitle = "Edit Specialty";
      collection = "specialties";
    } else if (type === "employment") {
      inputValue =
        this.state.employmentType[
          this.state.employmentType.findIndex((el) => el.uuid === uuid)
        ].title;
      inputTitle = "Edit Employment Type";
      collection = "employmentType";
    } else if (type === "payment") {
      inputValue =
        this.state.paymentMethods[
          this.state.paymentMethods.findIndex((el) => el.uuid === uuid)
        ].title;
      inputTitle = "Edit Payment Method";
      collection = "paymentMethods";
    } else if (type === "source") {
      inputValue =
        this.state.supplierSource[
          this.state.supplierSource.findIndex((el) => el.uuid === uuid)
        ].title;
      inputTitle = "Edit Supplier Source";
      collection = "supplierSource";
    }

    const { value: newValue } = await Swal.fire({
      title: inputTitle,
      input: "text",
      inputValue: inputValue,
      showCancelButton: true,
      showClass: {
        popup: "animated fadeInDown",
      },
      hideClass: {
        popup: "animated fadeOutUp",
      },
    });

    if (newValue && newValue.trim().length > 0) {
      firebaseRef
        .collection(collection)
        .doc(uuid)
        .update({
          title: newValue,
          timestamp_updated: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          if (type === "specialty") {
            let specialties = this.state.specialties;
            specialties[specialties.findIndex((el) => el.uuid === uuid)].title =
              newValue;
            this.setState({ specialties: specialties });
          } else if (type === "employment") {
            let employment = this.state.employmentType;
            employment[employment.findIndex((el) => el.uuid === uuid)].title =
              newValue;
            this.setState({ employmentType: employment });
          } else if (type === "payment") {
            let paymentMethods = this.state.paymentMethods;
            paymentMethods[
              paymentMethods.findIndex((el) => el.uuid === uuid)
            ].title = newValue;
            this.setState({ paymentMethods: paymentMethods });
          } else if (type === "source") {
            let supplierSource = this.state.supplierSource;
            supplierSource[
              supplierSource.findIndex((el) => el.uuid === uuid)
            ].title = newValue;
            this.setState({ supplierSource: supplierSource });
          }

          this.showToast("success", "Edit successful!");
        })
        .catch((error) => {
          this.showToast("error", "There was an error...");
          console.log(error);
        });
    }
  };

  loadEmploymentTypes = () => {
    let employmentTypes = [];
    firebaseRef
      .collection("employmentType")
      .get()
      .then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          employmentTypes.push(childSnapshot.data());
        });

        this.setState({ employmentType: employmentTypes });
      });
  };

  loadPaymentMethods = () => {
    let paymentMethods = [];

    firebaseRef
      .collection("paymentMethods")
      .get()
      .then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          paymentMethods.push(childSnapshot.data());
        });

        this.setState({ paymentMethods: paymentMethods });
      });
  };

  addSettings = async (type) => {
    let inputTitle,
      inputPlaceholder,
      collection = "";

    if (type === "specialty") {
      inputTitle = "New Specialty";
      inputPlaceholder = "Enter title for new Specialty";
      collection = "specialties";
    } else if (type === "employment") {
      inputTitle = "New Employment Type";
      inputPlaceholder = "Enter title for new Employment Type";
      collection = "employmentType";
    } else if (type === "payment") {
      inputTitle = "New Payment Method";
      inputPlaceholder = "Enter title of new Payment Method";
      collection = "paymentMethods";
    } else if (type === "supplierPayment") {
      inputTitle = "New Payment Type";
      inputPlaceholder = "Enter title of new Payment Type";
      collection = "paymentMethods";
    } else if (type === "source") {
      inputTitle = "New Supplier Source";
      inputPlaceholder = "Enter title of Supplier Source";
      collection = "supplierSource";
    }

    const { value: inputValue } = await Swal.fire({
      title: inputTitle,
      input: "text",
      inputPlaceholder: inputPlaceholder,
      confirmButtonText: "Save",
      showClass: {
        popup: "animated fadeInDown",
      },
      hideClass: {
        popup: "animated fadeOutUp",
      },
    });

    if (inputValue && inputValue.trim().length > 0) {
      const ref = firebaseRef.collection(collection).doc();
      const newID = ref.id;

      let data = {
        title: inputValue,
        uuid: newID,
        timestamp_created: firebase.firestore.FieldValue.serverTimestamp(),
      };

      if (type === "supplierPayment") {
        data.type = "supplier";
      }

      ref
        .set(data)
        .then(() => {
          if (type === "specialty") {
            let specialties = this.state.specialties || [];
            specialties.push(data);
            this.setState({ specialties: specialties });
          } else if (type === "employment") {
            let employmentType = this.state.employmentType || [];
            employmentType.push(data);
            this.setState({ employmentType: employmentType });
          } else if (type === "payment" || type === "supplierPayment") {
            let paymentMethods = this.state.paymentMethods || [];
            paymentMethods.push(data);
            this.setState({ paymentMethods: paymentMethods });
          } else if (type === "source") {
            let supplierSource = this.state.supplierSource || [];
            supplierSource.push(data);
            this.setState({ supplierSource: supplierSource });
          }

          this.showToast("success", "Data added successfully!");
        })
        .catch((error) => {
          this.showToast("error", "There was an error...");

          console.log(error);
        });
    }
  };

  addEmploymentType = async () => {
    const { value: employmentType } = await Swal.fire({
      title: "New Employment Type",
      input: "text",
      inputPlaceholder: "Enter title of Employment Type",
      confirmButtonText: "Save",
      showClass: {
        popup: "swal2-noanimation",
      },
    });

    if (employmentType) {
      if (employmentType.trim().length > 0) {
        //Add to database and local state.
        let currentEmployment = this.state.employmentType;

        const ref = firebaseRef.collection("employmentType").doc();
        const newID = ref.id;

        let data = {
          title: employmentType,
          uuid: newID,
          timestamp_created: firebase.firestore.FieldValue.serverTimestamp(),
        };

        ref
          .set({
            title: employmentType,
            uuid: newID,
            timestamp_added: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {
            currentEmployment.push(data);
            this.setState({ employmentType: currentEmployment });
            SuccessToast.fire({
              icon: "success",
              title: "Added Successfully",
            });
          })
          .catch(() => {
            ErrorToast.fire({
              icon: "error",
              title: "There was an error...",
            });
          });
      }
    }
  };

  deactivateDoctor = (uuid) => {
    Swal.fire({
      title: "Are you sure?",
      text: "The doctor will remain inactive but not deleted.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, deactivate doctor!",
    }).then((result) => {
      if (result.value) {
        //Deactivate doctor.
        let currentDoctors = this.state.doctors;

        firebaseRef
          .collection("doctors")
          .doc(uuid)
          .update({
            status: "inactive",
            status_Timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {
            currentDoctors[
              currentDoctors.findIndex((item) => item.uuid === uuid)
            ].status = "inactive";
            this.setState({ doctors: currentDoctors }, () => {
              SuccessToast.fire({
                icon: "success",
                title: "Doctor deactivated!",
              });
            });
          })
          .catch((error) => {
            ErrorToast.fire({
              icon: "error",
              title: "There was an error...",
            });

            console.log("Error message: " + error);
          });
      }
    });
  };

  deactivateSupplier = (uuid) => {
    Swal.fire({
      title: "Are you sure?",
      text: "The supplier will remain inactive but not deleted.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, deactivate supplier!",
    }).then((result) => {
      if (result.value) {
        //Deactivate doctor.
        let currentSuppliers = this.state.suppliers;

        firebaseRef
          .collection("suppliers")
          .doc(uuid)
          .update({
            status: "inactive",
            status_Timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {
            currentSuppliers[
              currentSuppliers.findIndex((item) => item.uuid === uuid)
            ].status = "inactive";
            this.setState({ suppliers: currentSuppliers }, () => {
              SuccessToast.fire({
                icon: "success",
                title: "Supplier deactivated!",
              });
            });
          })
          .catch((error) => {
            ErrorToast.fire({
              icon: "error",
              title: "There was an error...",
            });

            console.log("Error message: " + error);
          });
      }
    });
  };

  reactivateSupplier = (uuid) => {
    let currentSuppliers = this.state.suppliers;

    firebaseRef
      .collection("suppliers")
      .doc(uuid)
      .update({
        status: "active",
        status_Timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        currentSuppliers[
          currentSuppliers.findIndex((item) => item.uuid === uuid)
        ].status = "active";
        this.setState({ suppliers: currentSuppliers }, () => {
          SuccessToast.fire({
            icon: "success",
            title: "Supplier re-activated!",
          });
        });
      })
      .catch((error) => {
        ErrorToast.fire({
          icon: "error",
          title: "There was an error...",
        });

        console.log("Error message: " + error);
      });
  };

  reactivateDoctor = (uuid) => {
    //Reactivate doctor.
    let currentDoctors = this.state.doctors;

    firebaseRef
      .collection("doctors")
      .doc(uuid)
      .update({
        status: "active",
        status_Timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        currentDoctors[
          currentDoctors.findIndex((item) => item.uuid === uuid)
        ].status = "active";
        this.setState({ doctors: currentDoctors }, () => {
          SuccessToast.fire({
            icon: "success",
            title: "Doctor re-activated!",
          });
        });
      })
      .catch((error) => {
        ErrorToast.fire({
          icon: "error",
          title: "There was an error...",
        });

        console.log("Error message: " + error);
      });
  };

  filterActiveDoctors = (filter) => {
    this.setState({ doctorFilter: filter });
  };

  // Patients
  addPatient = (patient) => {
    let currentPatients = [];

    if (this.state.patients !== null && this.state.patients !== undefined) {
      currentPatients = this.state.patients;
    }

    currentPatients.push(patient);

    this.setState({ patients: currentPatients });
  };

  loadAppointments = () => {
    let from = new Date();
    from.setHours(0, 0, 0, 0);

    let to = new Date();
    to.setHours(23, 59, 59, 999);

    firebaseRef
      .collection("appointments")
      .where("date", ">=", from)
      .where("date", "<=", to)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach(async (childSnapshot) => {
            let data = childSnapshot.data();

            await data.patientRef.get().then((grandSnapshot) => {
              let patientData = grandSnapshot.data();
              data.patient = patientData;
            });

            await data.transactionRef.get().then((grandSnapshot) => {
              let transData = grandSnapshot.data();
              data.transaction = transData;
              let appointments = this.state.appointments || [];
              appointments.push(data);
              this.setState({ appointments: appointments });
            });
          });
        }
      });
  };

  addAppointment = (value) => {
    let currentAppointments = this.state.appointments || [];

    currentAppointments.push(value);

    let patient_uuid = value.patient_uuid;

    currentAppointments.forEach((item, index) => {
      if (item.patient_uuid === patient_uuid) {
        currentAppointments[index].patient.total_arrears =
          value.patient.total_arrears;
        currentAppointments[index].patient.hasArrears =
          value.patient.hasArrears;
      }
    });

    this.setState({ appointments: currentAppointments });
  };

  updateAppointment = (data) => {
    let uuid = data.uuid;
    let currentAppointments = this.state.appointments || [];

    if (currentAppointments.length > 0) {
      //Find existing appointment and replace it.
      currentAppointments[
        currentAppointments.findIndex((el) => el.uuid === uuid)
      ] = data;
    } else {
      currentAppointments.push(data);
    }

    //Update same patient's arrears, if he exists in another open appointment.
    let patient_uuid = data.patient_uuid;

    currentAppointments.forEach((item, index) => {
      if (item.patient_uuid === patient_uuid) {
        currentAppointments[index].patient.total_arrears =
          data.patient.total_arrears;
        currentAppointments[index].patient.hasArrears = data.patient.hasArrears;
      }
    });

    this.setState({ appointments: currentAppointments });
  };

  deleteAppointment = (uuid) => {
    Swal.fire({
      title: "Are you sure?",
      html:
        "<p>All data, including the transaction, will be deleted.</p>" +
        "<p>This action <b>cannot</b> be undone.</p>",
      icon: "warning",
      showClass: {
        popup: "animated fadeInDown",
      },
      hideClass: {
        popup: "animated fadeOutUp",
      },
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        //Appointment is to be deleted.

        //Get appointment from state.
        let appointment =
          this.state.appointments[
            this.state.appointments.findIndex((el) => el.uuid === uuid)
          ];

        const patientRef = firebaseRef
          .collection("patients")
          .doc(appointment.patient_uuid);
        const transRef = firebaseRef
          .collection("transactions")
          .doc(appointment.transaction_uuid);
        const appointmentRef = firebaseRef
          .collection("appointments")
          .doc(appointment.uuid);

        return firebaseRef.runTransaction((transaction) => {
          return transaction
            .get(patientRef)
            .then((snapshot) => {
              if (snapshot.exists) {
                let oldArrears = snapshot.data().total_arrears || 0;
                let transactionArrears = appointment.transaction.arrears;
                let total_arrears;

                if (transactionArrears > 0) {
                  //Delete arrears.
                  total_arrears = oldArrears - appointment.transaction.arrears;
                } else if (transactionArrears < 0) {
                  //Delete surplus.
                  total_arrears =
                    oldArrears + Math.abs(appointment.transaction.arrears);
                } else {
                  total_arrears = oldArrears;
                }

                transaction.update(patientRef, {
                  total_arrears: total_arrears,
                });
                appointment.patient.total_arrears = total_arrears;

                if (total_arrears > 0) {
                  transaction.update(patientRef, { hasArrears: true });
                  appointment.patient.hasArrears = true;
                } else {
                  transaction.update(patientRef, { hasArrears: false });
                  appointment.patient.hasArrears = false;
                }

                transaction.delete(transRef);
                transaction.delete(appointmentRef);
              }
            })
            .then(() => {
              console.log("Transaction committed.");

              let currentAppointments = this.state.appointments || [];

              currentAppointments.splice(
                currentAppointments.findIndex((el) => el.uuid === uuid),
                1
              );

              // Update all current appointments with the same patient.
              let patient_uuid = appointment.patient_uuid;

              currentAppointments.forEach((item, index) => {
                if (item.patient_uuid === patient_uuid) {
                  currentAppointments[index].patient.total_arrears =
                    appointment.patient.total_arrears;
                  currentAppointments[index].patient.hasArrears =
                    appointment.patient.hasArrears;
                }
              });

              this.setState({ appointments: currentAppointments });
              this.showToast("success", "Appointment has been deleted!");
            });
        });
      }
    });
  };

  filterAppointments = () => {
    this.setState({ appointments: null });
  };

  loadSupplierSource = () => {
    let supplierSource = [];
    firebaseRef
      .collection("supplierSource")
      .get()
      .then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          supplierSource.push(childSnapshot.data());
        });

        this.setState({ supplierSource: supplierSource });
      });
  };

  addSupplier = (value) => {
    let suppliers = this.state.suppliers || [];

    suppliers.push(value);

    this.setState({ suppliers: suppliers });
  };

  loadSuppliers = () => {
    let suppliers = this.state.suppliers || [];

    firebaseRef
      .collection("suppliers")
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach((childSnapshot) => {
            let supplier = childSnapshot.data();
            suppliers.push(supplier);
          });

          this.setState({ suppliers: suppliers });
        }
      });
  };

  updateSupplier = (data, oldData, uuid) => {
    let suppliers = this.state.suppliers || [];
    let newSupplier = { ...oldData, ...data };
    suppliers[suppliers.findIndex((el) => el.uuid === uuid)] = newSupplier;
    this.setState({ suppliers: suppliers });
  };

  deleteSupplier = (uuid) => {
    Swal.fire({
      title: "Are you sure?",
      html:
        "<p>All data, including the transactions, will be deleted.</p>" +
        "<p>This action <b>cannot</b> be undone.</p>" +
        "<p>Be <b>very</b> careful when confirming this action!</p>",
      icon: "warning",
      showClass: {
        popup: "animated fadeInDown",
      },
      hideClass: {
        popup: "animated fadeOutUp",
      },
      showCancelButton: true,
      confirmButtonText: "Yes, delete supplier!",
    }).then((result) => {
      if (result.value) {
        let supplierData = this.state.supplierData || [];
        let suppliers = this.state.suppliers || [];
        let transIDs = [];
        let batch = firebaseRef.batch();

        firebaseRef
          .collection("transactions")
          .where("type", "==", "supplier")
          .where("supplier", "==", uuid)
          .get()
          .then((snapshot) => {
            if (!snapshot.empty) {
              snapshot.forEach((childSnapshot) => {
                let transRef = firebaseRef
                  .collection("transactions")
                  .doc(childSnapshot.data().uuid);
                batch.delete(transRef);
                transIDs.push(childSnapshot.data().uuid);
              });

              batch.delete(firebaseRef.collection("suppliers").doc(uuid));

              batch
                .commit()
                .then(() => {
                  console.log("Data deleted!");
                  transIDs.forEach((id) => {
                    if (supplierData.findIndex((el) => el.uuid === id) !== -1) {
                      supplierData.splice(
                        supplierData.findIndex((el) => el.uuid === id),
                        1
                      );
                    }
                  });

                  suppliers.splice(
                    suppliers.findIndex((el) => el.uuid === uuid),
                    1
                  );

                  this.setState({
                    supplierData: supplierData,
                    suppliers: suppliers,
                  });

                  this.showToast("success", "Supplier successfully deleted!");
                })
                .catch((error) => {
                  console.log(error);
                  this.showToast("error", "There was an error...");
                });
            }
          });
      }
    });
  };

  loadSupplierData = () => {
    let supplierData = [];

    let from = moment().startOf("month").toDate();
    let to = moment().endOf("month").toDate();

    firebaseRef
      .collection("transactions")
      .where("type", "==", "supplier")
      .where("date", ">=", from)
      .where("date", "<=", to)
      .orderBy("date")
      .orderBy("timestamp_created", "desc")
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach((childSnapshot) => {
            supplierData.push(childSnapshot.data());
          });

          this.setState({ supplierData: supplierData });
        }
      });
  };

  resetSupplierTransactions = () => {
    this.setState({ supplierData: null });
  };

  addSupplierTransaction = (data, type, stateData) => {
    let supplierData = this.state.supplierData || [];

    // Not sure about this line.
    if (type === undefined) {
      data.date = firebase.firestore.Timestamp.fromDate(data.date);
    }

    data.timestamp_created = firebase.firestore.Timestamp.fromDate(new Date());

    if (stateData !== undefined) {
      let currentSuppliers = this.state.suppliers || [];

      currentSuppliers[
        currentSuppliers.findIndex((el) => el.uuid === data.supplier)
      ].total_arrears = stateData.total_arrears;
      currentSuppliers[
        currentSuppliers.findIndex((el) => el.uuid === data.supplier)
      ].hasArrears = stateData.hasArrears;

      this.setState({ suppliers: currentSuppliers });
    }

    // TODO: Check if this is better. If not, revert to previous line.
    // supplierData.push(data);
    supplierData.unshift(data);
    this.setState({ supplierData: supplierData });
  };

  updateSupplierTransaction = (data, arrearsData, additionalData) => {
    let supplierTransactions = this.state.supplierData || [];

    if (supplierTransactions.length > 0) {
      let key = supplierTransactions.findIndex((el) => el.uuid === data.uuid);
      supplierTransactions[key] = data;
    } else {
      supplierTransactions.push(data);
    }

    let currentSuppliers = this.state.suppliers || [];

    currentSuppliers[
      currentSuppliers.findIndex((el) => el.uuid === data.supplier)
    ].total_arrears = arrearsData.total_arrears;
    currentSuppliers[
      currentSuppliers.findIndex((el) => el.uuid === data.supplier)
    ].hasArrears = arrearsData.hasArrears;

    // If multiple transactions have to be updated.
    if (additionalData.length > 0) {
      additionalData.forEach((item) => {
        supplierTransactions[
          supplierTransactions.findIndex((a) => a.uuid === item.uuid)
        ].current_arrears = item.current_arrears;
      });
    }

    this.setState({
      supplierData: supplierTransactions,
      suppliers: currentSuppliers,
    });
  };

  deleteSupplierTransaction = (item) => {
    const transRef = firebaseRef.collection("transactions").doc(item.uuid);
    const supplierRef = firebaseRef.collection("suppliers").doc(item.supplier);

    let currentTransactions = this.state.supplierData || [];

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to undo this!",
      icon: "warning",
      showClass: {
        popup: "animated fadeInDown",
      },
      hideClass: {
        popup: "animated fadeOutUp",
      },
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.value) {
        let total_arrears = 0;
        let arrearsBool = false;
        let updateDocs = [];

        // Code for progressive arrears.
        if (item.current_arrears !== undefined) {
          let doc = await firebaseRef
            .collection("transactions")
            .where("type", "==", "supplier")
            .where("supplier", "==", item.supplier)
            .where("date", ">=", item.date)
            .orderBy("date", "asc")
            .orderBy("timestamp_created", "asc")
            .get();

          if (!doc.empty) {
            let docs = doc.docs;
            docs.forEach((snapshot) => {
              snapshot.data().uuid !== item.uuid &&
                snapshot.data().timestamp_created.toDate() >
                  item.timestamp_created.toDate() &&
                updateDocs.push(snapshot.data());
            });
            console.log(updateDocs);
          }
        }

        return firebaseRef
          .runTransaction((transaction) => {
            return transaction.get(supplierRef).then((snapshot) => {
              let oldArrears = snapshot.data().total_arrears || 0;
              let hasArrears = false;

              oldArrears = oldArrears - item.arrears;

              if (oldArrears > 0) {
                hasArrears = true;
              }

              total_arrears = oldArrears;
              arrearsBool = hasArrears;

              // Code for progressive Arrears.
              if (item.current_arrears !== undefined && updateDocs.length > 0) {
                console.log("UPDATE SUBSEQUENT TRANSACTIONS");
                updateDocs.forEach((el) => {
                  if (el.current_arrears !== undefined) {
                    let newArrears = parseFloat(
                      el.current_arrears - item.current_arrears
                    );
                    console.log("NEW ARREARS", newArrears);
                    el.current_arrears = newArrears;
                    transaction.update(
                      firebaseRef.collection("transactions").doc(el.uuid),
                      { current_arrears: newArrears }
                    );
                  }
                });
              }

              transaction.update(supplierRef, {
                total_arrears: oldArrears,
                hasArrears: hasArrears,
              });
              transaction.delete(transRef);
            });
          })
          .then(() => {
            let suppliers = this.state.suppliers || [];
            suppliers[
              suppliers.findIndex((el) => el.uuid === item.supplier)
            ].total_arrears = total_arrears;
            suppliers[
              suppliers.findIndex((el) => el.uuid === item.supplier)
            ].hasArrears = arrearsBool;
            currentTransactions.splice(
              currentTransactions.findIndex((el) => el.uuid === item.uuid),
              1
            );

            // Code for progressive arrears.
            if (updateDocs.length > 0) {
              updateDocs.forEach((b) => {
                let exists = currentTransactions.findIndex(
                  (a) => a.uuid === b.uuid
                );

                if (exists !== -1) {
                  currentTransactions[exists].current_arrears =
                    b.current_arrears;
                }
              });
            }

            console.log("Data after update", currentTransactions);

            this.setState({
              supplierData: currentTransactions,
              suppliers: suppliers,
            });
            this.showToast("success", "Transaction deleted!");
          })
          .catch((error) => {
            console.log("There was an error...");
            console.log(error);
            this.showToast("error", "There was an error...");
          });
      }
    });
  };

  deletePatient = (appointments) => {
    let currentAppointments = this.state.appointments || [];
    let newAppointments = currentAppointments.filter(
      (a) => appointments.findIndex((el) => el === a.uuid) === -1
    );

    this.setState({ appointments: newAppointments });

    SuccessToast.fire({
      icon: "success",
      title: "Successfully deleted patient!",
    });
  };

  render() {
    if (this.state.loadedBasics) {
      return (
        <div className="app-container d-flex">
          <Navbar />

          <div className="flex-grow-1" style={{ backgroundColor: "#f6f8fa" }}>
            {this.props.menu === 1 ? (
              <AppointmentProvider>
                <AppointmentsV2
                  doctors={
                    this.state.doctors !== null ? this.state.doctors : null
                  }
                  data={
                    this.state.appointments !== null
                      ? this.state.appointments
                      : null
                  }
                  paymentMethods={
                    this.state.paymentMethods !== null
                      ? this.state.paymentMethods.filter(
                          (el) => el.type !== "supplier"
                        )
                      : null
                  }
                  showToast={this.showToast}
                  addAppointment={this.addAppointment}
                  updateAppointment={this.updateAppointment}
                  deleteAppointment={this.deleteAppointment}
                  filterAppointments={this.filterAppointments}
                  user={this.state.user}
                />
              </AppointmentProvider>
            ) : this.props.menu === 2 ? (
              <Doctors
                data={
                  this.state.doctors !== null
                    ? this.state.doctorFilter
                      ? this.state.doctors.filter(
                          (el) => el.status === "active"
                        )
                      : this.state.doctors
                    : null
                }
                addDoctor={this.addDoctor}
                deactivateDoctor={this.deactivateDoctor}
                reactivateDoctor={this.reactivateDoctor}
                updateDoctor={this.updateDoctor}
                filterActiveDoctors={this.filterActiveDoctors}
                specialties={this.state.specialties}
                employmentType={this.state.employmentType}
              />
            ) : this.props.menu === 3 ? (
              <Patients
                doctors={
                  this.state.doctors !== null
                    ? this.state.doctors.filter((el) => el.status === "active")
                    : null
                }
                addPatient={this.addPatient}
                showToast={this.showToast}
                deletePatient={this.deletePatient}
                paymentMethods={
                  this.state.paymentMethods !== null
                    ? this.state.paymentMethods.filter(
                        (el) => el.type !== "supplier"
                      )
                    : null
                }
              />
            ) : this.props.menu === 4 ? (
              <Suppliers
                suppliers={this.state.suppliers}
                supplierData={
                  this.state.supplierData !== null
                    ? this.state.supplierData.sort(
                        (a, b) =>
                          b.date.toDate().getTime() - a.date.toDate().getTime()
                      )
                    : null
                }
                supplierSource={this.state.supplierSource}
                addSupplier={this.addSupplier}
                updateSupplier={this.updateSupplier}
                deleteSupplier={this.deleteSupplier}
                addSupplierTransaction={this.addSupplierTransaction}
                updateSupplierTransaction={this.updateSupplierTransaction}
                deleteSupplierTransaction={this.deleteSupplierTransaction}
                resetSupplierTransactions={this.resetSupplierTransactions}
                loadSupplierData={this.loadSupplierData}
                deactivateSupplier={this.deactivateSupplier}
                reactivateSupplier={this.reactivateSupplier}
                showToast={this.showToast}
                paymentMethods={
                  this.state.paymentMethods !== null
                    ? this.state.paymentMethods.filter(
                        (el) => el.type === "supplier"
                      )
                    : null
                }
              />
            ) : this.props.menu === 5 ? (
              <MetricsV2
                doctors={
                  this.state.doctors !== null ? this.state.doctors : null
                }
                employmentType={this.state.employmentType}
                paymentMethods={
                  this.state.paymentMethods !== null
                    ? this.state.paymentMethods.filter(
                        (el) => el.type !== "supplier"
                      )
                    : null
                }
                supplierPayment={
                  this.state.paymentMethods !== null
                    ? this.state.paymentMethods.filter(
                        (el) => el.type === "supplier"
                      )
                    : null
                }
                suppliers={this.state.suppliers}
                supplierSource={this.state.supplierSource}
              />
            ) : this.props.menu === 6 ? (
              <Settings
                specialties={this.state.specialties}
                addSpecialty={this.addSpecialty}
                deleteSettings={this.deleteSettings}
                employmentType={this.state.employmentType}
                paymentMethods={this.state.paymentMethods}
                supplierSource={this.state.supplierSource}
                addEmployment={this.addEmploymentType}
                editSettings={this.editSettings}
                addSettings={this.addSettings}
                showToast={this.showToast}
                user={this.state.user}
              />
            ) : null}
          </div>
        </div>
      );
    } else {
      return <Loader />;
    }
  }
}

export default App;
