import React, { useContext, useState } from 'react';
import firebase from '../../../firebase';
// Context.
import { appointmentContext } from '../../../context/appointmentContext';

const firebaseRef = firebase.firestore();


function StepFooter(props) {
    // Hooks.
    const [loading, setLoading] = useState(false);

    let appointmentCon = useContext(appointmentContext);
    let { state } = appointmentCon;

    const setAppointment = () => {
        setLoading(true);

        let isLegit = checkIfLegit();
        if (isLegit.message) {
            setLoading(false);
            props.showToast('error', isLegit.message);
            return;
        }

        const ref = firebaseRef.collection('appointments').doc();
        const transRef = firebaseRef.collection('transactions').doc();
        const newID = ref.id;
        const transID = transRef.id;
        const patientRef = firebaseRef.collection('patients').doc(state.selectedPatient.uuid);

        let financials = calculateFinancials();

        let data = {
            patient_uuid: state.selectedPatient.uuid,
            uuid: newID,
            date: state.appdate,
            patientRef: patientRef,
            doctor_uuid: state.doctor.value,
            timestamp_created: firebase.firestore.FieldValue.serverTimestamp(),
            paymentMethod: state.payment.paymentMethod?.value ?? null,
            gross: parseFloat(state.payment.cost),
            transaction_uuid: transID,
            transactionRef: firebaseRef.collection('transactions').doc(transID),
            notes: state.notes,
            user_created: props.user.uuid
        }

        data = Object.assign(data, financials);

        // console.log('FINANCIALS', financials)

        let transData = {
            uuid: transID,
            timestamp_created: firebase.firestore.FieldValue.serverTimestamp(),
            amount_full: parseFloat(state.payment.cost),
            amount_paid: financials.amount_paid,
            appointment_uuid: newID,
            arrears: parseFloat(state.payment.cost) - financials.amount_paid,
            appointmentRef: firebaseRef.collection('appointments').doc(newID)
        }

        // console.log(data);
        // console.log('TRANSACTION DATA', transData);
        // console.log('Arrears data', transData);
        let stateData = {};
        Object.assign(stateData, data);
        stateData.date = firebase.firestore.Timestamp.fromDate(state.appdate);
        
        return firebaseRef.runTransaction((transaction) => {
            return transaction.get(patientRef).then(snapshot => {
                if (snapshot.exists) {
                    let newArrears = 0;
                    let oldArrears = snapshot.data().total_arrears || 0;

                    stateData.patient = snapshot.data();

                    if (transData.arrears === 0) {
                        newArrears = oldArrears;
                    }else {
                        newArrears = oldArrears + transData.arrears;
                    }

                    let patientData = {} ;

                    if (newArrears === 0) {
                        patientData.total_arrears = newArrears;
                    }else {
                        patientData.total_arrears = newArrears;
                        patientData.timestamp_last_arrears = firebase.firestore.FieldValue.serverTimestamp();
                        stateData.patient.timestamp_last_arrears = patientData.timestamp_last_arrears;
                    }

                    // transaction.update(patientRef, patientData);

                    if (newArrears > 0) {
                        patientData.hasArrears = true
                        // transaction.update(patientRef, { 'hasArrears': true });
                        stateData.patient.hasArrears = true;
                    }else {
                        patientData.hasArrears = false;
                        // transaction.update(patientRef, { 'hasArrears': false });
                        stateData.patient.hasArrears = false;
                    }

                    transaction.update(patientRef, patientData)
                    stateData.patient.total_arrears = newArrears;
                }

                transaction.set(firebaseRef.collection('appointments').doc(newID), data);
                transaction.set(transRef, transData);

                stateData.transaction = transData;
            }).then(() => {
                console.log('SUCCESS');
                props.addAppointment(stateData);
                props.showToast('success', 'Appointment added successfully!');
                props.closeModal();
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                props.showToast('error', 'There was an error... Appointment has not been saved...');
                console.log('ERROR', error);
            })
        })
    }

    const calculateFinancials = () => {
        switch(state.doctor.paymentType) {
            case 'wage':
                return {
                    amount_paid: parseFloat(state.payment.paid ? state.payment.cost : state.payment.amount_paid),
                    net: parseFloat(state.payment.cost),
                    net_paid: parseFloat(state.payment.paid ? state.payment.cost : state.payment.amount_paid),
                    paid: (state.payment.paid || parseFloat(state.payment?.amount_paid) >= parseFloat(state.payment.cost)) ? true : false,
                    paymentType: 'wage',
                    paymentFee: 0
                }
            case 'fixed':
                let fixedResult = {
                    amount_paid: parseFloat(state.payment.paid ? state.payment.cost : state.payment.amount_paid),
                    net: parseFloat(state.payment.cost) - parseFloat(state.doctor.paymentFee),
                    paid: (state.payment.paid || parseFloat(state.payment?.amount_paid) >= parseFloat(state.payment.cost)) ? true : false,
                    paymentType: 'fixed',
                    paymentFee: parseFloat(state.doctor.paymentFee)
                }
                
                let amount_paid = state.payment.paid ? parseFloat(state.payment.cost) : parseFloat(state.payment.amount_paid);
                let netResult = parseFloat(amount_paid) - parseFloat(state.doctor.paymentFee);

                fixedResult.net_paid = netResult;

                return fixedResult;
            case 'percentage':
                let percentageResult = {
                    amount_paid: parseFloat(state.payment.paid ? state.payment.cost : state.payment.amount_paid),
                    net: parseFloat(state.payment.cost) - ((parseFloat(state.doctor.paymentFee) / 100) * parseFloat(state.payment.cost)),
                    paid: (state.payment.paid || parseFloat(state.payment?.amount_paid) >= parseFloat(state.payment.cost)) ? true : false,
                    paymentType: 'percentage',
                    paymentFee: parseFloat(state.doctor.paymentFee)
                }

                let amount_paid2 = state.payment.paid ? parseFloat(state.payment.cost) : parseFloat(state.payment.amount_paid);
                let doctorPercentage = parseFloat(state.payment.cost) - percentageResult.net;

                percentageResult.net_paid = amount_paid2 - doctorPercentage;

                return percentageResult;
        }
    }

    const checkIfLegit = () => {
        if (state.doctor.value === undefined) {
            return {
                message: 'Please go to previous step and select a doctor'
            }
        }else {
            return true;
        }
    }

    return (
        <div className={props.step === 1 ? "d-flex justify-content-end mt-4" : "d-flex justify-content-between mt-4"}>
            {props.step > 1 ? 
                <button type="button" className="btn btn-danger" onClick={() => props.setStep(props.step - 1)}>
                    <i className="las la-arrow-left mr-2"></i>Back
                </button>
            : null }

            {(props.selectPatient || props.step > 1) && 
                <button type="button" disabled={props.loading} className="btn btn-primary float-right" onClick={() => props.step < 3 ? props.setStep(props.step + 1) : setAppointment()}>
                    {loading ? 
                        [<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>,
                        'Loading...']
                    :
                        props.step < 3 ? [
                            'Next',
                            <i className="las la-arrow-right ml-2"></i>
                        ] : [
                            'Set Appointment',
                            <i className="las la-arrow-right ml-2"></i>
                        ]

                    }
                </button>
            }
        </div>
    )
}



export default StepFooter;