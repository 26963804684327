import firebase from 'firebase';
import 'firebase/firebase-firestore';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyCgifkD2Md5Mwy7gXd_SRc0xg917lIgbg0",
    authDomain: "doc-app-383b6.firebaseapp.com",
    databaseURL: "https://doc-app-383b6.firebaseio.com",
    projectId: "doc-app-383b6",
    storageBucket: "doc-app-383b6.appspot.com",
    messagingSenderId: "942132106752",
    appId: "1:942132106752:web:dcab5e58fb3d8a7320aae5"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);


export default firebase;