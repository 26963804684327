import React, { Component } from "react";
// Components.
import DoctorModal from "../Modals/DoctorModal";
import DoctorTable from "../DoctorTable/DoctorTable";
import DoctorFilters from "../Filters/DoctorFilters";

class Doctors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      doctor: null,
      filteredData: null,
      searchFilter: "",
      specialtyFilter: "",
      employmentFilter: "",
      activityFilter: "",
    };
  }

  triggerModal = (data) => {
    if (this.state.showModal && this.state.doctor) {
      this.setState({ doctor: null });
    } else {
      if (data) {
        this.setState({ doctor: data });
      } else {
        this.setState({ doctor: null });
      }
    }

    this.setState({ showModal: !this.state.showModal });
  };

  searchFilter = (el, filter) => {
    return (
      (el.abbreviation &&
        el.abbreviation.toLowerCase().indexOf(filter.toLowerCase()) !== -1) ||
      (el.fname &&
        el.fname.toLowerCase().indexOf(filter.toLowerCase()) !== -1) ||
      (el.lname &&
        el.lname.toLowerCase().indexOf(filter.toLowerCase()) !== -1) ||
      (el.phone &&
        el.phone.toLowerCase().indexOf(filter.toLowerCase()) !== -1) ||
      (el.email && el.email.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
    );
  };

  employmentFilter = (el, filter) => {
    return el.employmentType && el.employmentType.indexOf(filter) !== -1;
  };

  specialtyFilter = (el, filter) => {
    return el.specialty && el.specialty.indexOf(filter) !== -1;
  };

  activityFilter = (el, filter) => {
    return el.status === filter;
  };

  updateFilter = (e, type) => {
    let newFilter = e.target.value;

    if (type === "search") {
      this.setState({ searchFilter: newFilter }, () => {
        this.filterData();
      });
    } else if (type === "employment") {
      this.setState({ employmentFilter: newFilter }, () => {
        this.filterData();
      });
    } else if (type === "specialty") {
      this.setState({ specialtyFilter: newFilter }, () => {
        this.filterData();
      });
    } else if (type === "status") {
      if (newFilter === "all" || newFilter === "inactive") {
        this.props.filterActiveDoctors(false);
      } else if (newFilter === "active") {
        this.props.filterActiveDoctors(true);
      }

      this.setState({ activityFilter: newFilter }, () => {
        this.filterData();
      });
    }
  };

  filterData = async () => {
    let data = this.props.data;

    if (this.state.searchFilter.trim().length > 0) {
      console.log("Filter Names");
      data = await data.filter((el) =>
        this.searchFilter(el, this.state.searchFilter)
      );
      this.setState({ filteredData: data });
    } else {
      this.resetFilter();
    }

    if (
      this.state.employmentFilter.trim().length > 0 &&
      this.state.employmentFilter !== "All"
    ) {
      console.log("Filter Employment");
      data = await data.filter((el) =>
        this.employmentFilter(el, this.state.employmentFilter)
      );
      this.setState({ filteredData: data });
    }

    if (
      this.state.specialtyFilter.trim().length > 0 &&
      this.state.specialtyFilter !== "All Specialties"
    ) {
      console.log("Filter Specialty");
      data = await data.filter((el) =>
        this.specialtyFilter(el, this.state.specialtyFilter)
      );
      this.setState({ filteredData: data });
    }

    if (
      this.state.activityFilter.trim().length > 0 &&
      this.state.activityFilter !== "all"
    ) {
      console.log("Filter Activity");
      data = await data.filter((el) =>
        this.activityFilter(el, this.state.activityFilter)
      );
      this.setState({ filteredData: data });
    }
  };

  resetFilter = () => {
    console.log("Resetting filters");
    this.setState({ filteredData: null });
  };

  render() {
    return (
      <div className="doc-container d-flex flex-column">
        <h1 className="font-weight-bold my-4">
          Doctors{" "}
          <button
            type="button"
            class="btn btn-sm btn-primary ml-3"
            onClick={() => this.triggerModal()}
          >
            Add New
          </button>
        </h1>

        <DoctorFilters
          specialties={this.props.specialties}
          employmentType={this.props.employmentType}
          updateFilter={this.updateFilter}
        />

        <div className="card table-card table-scroll flex-grow-1">
          <DoctorTable
            data={
              this.state.filteredData !== null
                ? this.state.filteredData
                : this.props.data
            }
            specialties={this.props.specialties}
            modalTrigger={this.triggerModal}
            employmentType={this.props.employmentType}
            deactivateDoctor={this.props.deactivateDoctor}
            reactivateDoctor={this.props.reactivateDoctor}
          />
        </div>

        {/* <div className="fab shadow" onClick={() => this.triggerModal()}>
                    <i className="las la-plus h-100"></i>
                </div> */}

        <DoctorModal
          display={this.state.showModal}
          modalTrigger={this.triggerModal}
          addDoctor={this.props.addDoctor}
          updateDoctor={this.props.updateDoctor}
          specialties={this.props.specialties}
          employmentType={this.props.employmentType}
          doctor={this.state.doctor ? this.state.doctor : null}
        />
      </div>
    );
  }
}

export default Doctors;
