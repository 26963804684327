import React, { Component } from 'react';

class SettingsItem extends Component {
    render() {
        return (
            <div className={this.props.selected === true ? "d-flex menu-item menu-selected" : "d-flex menu-item"} onClick={() => this.props.onClick(this.props.id)}>
                <i className={this.props.icon + " menu-icon"}></i>
                <span className="ml-3">{this.props.title}</span>
            </div>
        )
    }
}



export default SettingsItem;