import React, { useState, useEffect } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import moment from 'moment';


function MetricsModal(props) {
    const [filter, setFilter] = useState({
        from: null,
        to: null,
        name: 'Custom Dates'
    });

    useEffect(() => {
        let modal = document.getElementById("myModal10");
        //Close modal on window click.
        window.onclick = (event) => {
            if (event.target === modal) {
                props.modalTrigger();
            }
        }
    }, []);

    const submitDates = () => {
        let dateFilter = filter;
        dateFilter.name = `${moment(filter.from).format('DD')} - ${moment(filter.to).format('DD MMMM, YYYY')}`
        props.changeFilter('custom', dateFilter);
        props.modalTrigger();
    }

    return (
        <div id="myModal10" className={props.display ? "modal show" : "modal"}>
            <div className="modal-content modal-small shadow">

                <div className="d-flex mb-4">
                    <p className="flex-grow-1 h1 font-weight-bold">Filters</p>
                    <span className="close" onClick={props.modalTrigger}>&times;</span>
                </div>
                

                <div className='form-row'>
                    <div>
                        <p>From</p>
                        <DayPickerInput 
                            value={filter.from}
                            onDayChange={day => setFilter({...filter, from: moment(day).startOf('day').toDate()})} />
                    </div>

                    <div className="mx-3">
                        <p>To</p>
                        <DayPickerInput 
                            value={filter.to}
                            onDayChange={day => setFilter({...filter, to: moment(day).endOf('day').toDate()})} />
                    </div>

                    <div>
                        <p style={{ visibility: 'hidden' }}>To</p>
                        <button type="button" class="btn btn-primary btn-sm" onClick={submitDates}>Set filters</button>
                    </div>
                </div>

            </div>
        </div>
    )
}
























// class MetricsModal extends Component {
//     constructor(props) {
//         super(props)
    
//         this.state = {

//         }
//     }
    
    
//     componentDidMount() {
//         let modal = document.getElementById("myModal10");

//         //Close modal on window click.
//         window.onclick = (event) => {
//             if (event.target === modal) {
//                 this.props.modalTrigger();
//             }
//         }
//     }


//     render() {
//         return (
//             <div id="myModal10" className={this.props.display ? "modal show" : "modal"}>
//                 <div className="modal-content modal-small shadow">

//                     <div className="d-flex mb-4">
//                         <p className="flex-grow-1 h1 font-weight-bold">Filters</p>
//                         <span className="close" onClick={this.props.modalTrigger}>&times;</span>
//                     </div>
                    

//                     <div className='form-row'>
//                         <div>
//                             <p>From</p>
//                             <DayPickerInput 
//                                 value={this.state.from}
//                                 onDayChange={day => this.props.setFilterDate(day, "from")} />
//                         </div>

//                         <div className="mx-3">
//                             <p>To</p>
//                             <DayPickerInput 
//                                 value={this.state.to}
//                                 onDayChange={day => this.props.setFilterDate(day, "to")} />
//                         </div>

//                         <div>
//                             <p style={{ visibility: 'hidden' }}>To</p>
//                             <button type="button" class="btn btn-primary btn-sm" onClick={this.props.loadMetrics}>Set filters</button>
//                         </div>
//                     </div>

//                 </div>
//             </div>
//         )
//     }
// }



export default MetricsModal;