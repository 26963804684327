import React, { Component } from 'react';
import PatientModal from '../Modals/PatientModal';
import PatientSearch from '../Search/PatientSearch';
import firebase from '../../firebase';
import moment from 'moment';
import patients from './patients.json';
import PatientHistoryModal from '../Modals/PatientHistoryModal';
import existingAppointments from '../../appointments.json';
import finalAppointments from '../../finalAppointments.json';
import Swal from 'sweetalert2';

const existingData = patients;

const ErrorToast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000
})

let doctors = [
  {
    uuid: '0grSjT5zFrhXyXbFILyO',
    name: 'ΚΑΡΑΤΕΓΟΥ ΚΡΥΣΤΑ'
  },
  {
    uuid: 'KeYskv1Nerleic1cSiHq',
    name: 'ΛΟΥΚΑΣ ΓΕΩΡΓΙΟΣ'
  },
  {
    uuid: 'bvFm5aONd4Z5aj53N5KM',
    name: 'ΠΟΖΙΔΗ ΤΖΩΡΤΖΙΝΑ'
  },
  {
    uuid: 'BnIrK7lTBFHREiksYW3P',
    name: 'ΚΑΡΑΤΕΓΟΥ ΑΚΡΙΒΗ'
  },
  {
    uuid: 'YwU3JeQHQb5B2VEIuu55',
    name: 'ΣΕΡΓΗΣ ΚΩΣΤΑΣ'
  },
  {
    uuid: 'tzLILuaRABVWNsVIkdZt',
    name: 'ΖΑΦΕΙΡΟΠΟΥΛΟΥ ΜΑΙΡΗ'
  },
  {
    uuid: 'WT5788SIp83RPI3e8v3F',
    name: 'ΚΑΚΑΒΕΤΣΟΣ ΒΑΣΙΛΗΣ'
  },
  {
    uuid: 'rGia39sZH8EZrKPXEyMO',
    name: 'ΠΑΠΑΣΤΑΜΟΥ ΒΑΣΙΛΙΚΗ'
  }
];

const firebaseRef = firebase.firestore();

class Patients extends Component {
  constructor(props) {
      super(props)
  
      this.state = {
        showModal: false,
        patient: null,
        searchData: null,
        loading: false,
        displayHistory: false,
        historyUser: null
      }
  }


    triggerModal = (data) => {
        if (this.state.showModal && this.state.patient) {
            this.setState({ patient: null });
        }else {
            if (data) {
                this.setState({ patient: data });
            }else {
                this.setState({ patient: null });
            }
        }

        this.setState({ showModal: !this.state.showModal });
    }

    triggerHistoryModal = (user) => {
      if (this.state.displayHistory) {
        this.setState({ displayHistory: false, historyUser: null });
      }else {
        this.setState({ displayHistory: true, historyUser: user });
      }
    }

    bulkAdd = () => {
        let batch = firebaseRef.batch();

        existingData.forEach(el => {
            console.log(el);

            let ref = firebaseRef.collection('patients').doc();
            let newID = ref.id;

            let newPatient = {
                fname: el.lname,
                lname: el.fname,
                phone: null,
                email: null,
                doctor: null,
                timestamp_added: firebase.firestore.FieldValue.serverTimestamp(),
                uuid: newID
            }

            let docRef = firebaseRef.collection('patients').doc(newID);
            batch.set(docRef, newPatient);

        });

        batch.commit().then(() => {
            console.log('FINISHED!')
        }).catch((error) => {
            console.log("There was an error... " + error);
        })
    }

    bulkEdit = () => {
      firebaseRef.collection('patients').get()
      .then((snapshot) => {
        snapshot.forEach(async (childSnapshot) => {
          let data = childSnapshot.data();

          let newIndex = await data.lname.trim().concat(data.fname.trim());
          let allIndexes = await data.searchIndex;

          allIndexes.push(newIndex);

          firebaseRef.collection('patients').doc(data.uuid).update({ 'searchIndex': allIndexes }).then(() => {
            console.log('Update complete!');
          }).catch((error) => {
            console.log('ERROOOOOOOOOOOOR: ' + error);
          })
        })
      })
    }

    LEGACYbulkEdit = () => {
      firebaseRef.collection('patients').get()
      .then((snapshot) => {
        snapshot.forEach(async (childSnapshot) => {
          let indexes = [];
          let data = childSnapshot.data();

          let lettersF = await data.fname.split("");
          let lettersL = await data.lname.split("");

          indexes.push(lettersF[0]);

          for (let x = 1; x < lettersF.length; x++) {
            indexes.push(indexes[x - 1].concat(lettersF[x]));
          }

          let newLength = (indexes.length + lettersL.length);
          let oldIndexesLength = indexes.length;
          
          indexes.push(lettersL[0]);
          

          for (let x = indexes.length; x < newLength; x++) {
            indexes.push(indexes[x - 1].concat(lettersL[x - oldIndexesLength]));
          }

          firebaseRef.collection('patients').doc(data.uuid).update({ 'searchIndex': indexes }).then(() => {
            console.log('Update complete!');
          }).catch((error) => {
            console.log('ERROOOOOOOR: ' + error);
          })
          
        })

      })
    }

    bulkAdd = () => {
      let batch = firebaseRef.batch();

      existingData.forEach(async (item) => {
        let data = {};

        data.fname = item.firstname.toUpperCase();
        data.lname = item.lastname.toUpperCase();

        data.searchIndex = await this.createIndexedName(data.fname, data.lname);

        //Email.
        if (item.email === '') {
          data.email = null;
        }else {
          data.email = item.email;
        }


        //Phone number(s).
        let phoneNumbers = [];
        
        if (item.phone1 !== '') {
          phoneNumbers.push(item.phone1);
        }

        if (item.phone2 !== '') {
          phoneNumbers.push(item.phone2);
        }

        if (item.phone3 !== '') {
          phoneNumbers.push(item.phone3);
        }

        data.phone = phoneNumbers;


        //Match doctor.
        if (item.doctor === '') {
          data.doctor = null;
        }else {
          data.doctor = doctors[doctors.findIndex(el => el.name === item.doctor)].uuid;
        }


        //Get age.
        if (item.age !== '-') {
          data.age = parseInt(item.age.substring(0,2));
        }else {
          data.age = null;
        }



        data.timestampCreated = firebase.firestore.FieldValue.serverTimestamp();

        const ref = firebaseRef.collection('patients').doc();
        const newID = ref.id;

        data.uuid = newID;
        data.total_arrears = 0;
        data.hasArrears = false;
        data.notes = null;

        ref.set(data);

        console.log('Went through');
      });
    }

    bulkDates = () => {
      firebaseRef.collection('patients').get().then(snapshot => {
        snapshot.forEach(childSnapshot => {
          let patient = childSnapshot.data();

          firebaseRef.collection('patients').doc(patient.uuid).update({"timestamapCreated" : firebase.firestore.FieldValue.delete(), "timestampCreated" : firebase.firestore.FieldValue.delete()}).then(() => {
            console.log('Deleted!');
          });
        })
      })
    }

    createIndexedName = async (fname, lname) => {
        let indexes = [];
        let data = {};

        data.fname = fname;
        data.lname = lname;

        let lettersF = await data.fname.split("");
        let lettersL = await data.lname.split("");

        indexes.push(lettersF[0]);

        for (let x = 1; x < lettersF.length; x++) {
            indexes.push(indexes[x - 1].concat(lettersF[x]));
        }

        if (data.lname && data.lname !== '') {
            let newLength = (indexes.length + lettersL.length);
            let oldIndexesLength = indexes.length;
            
            indexes.push(lettersL[0]);
            
            for (let x = indexes.length; x < newLength; x++) {
                indexes.push(indexes[x - 1].concat(lettersL[x - oldIndexesLength]));
            }

            // Connect first name and last name.
            indexes.push(fname.replace(/\s+/g, '').concat(lname.replace(/\s+/g, '')));
            indexes.push(lname.replace(/\s+/g, '').concat(fname.replace(/\s+/g, '')));
        }

        // console.log(indexes);

        return indexes;
    }

    getSize = () => {
      firebaseRef.collection('patients').get().then(snapshot => {
        console.log(snapshot.size);
      })
    }

    isEmail = (email) => {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  
    submitSearch = (e, term, doctor, arrearsFilter) => {
        e.preventDefault();

        if (term === null) {
          term = '';
        }

        if ((term.trim().length === 0 || term === '') && doctor === null && arrearsFilter === null) {
          return;
        }

        this.setState({ loading: true });
        // console.log("Submitted!");

        let searchData = [];
        let query = firebaseRef.collection('patients');

        if (term !== '') {
            let isnum = /^\d+$/.test(term);

            if (isnum) {
                //Search for phone number.
                // console.log('Looking for phone number');
    
                query = query.where('phone', '==', term);
            }else if (this.isEmail(term)) {
                //Search for email address.
                // console.log('Looking for email address');
    
                query = query.where("email", '==', term);
            }else {
                console.log('Looking for name');
    
                const searchTerm = term.toUpperCase();
                let searchWords = searchTerm.split(" ");
                searchWords = searchWords.join("");
        
                // console.log('Query: ' + searchWords);
        
                query = query.where("searchIndex", "array-contains", searchWords);

                console.log(searchWords);
            }
        }

        

        if (doctor !== null && doctor.length > 0) {
            query = query.where('doctor', '==', doctor);
        }


        if (arrearsFilter !== null) {
          query = query.where('hasArrears', '==', arrearsFilter);
        }

        query
        .get()
        .then((snapshot) => {
            this.setState({ loading: false });

            if (snapshot.empty) {
                // console.log('No results!');
                this.setState({ searchData: [] });
            }else {
                snapshot.forEach((childSnapshot) => {
                    // console.log(childSnapshot.data());
                    searchData.push(childSnapshot.data());
                })

                this.setState({ searchData: searchData });
            }
        }).catch((error) => {
            console.log('There was an error: ' + error );
            this.setState({ searchData: null });
        })


        return false;
    }

    updateSearchData = (uuid, data) => {
      let currentSearch = this.state.searchData || [];

      currentSearch[currentSearch.findIndex(el => el.uuid === uuid)] = data;

      this.setState({ searchData: currentSearch });
    }

    fixIndexes = () => {
      firebaseRef.collection('patients').get().then(snapshot => {
        snapshot.forEach(async childSnapshot => {
          let patient = childSnapshot.data();

          let firstname = patient.fname;
          let lastname = patient.lname;

          let indexes = await this.createIndexedName(firstname, lastname);

          await firebaseRef.collection('patients').doc(patient.uuid).update({'searchIndex': indexes});

          console.log('Updated!');
        })

        console.log('Done!');
      });
    }

    // Testing purposes ONLY.
    testJson = () => {
      let doctors = [];

      firebaseRef.collection('doctors').get().then(snapshot => {
        snapshot.forEach(childSnapshot => {
          let doctor = childSnapshot.data();
          doctor.timestamp_update = null;
          doctors.push(doctor);
        });

        localStorage.setItem('doctors', JSON.stringify(doctors));

        console.log('Finished!');
      });
    }

    importAppointments = () => {
      let appointments = finalAppointments;

      appointments.forEach((item, index) => {
        if (item.uuid1 !== '#N/A' && index >= 10) {
          let date = moment(item.date, "DD-MM-YYYY").toDate();

          let appointRef = firebaseRef.collection('appointments').doc();
          let appointKey = appointRef.id;

          let transRef = firebaseRef.collection('transactions').doc();
          let transKey = transRef.id;


          let data = {
            date: date,
            amount_paid: parseFloat(item.gross),
            gross: parseFloat(item.gross),
            net: parseFloat(item.net),
            net_paid: parseFloat(item.net),
            paid: true,
            notes: null,
            doctor_uuid: item.doc_uuid,
            patient_uuid: item.uuid1,
            patientRef: firebaseRef.collection('patients').doc(item.uuid1),
            timestampCreated: firebase.firestore.FieldValue.serverTimestamp(),
            transactionRef: firebaseRef.collection('transactions').doc(transKey),
            transaction_uuid: transKey,
            user_created: "TyPWr1T9y2Q2ZJX7KtTEPxo1nG83",
            uuid: appointKey
          };



          if (item.payment === "Cash") {
            data.paymentMethod = "6AlSG39mI4MaSGCuZGw2";
          }else if (item.payment === "Credit Card") {
            data.paymentMethod = "CMs2vxSA5rhrgztRN98M";
          }else {
            data.paymentMethod = "YHEMdXtGDRCXLwYMYKcl";
          }



          let transData = {
            amount_full: item.gross,
            amount_paid: item.gross,
            arrears: 0,
            timestampCreated: firebase.firestore.FieldValue.serverTimestamp(),
            appointment_uuid: appointKey,
            appointmentRef: firebaseRef.collection('appointments').doc(appointKey),
            uuid: transKey
          }


          appointRef.set(data);
          transRef.set(transData);

          console.log('Data pushed!');

          
        }
      })


    }

    deletePatientTrigger = (uuid) => {
      Swal.fire({
        title: 'Are you sure?',
        html: "<p>This will also delete all appointments created for this patient.</p>" +
              "<p>This action <b>cannot</b> be undone.</p>",
        icon: 'warning',
        showCancelButton: true,
        showClass: {
          popup: 'animated fadeInDown'
        },
        hideClass: {
          popup: 'animated fadeOutUp'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete patient!'
      }).then(async (result) => {
        if (result.value) {
          let batch = firebaseRef.batch();
  
          let doc = await firebaseRef.collection('appointments').where('patient_uuid', '==', uuid).get();
          let appointments = [];
  
          if (!doc.empty) {
            let docs = doc.docs;
            docs.forEach(a => {
              appointments.push(a.id);
              batch.delete(firebaseRef.collection('appointments').doc(a.id));
              batch.delete(firebaseRef.collection('transactions').doc(a.data().transaction_uuid));
            });
          }
  
          batch.delete(firebaseRef.collection('patients').doc(uuid));
  
          batch.commit().then(() => {
            // Delete locally.
            let currentSearchData = this.state.searchData;
            currentSearchData.splice(currentSearchData.findIndex(a => a.uuid === uuid), 1);
            this.setState({ searchData: currentSearchData });
            this.props.deletePatient(appointments);
          }).catch((error) => {
            ErrorToast.fire({
              icon: 'error',
              title: 'There was an error...'
            })
  
            console.log(error);
          })
        }
      })
    }

    render() {
        return (
          <div style={{overflow: 'auto', height: '100vh', paddingBottom: '5vh'}}>
            <div className="doc-container d-flex flex-column">
                <h1 className="font-weight-bold my-4">Patients <button type="button" class="btn btn-sm btn-primary ml-3" onClick={() => this.triggerModal()}>Add New</button><button type="button" class="btn btn-sm btn-danger ml-3 d-none">Import appointments</button></h1>

                <h2 className='mt-4'><u>Search for Patient</u></h2>

                <div className="mt-2">
                    <PatientSearch 
                        doctors={this.props.doctors}
                        modalTrigger={this.triggerModal}
                        submitSearch={this.submitSearch}
                        searchData={this.state.searchData}
                        loading={this.state.loading}
                        triggerHistoryModal={this.triggerHistoryModal}
                        deletePatient={this.deletePatientTrigger} />
                </div>


                <PatientModal 
                    display={this.state.showModal}
                    modalTrigger={this.triggerModal}
                    doctors={this.props.doctors}
                    addPatient={this.props.addPatient}
                    patient={this.state.patient}
                    showToast={this.props.showToast}
                    updateSearchData={this.updateSearchData} />


                <PatientHistoryModal 
                  display={this.state.displayHistory}
                  triggerHistoryModal={this.triggerHistoryModal}
                  patient={this.state.historyUser}
                  doctors={this.props.doctors}
                  paymentMethods={this.props.paymentMethods} />
                
            </div>
          </div>
        )
    }
}



export default Patients;