import React, { Component } from 'react';
import firebase from '../../firebase';

class Login extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            loading: false,
            email: null,
            password: null
        }
    }


    updateInfo = (e, type) => {
        let value = e.target.value;

        if (type === 'email') {
            this.setState({ email: value });
        }else {
            this.setState({ password: value });
        }
    }


    signIn = (e) => {
        e.preventDefault();

        this.setState({ loading: true });

        firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then(() => {
            this.setState({ loading: false });
        }).catch((error) => {
            console.log(error);
            this.setState({ loading: false });
            alert(error.message);
        });

        return false;
    }

    
    render() {
        return (
            <div className='text-center login' style={{ height: '100vh' }}>
                <form className="form-signin" onSubmit={(e) => this.signIn(e)}>
                    <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>

                    <div className='my-4'>
                        <label for="inputEmail" className="sr-only">Email address</label>
                        <input 
                            type="email"
                            className="form-control"
                            placeholder="Email address"
                            required
                            autoFocus={true}
                            onInput={(e) => this.updateInfo(e, 'email')} />

                        <label for="inputPassword" className="sr-only">Password</label>
                        <input 
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            required
                            onInput={(e) => this.updateInfo(e, 'password')} />
                    </div>

                    {this.state.loading ?
                        <button class="btn btn-lg btn-primary" type="button" disabled>
                            <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                
                        :

                        <button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
                    }
                    <p className="mt-5 mb-3 text-muted">&copy; 2020</p>
                </form>
            </div>
        )
    }
}



export default Login;