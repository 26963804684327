import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regularIcons } from "icons/icons";

class PatientResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
    };
  }

  selectPatient = (patient) => {
    // this.setState({ selected: patient.uuid });
    this.props.selectPatient(patient);
  };

  render() {
    return (
      <div className="mt-4">
        {this.props.data !== null && this.props.data.length > 0 ? (
          <table class="table table-borderless table-hover">
            <thead>
              <tr>
                <th>Firstname</th>
                <th>Lastname</th>
                <th>Phone Number</th>
                <th>Doctor</th>
                <th>Balance</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.props.data.map((el) => {
                return (
                  <tr
                    key={el.uuid}
                    className={
                      this.props.selected !== null
                        ? this.props.selected === el.uuid
                          ? "table-primary"
                          : ""
                        : ""
                    }
                  >
                    <td>{el.fname}</td>
                    <td>{el.lname}</td>
                    <td>
                      {el.phone.length > 1 ? (
                        <select
                          className="filter-select"
                          style={{ fontWeight: "normal" }}
                        >
                          {el.phone.map((el) => {
                            return (
                              <option style={{ paddingLeft: 0 }}>{el}</option>
                            );
                          })}
                        </select>
                      ) : el.phone.length === 1 ? (
                        el.phone
                      ) : null}
                    </td>
                    <td>
                      {el.doctor !== null
                        ? this.props.doctors[
                            this.props.doctors.findIndex(
                              (doc) => doc.uuid === el.doctor
                            )
                          ]
                          ? this.props.doctors[
                              this.props.doctors.findIndex(
                                (doc) => doc.uuid === el.doctor
                              )
                            ].lname !== null
                            ? this.props.doctors[
                                this.props.doctors.findIndex(
                                  (doc) => doc.uuid === el.doctor
                                )
                              ].fname +
                              " " +
                              this.props.doctors[
                                this.props.doctors.findIndex(
                                  (doc) => doc.uuid === el.doctor
                                )
                              ].lname
                            : this.props.doctors[
                                this.props.doctors.findIndex(
                                  (doc) => doc.uuid === el.doctor
                                )
                              ].fname
                          : "INACTIVE"
                        : null}
                    </td>
                    <td>
                      {el.total_arrears !== null &&
                      el.total_arrears !== undefined
                        ? -el.total_arrears + "€"
                        : "-"}
                    </td>
                    {this.props.mode === "appointment" ? (
                      <td>
                        <div
                          className="btn btn-sm btn-outline-primary"
                          onClick={() => this.selectPatient(el)}
                        >
                          <FontAwesomeIcon icon={regularIcons.CHECK} />
                        </div>
                      </td>
                    ) : (
                      <td>
                        <i
                          className="btn btn-sm btn-warning las la-pen mr-1"
                          onClick={() => this.props.modalTrigger(el)}
                        ></i>
                        <i
                          className="btn btn-sm btn-primary las la-book mr-1"
                          onClick={() => this.props.triggerHistoryModal(el)}
                        ></i>
                        <i
                          className="btn btn-sm btn-danger las la-trash mr-1"
                          onClick={() => this.props.deletePatient(el.uuid)}
                        ></i>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : this.props.data !== null && this.props.data.length === 0 ? (
          <h3 className="font-weight-bold">No results!</h3>
        ) : null}
      </div>
    );
  }
}

export default PatientResults;
