import { useSelector } from "react-redux";
import { RootState } from "store/store";
import App from "./components/App/App";

type Props = {
  user: any;
};

const AppWrapper: React.FC<Props> = (props) => {
  const { user } = props;
  const menu = useSelector((state: RootState) => state.menu.value);

  return <App user={user} menu={menu} />;
};

export default AppWrapper;
