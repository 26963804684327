export const formatAmount = (number) => {
    return Math.round(number * 100) / 100;
}


export const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}


export const checkInput = (data) => {
    if (isEmail(data)) {
        return 'email';
    }else if (isNum(data)) {
        return 'number';
    }else if (data.trim().length === 0) {
        return 'empty'
    }else {
        return 'name';
    }
}

export const isEmail = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


export const isNum = (num) => {
    return /^\d+$/.test(num);
}