import React, { Component } from 'react';
import Select from 'react-select';
import firebase from '../../firebase';

const firebaseRef = firebase.firestore();

class PatientModal extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             firstname: null,
             lastname: null,
             phone: null,
             phoneNumbers: null,
             email: null,
             doctor: null,
             notes: null,
             balance: null,
             loading: false
        }


        this.baseState = this.state;
    }
    

    componentDidMount() {
        let modal = document.getElementById("myModal2");

        //Close modal on window click.
        window.onclick = (event) => {
            if (event.target === modal) {
                this.closeModal();
            }
        }
    }


    closeModal = () => {
        this.props.modalTrigger();
        this.setState(this.baseState);
    }


    updateInfo = (type, e) => {
        const info = e.target.value;

        if (type === "fname") {
            this.setState({ firstname: info.toUpperCase() });
        }else if (type === "lname") {
            this.setState({ lastname: info.toUpperCase() });
        }else if (type === "phone") {
            this.setState({ phone: info });
        }else if (type === "email") {
            this.setState({ email: info });
        }else if (type === "doctor") {
            this.setState({ doctor: info });
        }else if (type === "notes") {
            this.setState({ notes: info });
        }else if (type === 'balance') {
            this.setState({ balance: info });
        }
        
    }


    updateDropdown = (info) => {
        console.log(info.value);
        this.setState({ doctor: info });
    }


    addPhoneNumber = () => {
        let phoneNumbers = this.state.phoneNumbers || [];
        
        if (this.state.phone !== null && this.state.phone.trim().length > 0) {
            phoneNumbers.push(this.state.phone);
            this.setState({ phoneNumbers: phoneNumbers, phone: '' });
        }
    }


    deletePhoneNumber = (index) => {
        let phoneNumbers = this.state.phoneNumbers;
        phoneNumbers.splice(index, 1);
        this.setState({ phoneNumbers: phoneNumbers });
    }


    submitInfo = async (e) => {
        e.preventDefault();

        this.setState({ loading: true });

        if (!this.props.patient) {
            //Add New Patient.

            //Create indexed name;
            let firstname = this.state.firstname;
            let lastname = this.state.lastname || "";

            if (firstname.trim().length === 0) {
                alert('Please insert a valid name.');
                this.setState({ firstname: null });
                return;
            }

            let indexedName = await this.createIndexedName(firstname, lastname);

            const ref = firebaseRef.collection('patients').doc();
            const newID = ref.id;

            let data = {};

            data.fname = firstname;
            data.lname = lastname;
            data.email = this.state.email;
            data.notes = this.state.notes;
            data.searchIndex = indexedName;
            data.timestamp_added = firebase.firestore.FieldValue.serverTimestamp();
            data.uuid = newID;


            if (this.state.doctor === null) {
                data.doctor = null;
            }else {
                data.doctor = this.state.doctor.value;
            }


            if (this.state.phoneNumbers === null) {
                data.phone = [];
            }else {
                data.phone = this.state.phoneNumbers;
            }


            let balance = this.state.balance || 0;
            data.total_arrears = -balance;

            if (balance > 0) {
                data.hasArrears = true;
            }else {
                data.hasArrears = false;
            }

            console.log(data);

            ref.set(data).then(() => {
                console.log('Success!');
                this.setState({ loading: false });
                this.props.showToast('success', 'Patient saved successfully!');
                this.closeModal();
            }).catch((error) => {
                this.setState({ loading: false });
                console.log(error);
                this.props.showToast('error', 'There was an error...');
            })
        }else {

            //Edit existing patient.
            let data = {};

            //Check name.
            let firstname = this.state.firstname;
            let lastname = this.state.lastname;

            if (firstname !== null) {
                if (firstname.trim().length === 0) {
                    alert('Please insert a valid name.');
                    this.setState({ firstname: null, loading: false });
                    return;
                }
            }


            if (firstname === null) {
                firstname = this.props.patient.fname;
            }

            if (lastname === null) {
                lastname = this.props.patient.lname;
            }else if (lastname.trim().length === 0) {
                lastname = '';
            }

            let indexedName = await this.createIndexedName(firstname, lastname);

            data.searchIndex = indexedName;
            data.fname = firstname;
            data.lname = lastname;


            //Check phone.
            if (this.state.phoneNumbers !== null) {
                data.phone = this.state.phoneNumbers;

                if (this.state.phoneNumbers.length === 0) {
                    data.phone = [];
                }
            }


            //Check email.
            if (this.state.email !== null) {
                data.email = this.state.email;
            }


            //Check doctor.
            if (this.state.doctor !== null) {
                data.doctor = this.state.doctor.value;
            }


            //Check balance.
            if (this.state.balance !== null) {
                data.total_arrears = parseFloat(-this.state.balance);

                if (data.total_arrears > 0) {
                    data.hasArrears = true;
                }else {
                    data.hasArrears = false;
                }
            }


            //Check notes.
            if (this.state.notes !== null) {
                data.notes = this.state.notes;
            }

            console.log(data);

            data.timestampUpdated = firebase.firestore.FieldValue.serverTimestamp();

            const ref = firebaseRef.collection('patients').doc(this.props.patient.uuid);

            ref.update(data).then(() => {
                console.log('Success!');
                this.setState({ loading: false });
                let newData = {...this.props.patient, ...data};
                this.props.updateSearchData(this.props.patient.uuid, newData);
                this.props.showToast('success', 'Patient updated!');
                this.closeModal();
            }).catch(error => {
                this.setState({ loading: false });
                console.log(error);
                this.props.showToast('error', 'There was an error...');
            })
        }
        

        return false;
    }


    testIndexedName = () => {
        this.createIndexedName(this.state.firstname, this.state.lastname);
    }


    createIndexedName = async (fname, lname) => {
        let indexes = [];
        let data = {};

        data.fname = fname;
        data.lname = lname;

        let lettersF = await data.fname.split("");
        let lettersL = await data.lname.split("");

        indexes.push(lettersF[0]);

        for (let x = 1; x < lettersF.length; x++) {
            indexes.push(indexes[x - 1].concat(lettersF[x]));
        }

        if (data.lname && data.lname !== '') {
            let newLength = (indexes.length + lettersL.length);
            let oldIndexesLength = indexes.length;
            
            indexes.push(lettersL[0]);
            
            for (let x = indexes.length; x < newLength; x++) {
                indexes.push(indexes[x - 1].concat(lettersL[x - oldIndexesLength]));
            }

            // Connect first name and last name.
            indexes.push(fname.replace(/\s+/g, '').concat(lname.replace(/\s+/g, '')));
            indexes.push(lname.replace(/\s+/g, '').concat(fname.replace(/\s+/g, '')));
        }

        console.log(indexes);

        return indexes;
    }


    render() {

        let options = [];

        if (this.props.doctors !== null) {
            this.props.doctors.map(item => {
                let name = '';

                if (item.lname === null) {
                    name = item.fname;
                }else {
                    name = item.fname + " " + item.lname;
                }

                options.push({
                    value: item.uuid,
                    label: name
                })
            })
        }


        return (
            <div id="myModal2" className={this.props.display ? "modal show" : "modal"}>

                <div className="modal-content shadow">

                    <div className="d-flex mb-4">
                        <p className="flex-grow-1 h1 font-weight-bold">{this.props.patient ? "Edit Patient" : "New Patient"}</p>
                        <span className="close" onClick={this.closeModal}>&times;</span>
                    </div>

                    <form onSubmit={(e) => this.submitInfo(e)}>
                        <div className="form-row">
                            <div className="col">
                                <label for="fname">First Name</label>
                                <input 
                                    type="text" 
                                    id="fname" 
                                    className="form-control" 
                                    placeholder="First name"
                                    required
                                    value={this.props.patient && this.state.firstname === null ? this.props.patient.fname : !this.state.firstname ? "" : this.state.firstname}
                                    onInput={(e) => this.updateInfo("fname", e)} />
                            </div>


                            <div className="col">
                                <label for="lname">Last Name</label>
                                <input 
                                    type="text" 
                                    id="lname" 
                                    className="form-control" 
                                    placeholder="Last name"
                                    value={this.props.patient && this.state.lastname === null ? this.props.patient.lname : !this.state.lastname ? "" : this.state.lastname}
                                    onInput={(e) => this.updateInfo("lname", e)} />
                            </div>
                        </div>


                        <div className="form-row mt-4">
                            <div className='col'>
                                <label for="docnumber">Phone Number</label>
                                <div className="input-group mb-3">
                                    <input 
                                        type="text" 
                                        id="docnumber" 
                                        className="form-control" 
                                        placeholder="Phone Number"
                                        value={this.state.phone}
                                        onInput={(e) => this.updateInfo("phone", e)} />

                                    <div className="input-group-append">
                                        <button className="btn btn-primary" style={{ padding: '.375rem .75rem' }} type="button" onClick={this.addPhoneNumber}>Add New</button>
                                    </div>
                                </div>

                                <ul className='list-group'>
                                    {this.state.phoneNumbers === null && this.props.patient && this.props.patient.phone !== null ?
                                    
                                        this.setState({ phoneNumbers: this.props.patient.phone })
                                
                                    : null}


                                    {this.state.phoneNumbers !== null ?
                                        this.state.phoneNumbers.map((phone, index) => {
                                            return (
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    {phone}
                                                    <span className="badge badge-pill round-reset" onClick={() => this.deletePhoneNumber(index)}><i className='las la-times'></i></span>
                                                </li>
                                            )
                                        })

                                    : null
                                    }
                                </ul>


                            </div>


                            <div className="col">
                                <label for="docemail">Email address</label>
                                <input 
                                    type="email" 
                                    id="docemail" 
                                    className="form-control" 
                                    placeholder="Email address"
                                    value={this.props.patient && this.state.email === null ? this.props.patient.email : !this.state.email ? "" : this.state.email}
                                    onInput={(e) => this.updateInfo("email", e)} />
                            </div>
                        </div>


                        <div className="form-row mt-4">
                            <div className="col">
                                <label>Doctor:</label>
                                <Select 
                                    options={options} 
                                    value={this.props.patient && this.state.doctor === null ? options[options.findIndex(el => el.value === this.props.patient.doctor)] : this.state.doctor !== null ? this.state.doctor : null}
                                    placeholder="Please select a doctor"
                                    onChange={this.updateDropdown} />
                            </div>


                            <div className="col">
                                <label>Balance</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    aria-describedby="emailHelp"
                                    value={this.props.patient && this.state.balance === null ? -this.props.patient.total_arrears : this.state.balance}
                                    onInput={(e) => this.updateInfo("balance", e)} />
                                    <small id="emailHelp" className="form-text text-muted">If patient has <b>NO</b> previous balance, leave blank or input 0.</small>
                            </div>

                        </div>


                        <div className="form-group mt-3">
                            <label>Patient notes</label>
                            <textarea 
                            className="form-control" 
                            style={{ resize: 'none' }} 
                            rows="3"
                            placeholder="Enter any additional notes for the patient here..."
                            value={this.props.patient && this.state.notes === null ? this.props.patient.notes : this.state.notes !== null ? this.state.notes : ''} 
                            onInput={(e) => this.updateInfo("notes", e)}></textarea>
                        </div>


                        {this.state.loading ? 
                                
                            <div className="col">
                                <button type="button" disabled className="btn btn-primary float-right mr-1">
                                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                    Loading...
                                </button>
                            </div>

                            :

                            <div className="col">
                                <button type="submit" className="btn btn-primary float-right mr-1">{this.props.patient ? "Save changes" : "Submit"}</button>
                            </div>
                        }


                    </form>


                </div>
            </div>
        )
    }
}



export default PatientModal;