// Tippy.js
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
// Interface.
import { Placement } from "tippy.js";

type Props = {
  children: any;
  label: string;
  duration?: number | [number | null, number | null] | undefined;
  placement: Placement;
};

const Tooltip: React.FC<Props> = (props) => {
  const { children, label, duration, placement } = props;

  return (
    <Tippy
      content={label}
      theme="light"
      placement={placement}
      duration={duration}
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
