import React, { Component } from 'react';

function DoctorTable(props) {
        return (
            <table className="table table-borderless table-hover" style={{tableLayout: 'fixed'}} id="doctorsTable">
                <thead>
                <tr>
                    <th className="text-center" scope="row">Short</th>
                    <th scope="row">Firstname</th>
                    <th scope="row">Lastname</th>
                    <th scope="row">Phone Number</th>
                    <th scope="row">Email</th>
                    <th scope="row">Specialty</th>
                    <th scope="row">Employment</th>
                    <th scope="row">Actions</th>
                </tr>
                </thead>
                <tbody>
                {props.data !== null && props.data !== undefined ? 
                    props.data.map(doc => {
                        return (
                            <tr key={doc.id}>
                                <td className="text-center">{doc.abbreviation}</td>
                                <td>{doc.fname}</td>
                                <td>{doc.lname}</td>
                                <td>{doc.phone}</td>
                                <td>
                                    <a href={"mailto:"+doc.email}>{doc.email}</a>    
                                </td>
                                <td className="ellipsis">
                                    {props.specialties.length > 0 ? props.specialties[props.specialties.findIndex(item => item.uuid === doc.specialty)] !== undefined ? props.specialties[props.specialties.findIndex(item => item.uuid === doc.specialty)].title : "DELETED" : ""}
                                </td>
                                <td>
                                    {props.employmentType.length > 0 ? 
                                        props.employmentType[props.employmentType.findIndex(el => el.uuid === doc.employmentType)] !== undefined ?
                                    
                                        <span className={"custom-badge-" + props.employmentType[props.employmentType.findIndex(item => item.uuid === doc.employmentType)].color}>{props.employmentType[props.employmentType.findIndex(item => item.uuid === doc.employmentType)].title}</span> 
                                    
                                    : "DELETED" 
                                    
                                    : null}
                                </td>
                                <td>
                                    <i className="btn btn-sm btn-warning las la-pen mr-1" onClick={() => props.modalTrigger(doc)}></i>
                                    {doc.status === "active" ? 
                                    
                                        <i 
                                            className="btn btn-sm btn-secondary las la-user-times" 
                                            onClick={() => props.deactivateDoctor(doc.uuid)}></i>
                                        :

                                        <i 
                                            className="btn btn-sm btn-primary las la-history" 
                                            onClick={() => props.reactivateDoctor(doc.uuid)}></i>

                                    }
                                </td>
                            </tr>
                        )
                    })
                
                : null}

                </tbody>
            </table>
        )
    }



export default DoctorTable;