import React, { Component } from 'react';
import moment from 'moment';
import firebase from '../../firebase';
import Select from 'react-select';
import Toggle from 'react-toggle';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

const firebaseRef = firebase.firestore();

class AppointmentEdit extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            appdate: null,
            doctor: null,
            selectDoctor: null,
            paymentMethod: null,
            amount: null,
            amountPaid: null,
            patientPaid: null,
            loading: false,
            notes: null,
            fixedFee: null,
            percentageFee: null
        }


        this.baseState = this.state;
    }


    componentDidMount() {
        let modal = document.getElementById("myModal4");

        //Close modal on window click.
        window.onclick = (event) => {
            if (event.target === modal) {
                this.closeModal();
            }
        }
    }


    closeModal = () => {
        this.props.modalTrigger();
        this.setState(this.baseState);
    }


    updateDate = (e) => {
        this.setState({ appdate: e });
    }


    updateDoctor = (info) => {
        this.setState({ doctor: info.value, selectDoctor: info, fixedFee: null, percentageFee: null });
    }

    
    updatePaymentMethod = (info) => {
        this.setState({  paymentMethod: info });
    }


    updateAmount = (e, type) => {
        let amount = e.target.value;
        
        if (amount.length === 0) {
            if (type === "full") {
                this.setState({ amount: "" });
            }else if (type === "paid") {
                this.setState({ amountPaid: "" });
            }else if (type === "fixed") {
                this.setState({ fixedFee: "" });
            }else if (type === 'percentage') {
                this.setState({ percentageFee: '' });
            }
        }else {
            if (type === "full") {
                this.setState({ amount: parseFloat(amount) });
            }else if (type === "paid") {
                this.setState({ amountPaid: parseFloat(amount) });
            }else if (type === "fixed") {
                this.setState({ fixedFee: parseFloat(amount) });
            }else if (type === 'percentage') {
                this.setState({ percentageFee: parseFloat(amount) });
            }
        }
    }


    updatePaid = (e) => {
        let value = e.target.checked;

        this.setState({ patientPaid: value });
    }


    updateNote = (e) => {
        let note = e.target.value;

        if (note.length > 0) {
            this.setState({ notes: note });
        }else {
            this.setState({ notes: "" });
        }
    }


    updateAppointment = () => {
        this.setState({ loading: true });

        let data = {} ;
        let transData = {} ;

        let transChanged = false;
        let docChanged = false;

        let newArrears;

        let isCustomPercentage = false;


        //Check doctor changes.
        if (this.state.doctor !== null && this.state.selectDoctor.value !== this.props.appointment.doctor_uuid) {
            //Doctor has indeed changed.
            // console.log('Doctor has changed');
            docChanged = true;
            data.doctor_uuid = this.state.selectDoctor.value;
        }



        //Appointment cost.
        if (this.state.amount !== null || this.state.patientPaid !== null || this.state.amountPaid || docChanged || this.state.fixedFee !== null || this.state.percentageFee !== null) {
            // console.log('Transaction changed');
            transChanged = true;
            //Check new cost amount.
            let cost = this.state.amount;

            if (cost === null || cost === '') {
                cost = this.props.appointment.gross;
            }

            let initialCost = this.props.appointment.gross;

            let net = 0, net_paid = 0;


            //Calculate arrears / surplus.
            let patientPaid = this.state.patientPaid;

            //Arrears pre-edit.
            let prevArrears = this.props.appointment.transaction.arrears;

            //Amount patient paid.
            let paidAmount = this.state.amountPaid;

            if (patientPaid === null) {
                patientPaid = this.props.appointment.paid;
            }


            if (paidAmount === null || paidAmount === '') {
                if (patientPaid) {
                    paidAmount = cost;
                }else {
                    paidAmount = this.props.appointment.amount_paid;
                }
            }else {
                if (patientPaid) {
                    paidAmount = cost;
                }
            }


            if (cost !== initialCost || docChanged || this.state.fixedFee !== null || paidAmount !== this.props.appointment.amount_paid || patientPaid !== null) {
                // console.log('I am inside the function');
                //Find doctor's payment method.
                let doctorPayment = null;
                let doctorFee = null;

                if (this.state.doctor !== null) {
                    doctorPayment = this.props.doctors[this.props.doctors.findIndex(el => el.uuid === this.state.doctor)].paymentType;

                    doctorFee = this.props.doctors[this.props.doctors.findIndex(el => el.uuid === this.state.doctor)].paymentFee;
                }else {
                    doctorPayment = this.props.doctors[this.props.doctors.findIndex(el => el.uuid === this.props.appointment.doctor_uuid)].paymentType;

                    doctorFee = this.props.doctors[this.props.doctors.findIndex(el => el.uuid === this.props.appointment.doctor_uuid)].paymentFee;
                }

                //Calculate net for clinic.
                if (doctorPayment === 'wage') {
                    // console.log('wage');
                    net = cost;
                    net_paid = paidAmount;
                }else if (doctorPayment === 'percentage') {
                    // console.log('percentage')
                    if (this.state.percentageFee === null) {
                        // Same percentage fee as the one set for the doctor.
                        net = cost - (doctorFee / 100) * cost;
                        net_paid = paidAmount - (doctorFee / 100) * paidAmount;
                    }else {
                        // Custom percentage fee.
                        isCustomPercentage = true;

                        let newPercentage = this.state.percentageFee;

                        if (newPercentage === '') {
                            newPercentage = 0;
                        }

                        net = cost - (newPercentage / 100) * cost;
                        net_paid = paidAmount - (newPercentage / 100) * paidAmount;
                    }
                }else if (doctorPayment === 'fixed') {
                    // console.log('Fixed');
                    if (this.state.fixedFee === null || this.state.fixedFee === '') {
                        net = doctorFee;
                        net_paid = doctorFee;
                    }else {
                        net = this.state.fixedFee;
                        net_paid = net;
                    }
                }

                data.net = net;
                data.net_paid = net_paid;
                
                // Custom percentage fee.
                if (isCustomPercentage) {
                    let newPercentage = this.state.percentageFee;

                    if (newPercentage === '') {
                        data.percentage = 0;
                    }else {
                        data.percentage = parseFloat(this.state.percentageFee);
                    }
                }else {
                    if (doctorPayment === 'percentage') {
                        data.percentage = doctorFee;
                    }
                }


                if (doctorPayment !== 'percentage') {
                    data.percentage = null;
                }
            }


            //New arrears from new transaction.
            let costArrears = cost - paidAmount;


            if (patientPaid || costArrears === 0) {
                paidAmount = cost;
                patientPaid = true;
                newArrears = -prevArrears;
            }else {
                //Patient didn't pay exact amount. Has arrears OR surplus.

                //Arrears from new transaction.
                // console.log('Cost Arrears ' + costArrears);

                if (costArrears > 0) {
                    //Paid less than cost. Creates ARREARS.
                    newArrears = costArrears - prevArrears;
                    patientPaid = false;
                }else if (costArrears < 0) {
                    //Paid more than cost. We have SURPLUS.
                    newArrears = costArrears - prevArrears;
                    patientPaid = true;
                }
            }
            

            data.paid = patientPaid;
            data.gross = cost;
            data.amount_paid = paidAmount;
            transData.amount_full = cost;
            transData.arrears = cost - paidAmount;
            transData.amount_paid = paidAmount;
            
        }


        //Check date changes.
        if (this.state.appdate !== null) {
            //Check for same dates.
            if (firebase.firestore.Timestamp.fromDate(new Date(this.state.appdate)).seconds !== this.props.appointment.date.seconds) {
                //Dates are not the same: Update.
                data.date = firebase.firestore.Timestamp.fromDate(new Date(this.state.appdate));
            }
        }


        //Check payment method changes.
        if (this.state.paymentMethod !== null) {
            if (this.state.paymentMethod.value !== this.props.appointment.paymentMethod) {
                //Payment Method has changed.
                data.paymentMethod = this.state.paymentMethod.value;
            }
        }


        //Check note changes.
        if (this.state.notes !== null) {
            if (this.state.notes !== this.props.appointment.notes) {
                //New note. Check if empty.
                if (this.state.notes.trim().length === 0) {
                    data.notes = null;
                }else {
                    data.notes = this.state.notes;
                }
            }
        }


        // console.log(data);
        // console.log(transData);


        let stateData = {};
        Object.assign(stateData, this.props.appointment);
        stateData = {...stateData, ...data};
        for (var attrname in transData) { stateData.transaction[attrname] = transData[attrname]; }

        // console.log(stateData);

        const ref = firebaseRef.collection('appointments').doc(this.props.appointment.uuid);
        const transRef = firebaseRef.collection('transactions').doc(this.props.appointment.transaction_uuid);
        const patientRef = firebaseRef.collection('patients').doc(this.props.appointment.patient_uuid);


        return firebaseRef.runTransaction(transaction => {
            return transaction.get(patientRef).then((snapshot) => {
                if (snapshot.exists) {
                    if (transChanged) {
                        //Update transaction info as well.
                        let oldArrears = snapshot.data().total_arrears || 0;

                        let total_arrears = oldArrears + newArrears;

                        transaction.update(patientRef, { 'total_arrears': total_arrears, timestamp_last_arrears: firebase.firestore.FieldValue.serverTimestamp() });
                        stateData.patient.total_arrears = total_arrears;
                        stateData.patient.timestamp_last_arrears = firebase.firestore.FieldValue.serverTimestamp();

                        if (total_arrears > 0) {
                            transaction.update(patientRef, { 'hasArrears': true });
                            stateData.patient.hasArrears = true;
                        }else {
                            transaction.update(patientRef, { 'hasArrears': false });
                            stateData.patient.hasArrears = false;
                        }

                        transaction.update(transRef, transData);

                    }

                    transaction.update(ref, data);
                }
            }).then(() => {
                // console.log('Transaction successfully committed!');
                //Update data on local state.
                this.props.updateAppointment(stateData);
                this.closeModal();
                this.props.showToast('success', 'Changes saved successfully!');
                this.setState({ loading: false });
            }).catch((error) => {
                console.log('Transaction failed: ' + error);
                this.setState({ loading: false });
            })
        })

    }

    
    render() {
        let options = [];
        let paymentOptions = [];

        // Doctors options.
        if (this.props.doctors !== null) {
            this.props.doctors.map(doc => {
                let name = '';

                if (doc.lname === null) {
                    name = doc.fname;
                }else {
                    name = doc.fname + " " + doc.lname;
                }

                options.push({
                    value: doc.uuid,
                    label: name,
                    paymentType: doc.paymentType,
                    paymentFee: doc.paymentFee
                })
            })
        }


        //Payment Method options.
        if (this.props.paymentMethods !== null) {
            this.props.paymentMethods.map(el => {
                paymentOptions.push({
                    value: el.uuid,
                    label: el.title
                })
            })
        }



        return (
            <div id="myModal4" className={this.props.display ? "modal show" : "modal"}>
                <div className="modal-content shadow">

                    <div className="d-flex mb-4">
                        <p className="flex-grow-1 h1 font-weight-bold">Edit Appointment</p>
                        <span className="close" onClick={this.closeModal}>&times;</span>
                    </div>

                    <div className="row col">
                        <div className='d-flex flex-column col'>
                            <h4 className='font-weight-bold'>Patient</h4>
                            
                            <span>{this.props.appointment ? this.props.appointment.patient.fname + " " + this.props.appointment.patient.lname : null}</span>
                            
                            <span>
                                {this.props.appointment ?
                                    this.props.appointment.patient.phone.map(item => {
                                        return (
                                            <div>
                                                <span>{item}</span>
                                                <br></br>
                                            </div>
                                        )
                                    }) 
                                    
                                : null}
                            </span>
                            
                            <a href={this.props.appointment ? "mailto:" + this.props.appointment.patient.email : null}>{this.props.appointment ? this.props.appointment.patient.email : null }</a>

                            <span className="font-weight-bold h5">Current Balance: <span>{this.props.appointment ?  -this.props.appointment.patient.total_arrears + "€" : null}</span></span>
                        </div>

                        <div className="col">
                            {this.props.appointment ? this.props.appointment.patient.notes ? this.props.appointment.patient.notes : null : null}
                        </div>
                    </div>



                    <div className="accordion mt-3" id="accordionAppointmentEdit">
                            <div class="card">
                                <div class="card-header custom-acc-header" id="headingOne">
                                    <h6 class="mb-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <span>
                                        Appointment details
                                        </span>
                                    </h6>
                                </div>

                                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionAppointmentEdit">
                                    <div class="card-body">

                                        <div className='row col'>
                                            <div className="col">
                                                <label for="appdate">Appointment date: <span className="font-weight-bold">{moment(this.props.appointment && this.state.appdate === null ? this.props.appointment.date.toDate() : this.state.appdate).format("dddd, MMMM Do")}</span></label>
                                                <br></br>
                                                <DayPickerInput 
                                                    value={this.props.appointment && this.state.appdate === null ? this.props.appointment.date.toDate() : this.state.appdate}
                                                    onDayChange={day => this.updateDate(day)} />
                                            </div>


                                            <div className="col">
                                                <label>Doctor:</label>
                                                <Select 
                                                    value={this.props.appointment && this.state.selectDoctor === null ? options[options.findIndex(el => el.value === this.props.appointment.doctor_uuid)] : this.state.selectDoctor}
                                                    options={options}
                                                    placeholder="Please select a doctor"
                                                    onChange={this.updateDoctor} />
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>





                            <div class="card">
                                <div class="card-header custom-acc-header py-3" id="headingTwo">
                                    <h6 class="mb-0" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                        <span>
                                        Payment details
                                        </span>
                                    </h6>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionAppointmentEdit">
                                    <div class="card-body">

                                        <div className="form-row col mt-3">
                                        <div className="col">
                                            <label>Payment Method:</label>
                                            <Select 
                                                value={this.props.appointment && this.state.paymentMethod === null ? paymentOptions[paymentOptions.findIndex(el => el.value === this.props.appointment.paymentMethod)] : this.state.paymentMethod}
                                                options={paymentOptions}
                                                placeholder="Please select:"
                                                onChange={this.updatePaymentMethod} />
                                        </div>

                                        <div className="form-group col">
                                            <div className='form-group col'>
                                                <label for="exampleInputEmail1">Cost of Appointment (€)</label>
                                                <input 
                                                    type="number" 
                                                    className="form-control" 
                                                    id="exampleInputEmail1"
                                                    value={this.props.appointment && this.state.amount === null ? this.props.appointment.gross : this.state.amount}
                                                    required
                                                    onInput={(e) => this.updateAmount(e, "full")} />
                                            </div>

                                            {this.props.appointment && this.state.patientPaid === null ?
                                                // State not set yet. Get info from props.
                                                this.props.appointment.paid && this.props.appointment.amount_paid === this.props.appointment.gross ?

                                                    null

                                                    :

                                                    <div className='form-group col'>
                                                        <label for="exampleInputEmail1">Amount Paid (€)</label>
                                                        <input 
                                                            type="number" 
                                                            className="form-control" 
                                                            id="exampleInputEmail1"
                                                            value={this.props.appointment && this.state.amountPaid === null ? this.props.appointment.transaction.amount_paid : this.state.amountPaid}
                                                            required
                                                            onInput={(e) => this.updateAmount(e, "paid")} />
                                                    </div>
                                        
                                                :
                                                
                                                this.state.patientPaid === true ? 

                                                null

                                                :

                                                <div className='form-group col'>
                                                    <label for="exampleInputEmail1">Amount Paid (€)</label>
                                                    <input 
                                                        type="number" 
                                                        className="form-control" 
                                                        id="exampleInputEmail1"
                                                        value={this.props.appointment && this.state.amountPaid === null ? this.props.appointment.transaction.amount_paid : this.state.amountPaid}
                                                        required
                                                        onInput={(e) => this.updateAmount(e, "paid")} />
                                                </div>
                                        
                                            }

                                        <div className="form-group">

                                            {this.props.appointment && this.state.selectDoctor === null && this.props.appointment.doctor_uuid !== null ?
                                                // Get data from appointment props.
                                                this.props.doctors[this.props.doctors.findIndex(el => el.uuid === this.props.appointment.doctor_uuid)].paymentType === 'fixed' ?

                                                    <div className='form-group col'>
                                                        <label for="exampleInputEmail1">Our fee (€)</label>
                                                        <input 
                                                            type="number" 
                                                            className="form-control" 
                                                            id="exampleInputEmail1"
                                                            value={this.props.appointment && this.state.fixedFee === null ? this.props.appointment.net : this.state.fixedFee}
                                                            required
                                                            onInput={(e) => this.updateAmount(e, "fixed")} />
                                                    </div>

                                                : null

                                            : this.state.selectDoctor !== null ?

                                                this.props.doctors[this.props.doctors.findIndex(el => el.uuid === this.state.selectDoctor.value)].paymentType === 'fixed' ?

                                                <div className='form-group col'>
                                                    <label for="exampleInputEmail1">Our fee (€)</label>
                                                    <input 
                                                        type="number" 
                                                        className="form-control" 
                                                        id="exampleInputEmail1"
                                                        value={this.props.appointment && this.state.fixedFee === null ? this.props.doctors[this.props.doctors.findIndex(el => el.uuid === this.state.selectDoctor.value)].paymentFee : this.state.fixedFee}
                                                        required
                                                        onInput={(e) => this.updateAmount(e, "fixed")} />
                                                </div>

                                            : null

                                            : null
                                            }


                                            {this.props.appointment && this.state.selectDoctor === null && this.props.appointment.doctor_uuid !== null ?
                                                this.props.doctors[this.props.doctors.findIndex(el => el.uuid === this.props.appointment.doctor_uuid)].paymentType === 'percentage' ?

                                                    <div className='form-group col'>
                                                        <label for="exampleInputEmail1">Doctor's percentage (%)</label>
                                                        <input 
                                                            type="number" 
                                                            className="form-control" 
                                                            id="exampleInputEmail1"
                                                            value={this.props.appointment && this.state.percentageFee === null ? this.props.appointment.percentage : this.state.percentageFee}
                                                            required
                                                            onInput={(e) => this.updateAmount(e, "percentage")} />
                                                        <small>The clinic will get <b>{this.state.percentageFee === null ? 100 - this.props.appointment.percentage : 100 - this.state.percentageFee}%</b> of the amount paid.</small>
                                                    </div>

                                                : null
                                        
                                            : this.state.selectDoctor !== null ?
                                                this.props.doctors[this.props.doctors.findIndex(el => el.uuid === this.state.selectDoctor.value)].paymentType === 'percentage' ?
                                                    <div className='form-group col'>
                                                        <label for="exampleInputEmail1">Doctor's percentage (%)</label>
                                                        <input 
                                                            type="number" 
                                                            className="form-control" 
                                                            id="exampleInputEmail1"
                                                            value={this.props.appointment && this.state.percentageFee === null ? this.props.doctors[this.props.doctors.findIndex(el => el.uuid === this.state.selectDoctor.value)].paymentFee : this.state.percentageFee}
                                                            required
                                                            onInput={(e) => this.updateAmount(e, "percentage")} />
                                                        <small>The clinic will get <b>{this.state.percentageFee === null ? 100 - this.props.doctors[this.props.doctors.findIndex(el => el.uuid === this.state.selectDoctor.value)].paymentFee : 100 - this.state.percentageFee}%</b> of the amount paid.</small>
                                                    </div>

                                                : null

                                            : null
                                            }

                                        </div>




                                        </div>

                                        <div className="col">
                                            <label className="mt-1 mb-2">
                                                Did the patient pay <span className="font-weight-bold">exactly {this.props.appointment && this.state.amount === null ? this.props.appointment.gross + '€' : this.state.amount === null ? "0€" : this.state.amount + '€'} ?</span> <span className="font-weight-bold">{this.props.appointment && this.state.patientPaid === null ? this.props.appointment.paid ? "YES" : "NO" : this.state.patientPaid ? "YES" : "NO" }</span></label>
                                            <br></br>
                                            <Toggle 
                                                defaultChecked={this.props.appointment && (this.state.patientPaid === null) ? this.props.appointment.amount_paid === this.props.appointment.gross ? true : this.props.appointment.amount_paid > this.props.appointment.gross ? false : true : this.state.patientPaid}

                                                checked={this.props.appointment && (this.state.patientPaid === null) ? this.props.appointment.amount_paid === this.props.appointment.gross ? true : this.props.appointment.amount_paid > this.props.appointment.gross ? false : false : this.state.patientPaid}

                                                onChange={(e) => this.updatePaid(e)} />
                                        </div>
                                    </div>

                                    </div>
                                </div>
                            </div>



                            <div class="card">
                                <div class="card-header custom-acc-header py-3" id="headingThree">
                                    <h6 class="mb-0" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                        <span>
                                        Appointment notes
                                        </span>
                                    </h6>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionAppointmentEdit">
                                    <textarea 
                                        className="form-control" 
                                        style={{ resize: 'none' }} 
                                        rows="3"
                                        placeholder="Enter any additional notes here..."
                                        value={this.props.appointment && this.state.notes === null ? this.props.appointment.notes ? this.props.appointment.notes : '' : this.state.notes } 
                                        onInput={(e) => this.updateNote(e)}></textarea>
                                </div>
                            </div>

                        </div>



                        <div className="mt-4">


                            <div className="d-flex justify-content-end mt-4">
                                {this.state.loading ? 
                                
                                    <button class="btn btn-primary" type="button" disabled>
                                        <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                        Loading...
                                    </button>
                            
                                :
                                
                                    <button type="button" className="btn btn-primary float-right" onClick={this.updateAppointment}>
                                        Save changes
                                    </button>
                                }

                            </div>


                        </div>


                </div>
            </div>
        )
    }
}



export default AppointmentEdit;