import React, { Component } from "react";

class DoctorFilters extends Component {
  render() {
    return (
      <div className="d-flex">
        <div className="form-group flex-grow-1">
          <label for="exampleInputEmail1">Search</label>
          <input
            type="text"
            className="form-control w-25"
            id="exampleInputEmail1"
            placeholder="Search for doctor"
            onInput={(e) => this.props.updateFilter(e, "search")}
          />
        </div>

        <div class="form-group mr-4">
          <label for="exampleFormControlSelect1">Specialty</label>
          <select
            className="form-control filter-select"
            id="exampleFormControlSelect1"
            onChange={(e) => this.props.updateFilter(e, "specialty")}
          >
            <option>All Specialties</option>
            {this.props.specialties !== null
              ? this.props.specialties.map((item) => {
                  return <option value={item.uuid}>{item.title}</option>;
                })
              : null}
          </select>
        </div>

        <div class="form-group mr-4">
          <label for="exampleFormControlSelect1">Employment</label>
          <select
            className="form-control filter-select"
            id="exampleFormControlSelect1"
            onChange={(e) => this.props.updateFilter(e, "employment")}
          >
            <option>All</option>
            {this.props.employmentType !== null
              ? this.props.employmentType.map((item) => {
                  return <option value={item.uuid}>{item.title}</option>;
                })
              : null}
          </select>
        </div>

        <div class="form-group">
          <label for="exampleFormControlSelect1">Status</label>
          <select
            className="form-control filter-select"
            id="exampleFormControlSelect1"
            onChange={(e) => this.props.updateFilter(e, "status")}
          >
            <option value="all">All</option>
            <option value="active" selected>
              Active
            </option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      </div>
    );
  }
}

export default DoctorFilters;
