import React, { Component } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

class MetricsCard extends Component {
    render() {
        return (
            <div className='card table-card py-4 text-center'>
                <Tippy 
                    content={this.props.tooltip} 
                    disabled={this.props.tooltip === undefined} >
                    <div>
                        <i className={this.props.icon}></i>
                    </div>
                </Tippy>

                <h1 className="font-weight-bold my-4">{this.props.number}</h1>
                <h2 className="h5 text-muted">{this.props.title}</h2>
            </div>
        )
    }
}



export default MetricsCard;