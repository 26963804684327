import React, { createContext, useReducer } from 'react';

const initialState = {
    searchData: [],
    selectedPatient: null,
    appdate: new Date(),
    doctor: {},
    notes: '',
    payment: {
        cost: 0,
        amount_paid: 0,
        paymentMethod: null,
        paid: true
    }
}

const appointmentContext = createContext(initialState);

const { Provider } = appointmentContext;

const StateProvider = ({ children }) => {
    const [state, dispatch] = useReducer((state, action) => {
        switch(action.type) {
            case 'SET_SEARCH_DATA':
                return {
                    ...state,
                    searchData: action.params,
                    selectedPatient: null
                }
            case 'SELECT_PATIENT': 
                return {
                    ...state,
                    selectedPatient: action.params.patient,
                    doctor: action.params.doctor
                }
            case 'SET_DATE':
                return {
                    ...state,
                    appdate: action.params
                }
            case 'SET_DOCTOR':
                return {
                    ...state,
                    doctor: action.params
                }
            case 'SET_EDIT_DOCTOR':
                return {
                    ...state,
                    doctor: {
                        ...state.doctor,
                        label: action.params.label,
                        value: action.params.value
                    }
                }
            case 'SET_NOTES':
                return {
                    ...state,
                    notes: action.params
                }
            case 'SET_AMOUNT':
                return {
                    ...state,
                    payment: {
                        ...state.payment,
                        [action.params.type]: action.params.data
                    }
                }
            case 'SET_DOCTOR_PAYMENT':
                return {
                    ...state,
                    doctor: {
                        ...state.doctor,
                        [action.params.type]: action.params.data
                    }
                }
            case 'SET_EDIT_APPOINTMENT':
                return {
                    ...state,
                    appdate: action.params.appdate,
                    selectedPatient: action.params.selectedPatient,
                    doctor: action.params.doctor,
                    notes: action.params.notes,
                    payment: action.params.payment
                }
            case 'CLEAR_STATE':
                return initialState;
            default:
                throw new Error();
        }
    }, initialState);

    return <Provider value={{ state, dispatch }}>{children}</Provider>
}


export { appointmentContext, StateProvider };