import React, { Component } from 'react';
import firebase from '../../firebase';
import moment from 'moment';

const firebaseRef = firebase.firestore();

class PatientHistoryModal extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            appointmentsData: null
        }

        this.baseState = this.state;
    }
    

    componentDidMount() {
        let modal = document.getElementById("myModal8");

        //Close modal on window click.
        window.onclick = (event) => {
            if (event.target === modal) {
                this.closeModal();
            }
        }
    }


    componentDidUpdate(prevProps) {
        if (this.props.patient !== null && prevProps.patient === null) {
            //Query patient appointments.
            this.loadPatientAppointments();
        }
    }


    closeModal = () => {
        this.props.triggerHistoryModal();
        this.setState(this.baseState);
    }


    loadPatientAppointments = () => {
        let user = this.props.patient;

        if (user === null || user === undefined) {
            return;
        }

        let query = firebaseRef.collection('appointments').where('patient_uuid', '==', user.uuid);
        let appointments = [];

        // Add different filters and conditions here.


        // After filters have been applied.
        query.get().then(snapshot => {
            if (!snapshot.empty) {
                snapshot.forEach(childSnapshot => {
                    appointments.push(childSnapshot.data());
                })

                appointments.sort(function(a,b){
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return a.date - b.date;
                });;

                this.setState({ appointmentsData: appointments });
            }else {
                this.setState({ appointmentsData: [] })
            }
        })

    }



    render() {
        return (
            <div id="myModal8" className={this.props.display ? "modal show" : "modal"}>

                <div className="modal-content shadow">

                    <div className="d-flex mb-4">
                        <p className="flex-grow-1 h1 font-weight-bold">Patient History</p>
                        <span className="close" onClick={this.closeModal}>&times;</span>
                    </div>


                    {/* Appointment Results */}

                    {this.state.appointmentsData !== null ?
                        this.state.appointmentsData.length > 0 ?
                            <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Doctor</th>
                                        <th scope="col">Payment Method</th>
                                        <th scope="col">Cost</th>
                                        <th scope="col">Paid</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {this.state.appointmentsData.map(item => {
                                        return (
                                            <tr>
                                                <td>
                                                    {item.date !== undefined && item.date !== null ?

                                                        moment(item.date.toDate()).format('DD/MM/YYYY')

                                                        : null
                                                    }
                                                </td>
                                                <td>
                                                    {item.doctor_uuid !== undefined && item.doctor_uuid !== null ?

                                                        this.props.doctors[this.props.doctors.findIndex(el => el.uuid === item.doctor_uuid)] ?

                                                            this.props.doctors[this.props.doctors.findIndex(el => el.uuid === item.doctor_uuid)].abbreviation
                                                    
                                                            : "INACTIVE"

                                                        : null
                                                    }
                                                </td>
                                                <td>
                                                    {item.paymentMethod !== undefined && item.paymentMethod !== null ?
                                                        
                                                        this.props.paymentMethods[this.props.paymentMethods.findIndex(el => el.uuid === item.paymentMethod)].title

                                                        : null
                                                
                                                    }
                                                </td>
                                                <td>{item.gross + '€'}</td>
                                                <td>{item.amount_paid + '€'}</td>
                                            </tr>
                                        )
                                    })
                                    }



                                </tbody>

                            </table>

                        : <h3 className='font-weight-bold'>No results!</h3>

                        : null
                    }


                </div>
            </div>
        )
    }
}



export default PatientHistoryModal;