import React from 'react';
import { formatAmount } from '../../utils/globalUtils';

function DebtModal(props) {
    return ( 
        <div id="myModal200" className={props.display ? "modal show" : "modal"}>
            <div className="modal-content modal-small shadow">
                <div className="d-flex mb-4">
                    <p className="flex-grow-1 h1 font-weight-bold">Active Debt</p>
                    <span className="close" onClick={props.modalTrigger}>×</span>
                </div>
                <h2>Total Debt: <b>{formatAmount(-props.suppliers.filter(sup => sup.total_arrears > 0).reduce((a, b) => a + b.total_arrears, 0)) + " €"}</b></h2>
                <small className="mb-4">This number represents the total amount of money that has been paid.</small>

                <div className="card table-card">
                    <table className="table table-borderless" style={{ tableLayout: 'fixed' }}>
                        <caption><h4 className="font-weight-bold">External Contractors</h4></caption>
                        
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th className="text-center">Balance</th>
                            </tr>
                        </thead>
                        
                        <tbody className="tableoverflow">
                            {/* Se parakolouthw 3eeeeeeeeeeees */}
                            {/* GRAPSE EDW TA SCRIPTAKIA SOU 2.0 */}
                            {
                                props.suppliers.filter(supplier => supplier.total_arrears > 0).sort((a, b) => b.total_arrears - a.total_arrears).map(item => {
                                    return (
                                       <tr>
                                           <td>{item.title}</td>
                                           <td className="text-center">{formatAmount(-item.total_arrears) + " €"}</td>
                                       </tr> 
                                    )
                                })
                            }
                        </tbody>

                    </table>
                

                </div>

                <button type="button" className="btn btn-secondary btn-sm float-right mt-4" onClick={props.modalTrigger}>Close</button>
            </div>
        </div>
    );
}

 
export default DebtModal;