import {
  faBook,
  faStethoscope,
  faUser,
  faBox,
  faChartLine,
  faCog,
  faTimes,
  faTimesCircle,
  faEdit,
  faAddressCard,
  faExclamationCircle,
  faSignOut,
} from "@fortawesome/pro-duotone-svg-icons";

import { faCheck, faCoins } from "@fortawesome/pro-regular-svg-icons";

export const duotoneIcons = {
  BOOK: faBook,
  STETHOSCOPE: faStethoscope,
  USER: faUser,
  BOX: faBox,
  METRICS: faChartLine,
  SETTINGS: faCog,
  TIMES: faTimes,
  TIMES_CIRCLE: faTimesCircle,
  EDIT: faEdit,
  ADDRESS_CARD: faAddressCard,
  EXCLAMATION_CIRCLE: faExclamationCircle,
  SIGN_OUT: faSignOut,
};

export const regularIcons = {
  CHECK: faCheck,
  COINS: faCoins,
};
