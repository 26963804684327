import firebase from '../firebase';
import moment from 'moment';

const firebaseRef = firebase.firestore();


export const appointmentsQuery = async (filter) => {
    let doc = await firebaseRef.collection('appointments').where('date', '>=', filter.from).where('date', '<=', filter.to).get();

    if (!doc.empty) {
        let docs = doc.docs;
        let data = [];

        docs.forEach(snapshot => {
            data.push(snapshot.data());
        })

        return data;
    }else {
        return [];
    }
}



export const transactionsQuery = async (filter) => {
    let doc = await firebaseRef.collection('transactions').where('date', '>=', filter.from).where('date', '<=', filter.to).where('type', '==', 'supplier').get();

    if (!doc.empty) {
        let docs = doc.docs;
        let data = [];

        docs.forEach(snapshot => {
            data.push(snapshot.data());
        })

        return data;
    }else {
        return [];
    }
}


export const arrearsQuery = async (filter) => {
    let doc = await firebaseRef.collection('transactions').where('date', '>=', filter.from).where('date', '<=', filter.to).get();

    if (!doc.empty) {
        let docs = doc.docs;
        let data = [];

        docs.forEach(snapshot => {
            if (snapshot.data().type === undefined) {
                data.push(snapshot.data());
            }
        })

        return data;
    }else {
        return [];
    }
}



export const patientQuery = async (filter) => {
    let doc = await firebaseRef.collection('patients').where('timestamp_added', '>=', filter.from).where('timestamp_added', '<=', filter.to).get();

    return doc.size;
}



export const overviewMetrics = async (appData) => {
    return { totalAppointments: appData.length }
}