import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { store } from "store/store";
import WebFont from "webfontloader";
// Screens.
import Login from "./components/Login/Login";
import AppWrapper from "AppWrapper";
// Firebase.
import firebase from "./firebase";

type Props = {};

const AuthNavigator: React.FC<Props> = (props) => {
  const [signedIn, setSignedIn] = useState<boolean | null>(null);
  const [user, setUser] = useState<any>();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setSignedIn(true);
      } else {
        setSignedIn(false);
      }
    });
  }, []);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Inter"],
      },
    });
  }, []);

  if (signedIn === null) return null;

  return (
    <Provider store={store}>
      {signedIn ? <AppWrapper user={user} /> : <Login />}
    </Provider>
  );
};

export default AuthNavigator;
