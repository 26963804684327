import React, { Component } from 'react';
import firebase from '../../firebase';
import Swal from 'sweetalert2';

const firebaseRef = firebase.firestore();

class SettingsTab extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             email: null,
             role: null,
             loading: false,
             users: null
        }
    }


    componentDidMount() {
        this.loadUsers()
    }


    addUser = (user) => {
        let currentUsers = this.state.users;

        currentUsers.push(user);

        this.setState({ users: currentUsers });
    }


    updateInfo = (e, type) => {
        let value = e.target.value;

        if (type === 'email') {
            this.setState({ email: value });
        }else {
            this.setState({ role: value });
        }
    }


    loadUsers = () => {
        firebaseRef.collection('users').get().then(snapshot => {
            let currentUsers = this.state.users || [];

            snapshot.forEach(item => {
                currentUsers.push(item.data());
            });

            this.setState({ users: currentUsers });
        });
    }


    createUser = () => {
        let email = this.state.email;
        let role = this.state.role;

        if (email === null) {
            this.props.showToast('error', 'Please enter an email address!');
            return;
        }else {
            if (email.trim().length === 0) {
                this.props.showToast('error', 'Please enter a valid email address!');
                return;
            }
        }

        this.setState({ loading: true });

        let data = {};

        if (role === null) {
            role = '1';
        }

        data.role = parseInt(role);
        data.email = email;

        if (this.props.user !== null) {
            data.user_uuid = this.props.user.uuid;
        }else {
            data.user_uuid = null;
        }


        let addUser = firebase.functions().httpsCallable('createUser');

        addUser({data: data}).then(result => {
            console.log(result);
            firebase.auth().sendPasswordResetEmail(email).catch((error) => {
                console.log(error);
                this.props.showToast('error', 'Email was not sent...');
            });
            this.addUser(data);
            this.setState({ loading: false });
            this.props.showToast('success', 'User account created successfully!');
        }).catch(error => {
            console.log(error);
        });
    }


    resetPassword = (email) => {
        Swal.fire({
            title: 'Reset password?',
            html: "<p>A password-reset email will be sent to <b>" + email + "</b>, asking the user to reset their password.</p>",
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes, reset password!'
        }).then((result) => {
            if (result.value) {
                firebase.auth().sendPasswordResetEmail(email).then(() => {
                    this.props.showToast('success', 'Password has been reset!');
                }).catch((error) => {
                    this.props.showToast('error', 'There was an error...');
                    console.log(error);
                });
            }
        })
    }

    
    render() {
        if (this.props.selected === 1) {
            //Load Doctors Settings.
            return (
                <div>
                    <h2 className="font-weight-bold">Doctor Settings</h2>
                    
                    <ul className="list-group">
                        {/* Specialties */}
                        <li className="list-group-item">
                            <div className="d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapseSpecialties" aria-expanded="false" aria-controls="collapseSpecialties">
                                Specialties <i className="las la-chevron-left"></i>
                            </div>

                            <div className="collapse" id="collapseSpecialties">
                                <ul className="list-group list-group-flush pt-3">
                                    {this.props.specialties ?
                                        this.props.specialties.map(item => {
                                            return (
                                                <li className="list-group-item d-flex" key={item.uuid}><span className="flex-grow-1">{item.title}</span> <i className="las la-pen mr-3 set-icon" onClick={() => this.props.editSettings(item.uuid, 'specialty')}></i><i className="las la-minus-circle set-icon" onClick={() => this.props.deleteSettings(item.uuid, "specialty")}></i></li>
                                            )
                                        })
                                    : null}
                                    <span className='ml-3 mt-4' style={{ cursor: 'pointer' }} onClick={() => this.props.addSettings('specialty')}><i className="las la-plus-circle set-icon mr-2"></i> Add new</span>
                                </ul>
                            </div>
                        </li>

                        {/* Employment Type */}
                        <li className="list-group-item">
                            <div className="d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapseEmployment" aria-expanded="false" aria-controls="collapseEmployment">
                                Employment Type <i className="las la-chevron-left"></i>
                            </div>

                            <div className="collapse" id="collapseEmployment">
                                <ul className="list-group list-group-flush pt-3">
                                    {this.props.employmentType !== null ?
                                        this.props.employmentType.map(item => {
                                            return (
                                                <li className="list-group-item d-flex" key={item.uuid}><span className="flex-grow-1">{item.title}</span> <i className="las la-pen mr-3 set-icon" onClick={() => this.props.editSettings(item.uuid, 'employment')}></i><i className="las la-minus-circle set-icon" onClick={() => this.props.deleteSettings(item.uuid, "employment")}></i></li>
                                            )
                                        })
                                    : null}
                                    <span className='ml-3 mt-4' style={{ cursor: 'pointer' }} onClick={() => this.props.addSettings('employment')}><i className="las la-plus-circle set-icon mr-2"></i> Add new</span>
                                </ul>
                            </div>
                        </li>
                    </ul>


                </div>
            )
        }else if (this.props.selected === 3) {
            //Supplier Settings.
            return (
                <div>
                    <h2 className="font-weight-bold">Supplier Settings</h2>

                    <ul className="list-group">
                        <li className="list-group-item">
                            <div className="d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapsePaymentsSupplier" aria-expanded="false" aria-controls="collapsePaymentsSupplier">
                                Payment Types <i className="las la-chevron-left"></i>
                            </div>

                            <div className="collapse" id="collapsePaymentsSupplier">
                                <ul className="list-group list-group-flush pt-3">
                                    {this.props.paymentMethods ?
                                        this.props.paymentMethods.map(item => {
                                            if (item.type === 'supplier') {
                                                return (
                                                    <li className="list-group-item d-flex" key={item.uuid}><span className="flex-grow-1">{item.title}</span> <i className="las la-pen mr-3 set-icon" onClick={() => this.props.editSettings(item.uuid, 'payment')}></i><i className="las la-minus-circle set-icon" onClick={() => this.props.deleteSettings(item.uuid, "payment")}></i></li>
                                                )
                                            }
                                        })
                                    : null}

                                    <span className='ml-3 mt-4' style={{ cursor: 'pointer' }} onClick={() => this.props.addSettings('supplierPayment')}><i className="las la-plus-circle set-icon mr-2"></i> Add new</span>
                                </ul>
                            </div>
                        </li>


                        <li className="list-group-item">
                            <div className="d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapseSource" aria-expanded="false" aria-controls="collapseSource">
                                Source <i className="las la-chevron-left"></i>
                            </div>

                            <div className="collapse" id="collapseSource">
                                <ul className="list-group list-group-flush pt-3">
                                    {this.props.supplierSource ?
                                        this.props.supplierSource.map(item => {
                                            return (
                                                <li className="list-group-item d-flex" key={item.uuid}><span className="flex-grow-1">{item.title}</span> <i className="las la-pen mr-3 set-icon" onClick={() => this.props.editSettings(item.uuid, 'source')}></i><i className="las la-minus-circle set-icon" onClick={() => this.props.deleteSettings(item.uuid, "source")}></i></li>
                                            )
                                        })
                                    : null}

                                    <span className='ml-3 mt-4' style={{ cursor: 'pointer' }} onClick={() => this.props.addSettings('source')}><i className="las la-plus-circle set-icon mr-2"></i> Add new</span>
                                </ul>
                            </div>
                        </li>

                    </ul>


                </div>
            )



        }else if (this.props.selected === 5) {
            //Payments Settings
            return (
                <div>
                    <h2 className="font-weight-bold">Payment Settings</h2>
                    
                    <ul className="list-group">
                        {/* Specialties */}
                        <li className="list-group-item">
                            <div className="d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapsePayments" aria-expanded="false" aria-controls="collapsePayments">
                                Payment Methods <i className="las la-chevron-left"></i>
                            </div>

                            <div className="collapse show" id="collapsePayments">
                                <ul className="list-group list-group-flush pt-3">
                                    {this.props.paymentMethods ?
                                        this.props.paymentMethods.map(item => {
                                            if (item.type === undefined || item.type !== 'supplier') {
                                                return (
                                                    <li className="list-group-item d-flex" key={item.uuid}><span className="flex-grow-1">{item.title}</span> <i className="las la-pen mr-3 set-icon" onClick={() => this.props.editSettings(item.uuid, 'payment')}></i><i className="las la-minus-circle set-icon" onClick={() => this.props.deleteSettings(item.uuid, "payment")}></i></li>
                                                )
                                            }
                                        })
                                    : null}
                                    <span className='ml-3 mt-4' style={{ cursor: 'pointer' }} onClick={() => this.props.addSettings('payment')}><i className="las la-plus-circle set-icon mr-2"></i> Add new</span>
                                </ul>
                            </div>
                        </li>
                    </ul>


                </div>
            )



        }else if (this.props.selected === 6) {
            //User accounts.
            return (
                <div>
                    <h2 className="font-weight-bold mb-3">User Accounts</h2>

                    <div className='form-row'>
                        <div className="form-group col">
                            <label for="exampleInputEmail1">Email address</label>
                            <input 
                                type="email" 
                                className="form-control" 
                                id="exampleInputEmail1" 
                                value={this.state.email}
                                placeholder="Enter user's email address"
                                onInput={(e) => this.updateInfo(e, 'email')} />
                        </div>


                        <div className="form-group col">
                            <label for="exampleFormControlSelect1">Account Role</label>
                            <select 
                                className="form-control" 
                                id="exampleFormControlSelect1"
                                onChange={(e) => this.updateInfo(e, 'role')}
                                value={this.state.role}>
                                <option value='1'>1 - Simple User</option>
                                <option value='2'>2 - Admin</option>
                            </select>
                        </div>
                    </div>

                    <small>The user will receive an email with a link to create a password. Please notify them!</small>
                    
                    <div className='mt-3 text-center'>
                        {this.state.loading ? 
                            <button class="btn btn-primary" type="button" disabled>
                                <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                Loading...
                            </button>
                        : 
                            <button type="button" class="btn btn-primary" onClick={this.createUser}>Create User</button>
                        }
                    </div>




                    <div className='mt-3 card table-card table-scroll'>
                        <table class="table">
                            <thead>
                                <tr>
                                <th scope="col">User's email</th>
                                <th scope="col">User Role</th>
                                <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>

                                {this.state.users !== null ?
                                    this.state.users.map(item => {
                                        return (
                                            <tr>
                                                <td>{item.email}</td>
                                                <td>
                                                    {item.role === 1 ? 'Simple User' : 'Admin'}
                                                </td>
                                                <td>
                                                    <i className="btn btn-sm btn-primary las la-envelope mr-2" onClick={() => this.resetPassword(item.email)}></i>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    
                            
                                : null }
                                
                            </tbody>
                        </table>    
                    </div>

                    
                </div>
            )
        }else {
            return (
                ""
            )
        }
    }
}



export default SettingsTab;