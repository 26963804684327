import React, { useState, useContext } from "react";
// Components.
import AppointmentsTable from "./AppointmentsTable";
import AppointmentModalV2 from "./AppointmentModalV2";
import AppointmentEditV2 from "./AppointmentEditV2";
import AppointmentEdit from "./AppointmentEdit";
import EarningsModal from "../Modals/EarningsModal";
// Context.
import { appointmentContext } from "../../context/appointmentContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regularIcons } from "icons/icons";
// import { StateProvider as AppointmentProvider } from '../../context/appointmentContext';

function AppointmentsV2(props) {
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [earningsModal, setEarningsModal] = useState(false);
  const [originalEdit, setOriginalEdit] = useState(false);
  const [appointment, setAppointment] = useState(null);

  const appointmentCon = useContext(appointmentContext);
  const { dispatch, state } = appointmentCon;

  const showEditModal = (appData) => {
    setAppointment(appData);
    let doctor = {
      value: appData.doctor_uuid,
      label:
        props.doctors[
          props.doctors.findIndex((a) => a.uuid === appData.doctor_uuid)
        ].fname +
          " " +
          props.doctors[
            props.doctors.findIndex((a) => a.uuid === appData.doctor_uuid)
          ]?.lname ?? "",
      paymentType: appData.paymentType,
      paymentFee: appData.paymentFee,
    };
    let payment = {
      cost: appData.gross,
      amount_paid: appData.amount_paid,
      paymentMethod: {
        value: appData.paymentMethod,
        label:
          props.paymentMethods[
            props.paymentMethods.findIndex(
              (a) => a.uuid === appData.paymentMethod
            )
          ]?.title ?? "",
      },
      paid: appData.paid && appData.gross === appData.amount_paid,
    };
    dispatch({
      type: "SET_EDIT_APPOINTMENT",
      params: {
        selectedPatient: appData.patient,
        appdate: appData.date.toDate(),
        doctor: doctor,
        notes: appData.notes,
        payment: payment,
      },
    });
    appData.paymentType ? setEditModal(true) : setOriginalEdit(true);
  };

  const sortTimestamps = (a, b) => {
    return b.date - a.date;
  };

  return (
    <div style={{ overflow: "auto", height: "100vh", paddingBottom: "5vh" }}>
      <div className="doc-container d-flex flex-column">
        {/* Header */}
        <div className="d-flex">
          <h1 className="fw-bolder my-4">Appointments</h1>

          <div className="h-100 align-self-center">
            <button
              type="button"
              className="btn btn-sm btn-primary ml-3"
              onClick={() => setShowModal(true)}
            >
              Add New
            </button>
          </div>

          <div className="flex-grow-1"></div>

          <div className="h-100 align-self-center">
            <button
              type="button"
              className="btn btn-sm btn-warning d-flex align-items-center"
              onClick={() => setEarningsModal(true)}
            >
              <FontAwesomeIcon className="mr-2" icon={regularIcons.COINS} />
              Earnings
            </button>
          </div>
        </div>
        {/* End Header */}

        {/* Table */}
        <div className="flex-grow-1">
          <AppointmentsTable
            patients={
              props.data !== null ? props.data.sort(sortTimestamps) : null
            }
            doctors={
              props.doctors !== null
                ? props.doctors.filter((el) => el.status === "active")
                : null
            }
            paymentMethods={props.paymentMethods}
            modalTrigger={showEditModal}
            addAppointment={props.addAppointment}
            deleteAppointment={props.deleteAppointment}
            filterAppointments={props.filterAppointments}
          />
        </div>
        {/* End Table */}

        {/* Modals */}
        <AppointmentModalV2
          display={showModal}
          modalTrigger={setShowModal}
          doctors={
            props.doctors !== null
              ? props.doctors.filter((el) => el.status === "active")
              : null
          }
          paymentMethods={props.paymentMethods}
          showToast={props.showToast}
          addAppointment={props.addAppointment}
          user={props.user}
        />

        <AppointmentEditV2
          display={editModal}
          appointment={appointment}
          modalTrigger={setEditModal}
          doctors={props.doctors?.filter((el) => el.status === "active")}
          paymentMethods={props.paymentMethods}
          showToast={props.showToast}
          updateAppointment={props.updateAppointment}
          user={props.user}
        />

        <AppointmentEdit
          display={originalEdit}
          modalTrigger={() => setOriginalEdit(false)}
          doctors={props.doctors !== null ? props.doctors : null}
          appointment={appointment}
          paymentMethods={
            props.paymentMethods !== null ? props.paymentMethods : null
          }
          showToast={props.showToast}
          updateAppointment={props.updateAppointment}
        />

        <EarningsModal
          display={earningsModal}
          modalTrigger={() => setEarningsModal(false)}
          data={props.data}
          doctors={props.doctors !== null ? props.doctors : null}
        />

        {/* End Modals */}
      </div>
    </div>
  );
}

export default AppointmentsV2;
