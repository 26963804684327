import React, { useState, useContext } from 'react';
import Select from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import firebase from '../../firebase';
// Components.
import Step3 from './ModalComponents/Step3';
// Context.
import { appointmentContext } from '../../context/appointmentContext';
// Utils.
import * as utils from '../../utils/appointmentUtils';

const firebaseRef = firebase.firestore();


function AppointmentEditV2(props) {
    const [loading, setLoading] = useState(false);

    const appointmentCon = useContext(appointmentContext);
    const { dispatch, state } = appointmentCon;

    // Doctors options.
    let options = [];
    if (props.doctors !== null) {
        props.doctors.map(doc => {
            let name = '';

            if (doc.lname === null) {
                name = doc.fname;
            }else {
                name = doc.fname + " " + doc.lname;
            }

            options.push({
                value: doc.uuid,
                label: name,
                paymentType: doc.paymentType,
                paymentFee: doc.paymentFee
            })
        })
    }

    const closeModal = () => {
        dispatch({ type: 'CLEAR_STATE' });
        props.modalTrigger(false);
        setLoading(false);
    }

    const updateAppointment = () => {
        setLoading(true);
        let stateData = props.appointment;

        let newData = {
            timestamp_updated: firebase.firestore.FieldValue.serverTimestamp()
        };
        let transData = {};

        let docChanged = utils.checkDoctorChanges(props.appointment, state)
        let financialsChanged = utils.financialsChanged(props.appointment, state);
        let notesChanged = utils.notesChanged(props.appointment, state);
        let paymentMethodChanged = utils.paymentMethodChanged(props.appointment, state);
        let dateChanged = utils.dateChanged(props.appointment, state);

        if (docChanged) {
            newData.doctor_uuid = state.doctor.value;
        }

        if (notesChanged) {
            newData.notes = state.notes;
        }

        if (paymentMethodChanged) {
            newData.paymentMethod = state.payment.paymentMethod.value;
        }

        if (dateChanged) {
            newData.date = firebase.firestore.Timestamp.fromDate(new Date(state.appdate));
        }

        if (financialsChanged) {
            let financials = calculateFinancials();
            financials.gross = parseFloat(state.payment.cost);

            Object.assign(newData, financials);

            transData.amount_full = parseFloat(state.payment.cost);
            transData.amount_paid = financials.amount_paid;
            transData.arrears = parseFloat(state.payment.cost) - financials.amount_paid;

            Object.assign(stateData, financials);
            stateData = {
                ...stateData,
                transaction: {
                    amount_full: transData.amount_full,
                    amount_paid: transData.amount_paid,
                    arrears: transData.arrears
                }
            }

            console.log(financials);
            console.log('TRANSACTION DATA', transData);
        }

        
        let appointmentRef = firebaseRef.collection('appointments').doc(props.appointment.uuid);
        let transactionRef = firebaseRef.collection('transactions').doc(props.appointment.transaction_uuid);
        Object.assign(stateData, newData);
        
        // Update.
        if (!financialsChanged) {
            appointmentRef.update(newData);
            props.updateAppointment(stateData);
        }else {
            let patientRef = firebaseRef.collection('patients').doc(props.appointment.patient_uuid);

            return firebaseRef.runTransaction(transaction => {
                return transaction.get(patientRef).then((snapshot) => {
                    if (snapshot.exists) {
                        //Update transaction info as well.
                        let oldArrears = snapshot.data().total_arrears || 0;

                        let total_arrears = oldArrears + (transData.arrears - props.appointment.transaction.arrears);

                        transaction.update(patientRef, { 'total_arrears': total_arrears, timestamp_last_arrears: firebase.firestore.FieldValue.serverTimestamp() });
                        stateData.patient.total_arrears = total_arrears;
                        stateData.patient.timestamp_last_arrears = firebase.firestore.FieldValue.serverTimestamp();

                        if (total_arrears > 0) {
                            transaction.update(patientRef, { 'hasArrears': true });
                            stateData.patient.hasArrears = true;
                        }else {
                            transaction.update(patientRef, { 'hasArrears': false });
                            stateData.patient.hasArrears = false;
                        }

                        transaction.update(transactionRef, transData);
                        transaction.update(appointmentRef, newData);
                    }
                }).then(() => {
                    console.log('Transaction successfully committed!');
                    //Update data on local state.
                    props.updateAppointment(stateData);
                    closeModal();
                    props.showToast('success', 'Changes saved successfully!');
                }).catch((error) => {
                    console.log('Transaction failed: ' + error);
                    props.showToast('error', 'There was an error...')
                    setLoading(false);
                })
            })
        }
    }

    const calculateFinancials = () => {
        switch(state.doctor.paymentType) {
            case 'wage':
                return {
                    amount_paid: parseFloat(state.payment.paid ? state.payment.cost : state.payment.amount_paid),
                    net: parseFloat(state.payment.cost),
                    net_paid: parseFloat(state.payment.paid ? state.payment.cost : state.payment.amount_paid),
                    paid: (state.payment.paid || parseFloat(state.payment?.amount_paid) >= parseFloat(state.payment.cost)) ? true : false,
                    paymentType: 'wage',
                    paymentFee: 0
                }
            case 'fixed':
                let fixedResult = {
                    amount_paid: parseFloat(state.payment.paid ? state.payment.cost : state.payment.amount_paid),
                    net: parseFloat(state.payment.cost) - parseFloat(state.doctor.paymentFee),
                    paid: (state.payment.paid || parseFloat(state.payment?.amount_paid) >= parseFloat(state.payment.cost)) ? true : false,
                    paymentType: 'fixed',
                    paymentFee: parseFloat(state.doctor.paymentFee)
                }
                
                let amount_paid = state.payment.paid ? parseFloat(state.payment.cost) : parseFloat(state.payment.amount_paid);
                let netResult = parseFloat(amount_paid) - parseFloat(state.doctor.paymentFee);

                fixedResult.net_paid = netResult;

                return fixedResult;
            case 'percentage':
                let percentageResult = {
                    amount_paid: parseFloat(state.payment.paid ? state.payment.cost : state.payment.amount_paid),
                    net: parseFloat(state.payment.cost) - ((parseFloat(state.doctor.paymentFee) / 100) * parseFloat(state.payment.cost)),
                    paid: (state.payment.paid || parseFloat(state.payment?.amount_paid) >= parseFloat(state.payment.cost)) ? true : false,
                    paymentType: 'percentage',
                    paymentFee: parseFloat(state.doctor.paymentFee)
                }

                let amount_paid2 = state.payment.paid ? parseFloat(state.payment.cost) : parseFloat(state.payment.amount_paid);
                let doctorPercentage = parseFloat(state.payment.cost) - percentageResult.net;

                percentageResult.net_paid = amount_paid2 - doctorPercentage;

                return percentageResult;
        }
    }


    return (
        <div id="myModal4" className={props.display ? "modal show" : "modal"}>
            <div className="modal-content shadow">

                <div className="d-flex mb-4">
                    <p className="flex-grow-1 h1 font-weight-bold">Edit Appointment</p>
                    <span className="close" onClick={closeModal}>&times;</span>
                </div>

                {/* Main Content */}
                <ul class="nav nav-tabs nav-justified" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Payment Details</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Appointment Details</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    {/* Appointment Details Tab */}
                    <div class="tab-pane fade mt-4" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="form-row col">
                            <div className="col">
                                <label for="appdate">Appointment date: <span className="font-weight-bold">{moment(state.appdate).format('dddd, MMMM Do')}</span></label>
                                <br></br>
                                <DayPickerInput 
                                    value={state.appdate}
                                    onDayChange={day => dispatch({ type: 'SET_DATE', params: day })} 
                                    />
                            </div>
                            
                            <div className="col">
                                <span>Doctor:</span>
                                <Select 
                                    value={options[options.findIndex(el => el.value === state.doctor.value)] ?? null}
                                    options={options}
                                    placeholder="Please select a doctor:"
                                    onChange={(info) => dispatch({ type: 'SET_EDIT_DOCTOR', params: info })} 
                                    />
                            </div>
                        </div>

                        <div className="form-row col mt-5">
                            <textarea 
                                className="form-control" 
                                style={{ resize: 'none' }} 
                                rows="3"
                                value={state.notes}
                                placeholder="Enter any additional notes here..."
                                onInput={(e) => dispatch({ type: 'SET_NOTES', params: e.target.value }) } 
                                />
                        </div>
                    </div>
                    {/* End Appointment Details */}


                    {/* Payment Details Tab */}
                    <div class="tab-pane fade show active py-5" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <Step3 paymentMethods={props.paymentMethods} type="edit" />
                    </div>
                    {/* End Payment Details Tab */}
                </div>


                {/* Footer */}
                <div className="d-flex justify-content-end mt-4">
                    <button type="button" disabled={loading} className="btn btn-primary float-right" onClick={() => updateAppointment()}>
                        {loading ? 
                            [<span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>,
                            'Loading...']
                        :
                            ['Update Appointment',
                            <i className="las la-arrow-right ml-2"></i>]
                        }
                    </button>
                </div>
                {/* End Footer */}

            </div>
        </div>
    )
}



export default AppointmentEditV2;