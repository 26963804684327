import React, { useEffect, useState } from 'react';

function EarningsModal(props) {
    const [result, setResult] = useState([]);

    useEffect(() => {
        calculateEarnings();
    }, [props.display]);

    const calculateEarnings = () => {
        if (props.data === null || props.doctors === null) {
            return;
        }

        let data = [];
        let doctors = props.doctors.filter(el => el.paymentType !== 'wage');

        let relevantAppointments = props.data.filter(arr => doctors.some(filter => filter.uuid === arr.doctor_uuid));

        if (relevantAppointments.length === 0) {
            return;
        }

        doctors.forEach(async (item, index) => {
            let sum = props.data.filter(el => el.doctor_uuid === item.uuid);
            let totalGross = sum.reduce((a,b) => a + b.amount_paid, 0);    
            let totalNet = sum.reduce((a,b) => a + b.net_paid, 0);

            let newEntry = {
                doctor: item.uuid,
                gross: totalGross,
                net: totalNet
            }

            data.push(newEntry);
        })

            setResult(data);
    }

    return ( 
        <div id="myModal100" className={props.display ? "modal show" : "modal"}>
            <div class="modal-content modal-small shadow">
                <div class="d-flex mb-4">
                    <p class="flex-grow-1 h1 font-weight-bold">Earnings</p>
                    <span class="close" onClick={props.modalTrigger}>×</span>
                </div>
                <h2>Total Earnings: <b>{props.data ? props.data.reduce((a, b) => a + b.amount_paid, 0) + " €" : '0 €'}</b></h2>
                <small class="mb-4">This number represents the total amount of money that has been paid.</small>

                <div className="my-4">
                    <h4 className="font-weight-bold">External Contractors</h4>
                    {result.length > 0 ?
                        result.map(item => {
                            if (item.gross > 0) {
                                return (
                                    <div className="row my-2">
                                        <h6 className="col-5">{props.doctors[props.doctors.findIndex(el => el.uuid === item.doctor)].fname + " " + props.doctors[props.doctors.findIndex(el => el.uuid === item.doctor)].lname}</h6>
                                        <h6 className="offset-1 col-6">{item.gross + "€ - " + item.net + "€ = "}<b>{(item.gross - item.net) + "€"}</b></h6>
                                    </div>
                                )
                            }
                        })
                    : 
                        <h6>External Contractors had no appointments at the selected time.</h6>
                    }
                </div>

                <button type="button" class="btn btn-secondary btn-sm float-right mt-4" onClick={props.modalTrigger}>Close</button>
            </div>
        </div>
    );
}

 
export default EarningsModal;