import React, { useState, useEffect } from "react";
import { HorizontalBar, Doughnut } from "react-chartjs-2";
import moment from "moment";
// Components.
import MetricsCard from "./MetricsCard";
import PINModal from "../Modals/PINModal";
import MetricsModal from "./MetricsModal";
// Utilities.
import * as utils from "../../utils/metricsUtils";
import { formatAmount, getRandomColor } from "../../utils/globalUtils";

function MetricsV2(props) {
  // Hooks.
  const [filter, setFilter] = useState({
    from: moment().startOf("day").toDate(),
    to: moment().endOf("day").toDate(),
    name: "Today",
  });
  const [loaded, setLoaded] = useState(false);
  const [overview, setOverview] = useState({});
  const [patientMetrics, setPatientMetrics] = useState({});
  const [employeePerformance, setEmployeePerformance] = useState({});
  const [collaboratorPerformance, setCollaboratorPerformance] = useState({});
  const [clinicUtilization, setClinicUtilization] = useState({});
  const [revenueBreakdownChart, setRevenueBreakdownChart] = useState({});
  const [revenueBreakdown, setRevenueBreakdown] = useState({});
  const [supplierMetrics, setSupplierMetrics] = useState({});
  const [costsBreakdown, setCostsBreakdown] = useState({});
  const [typeOfCosts, setTypeOfCosts] = useState({});
  const [operationalMargins, setOperationalMargins] = useState({});
  // Lock Hook.
  const [locked, setLocked] = useState(true);
  const [displayPIN, setDisplayPIN] = useState(false);
  const [displayCustomDate, setDisplayCustomDate] = useState(false);

  // Functionalities.
  const triggerPIN = () => {
    if (displayPIN) {
      setDisplayPIN(false);
    } else {
      locked ? setDisplayPIN(true) : setLocked(true);
    }
  };

  const changeFilter = (filter, data) => {
    let newFilter = {};

    switch (filter) {
      case "today":
        newFilter = {
          from: moment().startOf("day").toDate(),
          to: moment().endOf("day").toDate(),
          name: "Today",
        };
        setFilter(newFilter);
        fetchData(newFilter);
        break;
      case "current":
        newFilter = {
          from: moment().startOf("month").toDate(),
          to: moment().endOf("month").toDate(),
          name: `This Month (${moment().format("MMMM YYYY")})`,
        };
        setFilter(newFilter);
        fetchData(newFilter);
        break;
      case "last":
        newFilter = {
          from: moment().startOf("month").subtract(1, "month").toDate(),
          to: moment().endOf("month").subtract(1, "month").toDate(),
          name: `Last Month (${moment()
            .subtract(1, "month")
            .format("MMMM YYYY")})`,
        };
        setFilter(newFilter);
        fetchData(newFilter);
        break;
      case "last6":
        newFilter = {
          from: moment().startOf("month").subtract(5, "month").toDate(),
          to: moment().endOf("month").toDate(),
          name: `Last 6 Months (${moment()
            .subtract(5, "month")
            .format("MMMM")} - ${moment().format("MMMM YYYY")})`,
        };
        setFilter(newFilter);
        fetchData(newFilter);
        break;
      case "custom":
        setFilter(data);
        fetchData(data);
        break;
    }
  };

  const fetchData = async (dateFilter) => {
    let appointmentsData = await utils.appointmentsQuery(dateFilter);
    let transactionsData = await utils.transactionsQuery(dateFilter);
    let arrearsData = await utils.arrearsQuery(dateFilter);
    let patientsData = await utils.patientQuery(dateFilter);

    let overviewData = {
      totalAppointments: appointmentsData.length,
      grossRevenue: appointmentsData.reduce((a, b) => a + b.gross, 0),
      netRevenue: appointmentsData.reduce((a, b) => a + b.net_paid, 0),
      totalArrears: arrearsData
        .filter((a) => a.arrears > 0)
        .reduce((a, b) => a + b.arrears, 0),
      totalDebt: transactionsData.reduce((a, b) => a + b.amount_paid, 0),
      newPatients: patientsData,
    };

    let patientData = {
      totalAppointments: appointmentsData.length,
      appointmentsPaid: appointmentsData.filter(
        (el) => el.paid === true && el.amount_paid > 0
      ).length,
      uniquePatients: [
        ...new Set(appointmentsData.map((item) => item.patient_uuid)),
      ].length,
      netRevenueAppointment:
        appointmentsData.reduce((a, b) => a + b.net, 0) /
        appointmentsData.length,
      netRevenuePatient:
        appointmentsData.reduce((a, b) => a + b.net, 0) /
        [...new Set(appointmentsData.map((item) => item.patient_uuid))].length,
      appPatientPerMonth:
        appointmentsData.length /
        [...new Set(appointmentsData.map((item) => item.patient_uuid))].length,
    };

    let supplierData = {
      totalCostsPaid: transactionsData.reduce((a, b) => a + b.amount_paid, 0),
      totalDebt: transactionsData
        .filter((a) => a.paid === false)
        .reduce((a, b) => a + b.arrears, 0),
    };

    let operationalData = {
      income: overviewData.netRevenue - supplierData.totalCostsPaid,
      debt: supplierData.totalDebt,
      margin: 0,
      debtPercentage: (supplierData.totalDebt / overviewData.netRevenue) * 100,
    };

    operationalData.margin =
      (operationalData.income / overviewData.netRevenue) * 100;

    let doctorPerformance = getDoctorPerformance(appointmentsData);
    let clinicData = getClinicUtilization(appointmentsData);
    let revenueBreakdownData = getRevenueBreakdown(appointmentsData);
    let costsBreakdown = getCostsBreakdown(transactionsData);
    let typeOfCosts = getTypeOfCosts(transactionsData, overviewData.netRevenue);
    let employeePerformanceData = {
      labels: doctorPerformance.employees.map((a) => a.employeeName),
      datasets: [
        {
          label: "Gross Revenue",
          data: doctorPerformance.employees.map((a) => a.employeeGross),
          backgroundColor: "#007bff",
        },
        {
          label: "Net Revenue",
          data: doctorPerformance.employees.map((a) => a.employeeNet),
          backgroundColor: "#ffc107",
        },
      ],
    };
    let collaboratorPerformanceData = {
      labels: doctorPerformance.collaborators.map((a) => a.collaboratorName),
      datasets: [
        {
          label: "Total Gross Generated",
          data: doctorPerformance.collaborators.map((a) => a.collaboratorGross),
          backgroundColor: "#007bff",
        },
        {
          label: "Doctor's Income",
          data: doctorPerformance.collaborators.map((a) => a.collaboratorNet),
          backgroundColor: "#ffc107",
        },
      ],
    };
    let revenueBreakDownChartData = {
      labels: revenueBreakdownData.map((a) => a.paymentName),
      datasets: [
        {
          data: revenueBreakdownData.map((a) => a.sum),
          backgroundColor: revenueBreakdownData.map((a) => getRandomColor()),
        },
      ],
    };

    setOverview(overviewData);
    setPatientMetrics(patientData);
    setEmployeePerformance(employeePerformanceData);
    setCollaboratorPerformance(collaboratorPerformanceData);
    setClinicUtilization(clinicData);
    setRevenueBreakdown(revenueBreakdownData);
    setRevenueBreakdownChart(revenueBreakDownChartData);
    setSupplierMetrics(supplierData);
    setCostsBreakdown(costsBreakdown);
    setTypeOfCosts(typeOfCosts);
    setOperationalMargins(operationalData);

    let params = {
      filter: dateFilter,
      overview: overviewData,
      patientMetrics: patientData,
      doctorPerformance: doctorPerformance,
      clinicUtilization: clinicData,
      revenueBreakdown: revenueBreakdownData,
      supplierMetrics: supplierData,
      costsBreakdown: costsBreakdown,
      typeOfCosts: typeOfCosts,
      operationalMargins: operationalData,
    };

    sessionStorage.setItem("metrics", JSON.stringify(params));

    setLoaded(true);
  };

  useEffect(() => {
    let localData = getLocalData();
    props.doctors && localData === null
      ? fetchData(filter)
      : loadLocalData(localData);
  }, [props.doctors]);

  const getLocalData = () => {
    return sessionStorage.getItem("metrics");
  };

  const loadLocalData = (localData) => {
    let data = JSON.parse(localData);

    let employeePerformanceData = {
      labels: data.doctorPerformance.employees.map((a) => a.employeeName),
      datasets: [
        {
          label: "Gross Revenue",
          data: data.doctorPerformance.employees.map((a) => a.employeeGross),
          backgroundColor: "#007bff",
        },
        {
          label: "Net Revenue",
          data: data.doctorPerformance.employees.map((a) => a.employeeNet),
          backgroundColor: "#ffc107",
        },
      ],
    };
    let collaboratorPerformanceData = {
      labels: data.doctorPerformance.collaborators.map(
        (a) => a.collaboratorName
      ),
      datasets: [
        {
          label: "Total Gross Generated",
          data: data.doctorPerformance.collaborators.map(
            (a) => a.collaboratorGross
          ),
          backgroundColor: "#007bff",
        },
        {
          label: "Doctor's Income",
          data: data.doctorPerformance.collaborators.map(
            (a) => a.collaboratorNet
          ),
          backgroundColor: "#ffc107",
        },
      ],
    };
    let revenueBreakDownChartData = {
      labels: data.revenueBreakdown.map((a) => a.paymentName),
      datasets: [
        {
          data: data.revenueBreakdown.map((a) => a.sum),
          backgroundColor: data.revenueBreakdown.map((a) => getRandomColor()),
        },
      ],
    };

    setFilter(data.filter);
    setOverview(data.overview);
    setPatientMetrics(data.patientMetrics);
    setEmployeePerformance(employeePerformanceData);
    setCollaboratorPerformance(collaboratorPerformanceData);
    setClinicUtilization(data.clinicUtilization);
    setRevenueBreakdown(data.revenueBreakdown);
    setRevenueBreakdownChart(revenueBreakDownChartData);
    setSupplierMetrics(data.supplierMetrics);
    setCostsBreakdown(data.costsBreakdown);
    setTypeOfCosts(data.typeOfCosts);
    setOperationalMargins(data.operationalMargins);

    setLoaded(true);
  };

  // Metric Related functionalities.
  const showMetric = (data) => {
    let metric = loaded ? formatAmount(data) : 0;
    return metric;
  };

  const getDoctorPerformance = (data) => {
    let employees = props.doctors.filter(
      (el) => el.employmentType === "jCi5lJQL35wHxkHpAiZB"
    );
    let collaborators = props.doctors.filter(
      (el) => el.employmentType === "CtILd8u4HmIM3RaP8Z1a"
    );

    let employeeData = data.filter(
      (el) => employees.findIndex((doc) => doc.uuid === el.doctor_uuid) !== -1
    );
    let collaboratorData = data.filter(
      (el) =>
        collaborators.findIndex((doc) => doc.uuid === el.doctor_uuid) !== -1
    );

    let employeePerformance = [];
    let collaboratorPerformance = [];

    employees.map((doc) =>
      employeePerformance.push({
        employeeGross: employeeData
          .filter((el) => el.doctor_uuid === doc.uuid)
          .reduce((a, b) => a + b.gross, 0),
        employeeNet: employeeData
          .filter((el) => el.doctor_uuid === doc.uuid)
          .reduce((a, b) => a + b.net, 0),
        employeeName: doc.abbreviation,
      })
    );

    collaborators.map((doc) =>
      collaboratorPerformance.push({
        collaboratorGross: collaboratorData
          .filter((el) => el.doctor_uuid === doc.uuid)
          .reduce((a, b) => a + b.gross, 0),
        collaboratorNet: collaboratorData
          .filter((el) => el.doctor_uuid === doc.uuid)
          .reduce((a, b) => a + (b.gross - b.net), 0),
        collaboratorName: doc.abbreviation,
      })
    );

    return {
      employees: employeePerformance.filter((el) => el.employeeGross > 0),
      collaborators: collaboratorPerformance.filter(
        (el) => el.collaboratorGross > 0
      ),
    };
  };

  const getClinicUtilization = (data) => {
    let days = data.map((el) =>
      moment(el.date.toDate())
        .dayOfYear()
        .toString()
        .concat(
          moment(el.date.toDate()).month().toString(),
          moment(el.date.toDate()).year().toString()
        )
    );
    let unique = [...new Set(days)];
    return {
      daysWorked: unique.length,
      appPerDay: data.length / unique.length,
      appPerDentalPerDay: data.length / 4 / unique.length,
    };
  };

  const getRevenueBreakdown = (data) => {
    let paymentBreakdown = [];

    props.paymentMethods.map((el) =>
      paymentBreakdown.push({
        paymentName: el.title,
        sum: data
          .filter((app) => app.paymentMethod === el.uuid)
          .reduce((a, b) => a + b.gross, 0),
        totalTransactions: data.filter((app) => app.paymentMethod === el.uuid)
          .length,
      })
    );

    return paymentBreakdown;
  };

  const getCostsBreakdown = (data) => {
    let result = [];

    props.supplierPayment.map((item) => {
      result.push({
        paymentMethod: item.title,
        totalAmount: data
          .filter((a) => a.paymentType === item.uuid)
          .reduce((a, b) => a + b.amount_paid, 0),
        totalTransactions: data.filter((a) => a.paymentType === item.uuid)
          .length,
      });
    });

    return result;
  };

  const getTypeOfCosts = (data, net) => {
    let result = [];

    props.supplierSource.map((item) => {
      let currentSource = props.suppliers
        .filter((a) => a.source === item.uuid)
        .map((item) => item.uuid);

      result.push({
        source: item.title,
        amount: data
          .filter((a) => currentSource.includes(a.supplier))
          .reduce((a, b) => a + b.amount_paid, 0),
        percentage:
          (data
            .filter((a) => currentSource.includes(a.supplier))
            .reduce((a, b) => a + b.amount_paid, 0) /
            net) *
          100,
      });
    });

    return result;
  };

  return (
    <div style={{ overflow: "auto", height: "100vh" }}>
      <div className="doc-container d-flex flex-column">
        {/* Filters */}
        <div className="d-flex h-100 mb-5 mt-4">
          <h1 className="font-weight-bold">Metrics</h1>

          <div className="flex-grow-1 ml-4">
            <div class="dropdown">
              <button
                class="btn btn-secondary btn-sm dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {filter && filter.name}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" onClick={() => changeFilter("today")}>
                  Today
                </a>
                <a
                  class="dropdown-item"
                  onClick={() => changeFilter("current")}
                >
                  This Month
                </a>
                <a class="dropdown-item" onClick={() => changeFilter("last")}>
                  Last Month
                </a>
                <a class="dropdown-item" onClick={() => changeFilter("last6")}>
                  Last 6 Months
                </a>
              </div>
            </div>
          </div>

          <div className="h-100 align-self-center">
            <button
              className="btn btn-primary float-right"
              onClick={() => setDisplayCustomDate(true)}
            >
              <i className="las la-search mr-2"></i>Custom Date
            </button>
          </div>
        </div>
        {/* End Filters */}

        {/* Overview */}

        <h3 className="font-weight-bold">Overview</h3>

        <div className="card-deck">
          <MetricsCard
            number={showMetric(overview.totalAppointments)}
            title="Total Appointments"
            icon="las la-book card-icon card-icon-purple"
            tooltip="Total number of appointments completed"
          />

          <MetricsCard
            number={showMetric(overview.grossRevenue) + " €"}
            title="Gross Revenue (paid)"
            icon="las la-euro-sign card-icon card-icon-light-green"
            tooltip="Gross Revenue from paid appointments (What the clinic should have earned if everyone paid in full)"
          />

          <MetricsCard
            number={showMetric(overview.netRevenue) + " €"}
            title="Net Revenue (of paid)"
            icon="las la-coins card-icon card-icon-purple"
            tooltip="Net Revenue from paid appointments (What the clinic has earned after percentages have been paid)"
          />
        </div>

        <div className="card-deck mt-3">
          <MetricsCard
            number={showMetric(overview.totalArrears) + " €"}
            title="Total Arrears"
            icon="las la-hand-holding-usd card-icon card-icon-purple"
            tooltip="Total Arrears added from patients"
          />

          <MetricsCard
            number={showMetric(overview.totalDebt) + " €"}
            title="Total Supplier Costs Paid"
            icon="las la-box card-icon card-icon-purple"
            tooltip="Total Supplier Costs Paid"
          />

          <MetricsCard
            number={showMetric(overview.newPatients)}
            title="New patients"
            icon="las la-user card-icon card-icon-purple"
            tooltip="Number of new patients that have been added during the set timeline"
          />
        </div>

        {/* End Overview */}

        {/* Doctor's Performance */}

        <h3 className="font-weight-bold mt-5">Doctor's performance</h3>

        <div className="card-deck my-2">
          <div className="card table-card p-5">
            <h2 className="font-weight-bold">Employees</h2>

            <div>
              <HorizontalBar
                data={employeePerformance}
                options={{
                  scales: {
                    xAxes: [
                      {
                        display: true,
                        ticks: {
                          beginAtZero: true, // minimum value will be 0.
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
          </div>

          <div className="card table-card p-5">
            <h2 className="font-weight-bold">Collaborators</h2>

            <div>
              <HorizontalBar
                data={collaboratorPerformance}
                options={{
                  scales: {
                    xAxes: [
                      {
                        display: true,
                        ticks: {
                          beginAtZero: true, // minimum value will be 0.
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
          </div>
        </div>

        {/* End of Doctor's Performance */}

        {/* Patient Metrics */}

        <h3 className="font-weight-bold mt-5">Patient Metrics</h3>

        <div className="card-deck mt-3">
          <MetricsCard
            number={showMetric(patientMetrics.totalAppointments)}
            title="Total Appointments"
            icon="las la-book card-icon card-icon-purple"
            tooltip="Total number of appointments completed"
          />

          <MetricsCard
            number={showMetric(patientMetrics.appointmentsPaid)}
            title="Appointments paid"
            icon="las la-euro-sign card-icon card-icon-light-green"
            tooltip="Total number of appointments where the patient paid the exact amount of the appointment, or more (to cover arrears)"
          />

          <MetricsCard
            number={showMetric(patientMetrics.uniquePatients)}
            title="Unique patients"
            icon="las la-coins card-icon card-icon-purple"
            tooltip="Number of unique patients that had appointments during the set time period"
          />
        </div>

        <div className="card-deck mt-3">
          <MetricsCard
            number={showMetric(patientMetrics.netRevenueAppointment) + "€"}
            title="Net Revenue per appointment"
            icon="las la-book card-icon card-icon-purple"
            tooltip="Average Net Revenue from selected appointments"
          />

          <MetricsCard
            number={showMetric(patientMetrics.netRevenuePatient) + "€"}
            title="Net Revenue per patient"
            icon="las la-euro-sign card-icon card-icon-light-green"
            tooltip="Average Net Revenue per patient. Differs from the previous metric, because some patients may have multiple appointments"
          />

          <MetricsCard
            number={showMetric(patientMetrics.appPatientPerMonth)}
            title="Appointments per patient per month"
            icon="las la-coins card-icon card-icon-purple"
            tooltip="Average number of appointments completed per patient / per month"
          />
        </div>

        {/* End of Patient Metrics */}

        {/* Clinic Utilization */}

        <h3 className="font-weight-bold mt-5">Clinic Utilization</h3>

        <div className="card-deck mt-3">
          <MetricsCard
            number={showMetric(clinicUtilization.daysWorked)}
            title="Days Worked"
            icon="las la-book card-icon card-icon-purple"
            tooltip="Total Days Worked"
          />

          <MetricsCard
            number={showMetric(Math.round(clinicUtilization.appPerDay))}
            title="Appointments per day"
            icon="las la-euro-sign card-icon card-icon-light-green"
            tooltip="Average appointments per day"
          />

          <MetricsCard
            number={showMetric(4)}
            title="Number of dental units"
            icon="las la-coins card-icon card-icon-purple"
            tooltip="This is a constant value set to 4"
          />

          <MetricsCard
            number={showMetric(
              Math.round(clinicUtilization.appPerDentalPerDay)
            )}
            title="Appointments per dental unit per day"
            icon="las la-coins card-icon card-icon-purple"
          />
        </div>

        {/* End of Clinic Utilization */}

        {/* Net Revenue Breakdown */}

        <h3 className="font-weight-bold mt-5">Net Revenue breakdown</h3>

        <div className="card-deck mt-3">
          <div className="card table-card p-5">
            <Doughnut data={revenueBreakdownChart} />
          </div>

          <div className="card table-card p-2">
            <table
              className="table table-borderless"
              style={{ tableLayout: "fixed" }}
            >
              <caption>Analytical Breakdown</caption>
              <thead>
                <tr>
                  <th scope="col">Payment Type</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Total Transactions</th>
                </tr>
              </thead>

              <tbody>
                {Array.isArray(revenueBreakdown) &&
                  revenueBreakdown
                    .sort((a, b) => b.sum - a.sum)
                    .map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.paymentName}</td>
                          <td>{item.sum + " €"}</td>
                          <td>{item.totalTransactions}</td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>

        {/* End of Net Revenue Breakdown */}

        {/* Supplier Costs */}

        <h3 className="font-weight-bold mt-5">Supplier Costs</h3>

        <div className="card-deck mt-3">
          <MetricsCard
            number={showMetric(supplierMetrics.totalCostsPaid) + "€"}
            title="Total Costs Paid"
            icon="las la-book card-icon card-icon-purple"
            tooltip="Total amount paid to supplier transactions"
          />

          <MetricsCard
            number={showMetric(-supplierMetrics.totalDebt) + "€"}
            title="Total Debt Incurred"
            icon="las la-euro-sign card-icon card-icon-light-green"
            tooltip="Total amount of added debt from supplier transactions"
          />
        </div>

        <div className="card-deck mt-4">
          {/* Costs breakdown */}
          <div className="card table-card">
            <table
              className="table table-borderless"
              style={{ tableLayout: "fixed" }}
            >
              <caption>Costs Breakdown</caption>
              <thead>
                <tr>
                  <th scope="col">Payment Type</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Total Transactions</th>
                </tr>
              </thead>

              <tbody>
                {Array.isArray(costsBreakdown) &&
                  costsBreakdown
                    .sort((a, b) => b.totalAmount - a.totalAmount)
                    .map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.paymentMethod}</td>
                          <td>{formatAmount(item.totalAmount) + "€"}</td>
                          <td>{item.totalTransactions}</td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>

          {/* Costs breakdown */}
          <div className="card table-card">
            <table
              className="table table-borderless"
              style={{ tableLayout: "fixed" }}
            >
              <caption>Type of Costs paid</caption>
              <thead>
                <tr>
                  <th scope="col">Source</th>
                  <th scope="col">Amount</th>
                  <th scope="col">% of Revenues</th>
                </tr>
              </thead>

              <tbody>
                {Array.isArray(typeOfCosts) &&
                  typeOfCosts
                    .sort((a, b) => b.amount - a.amount)
                    .map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.source}</td>
                          <td>{formatAmount(item.amount) + "€"}</td>
                          <td>{formatAmount(item.percentage) + "%"}</td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>

        {/* End of Supplier Costs */}

        {/* Operational Margins */}

        <h3 className="font-weight-bold mt-5">
          Operational Margins{" "}
          <i
            className={
              locked
                ? "las la-lock btn-danger btn-sm"
                : "las la-unlock btn-warning btn-sm"
            }
            onClick={triggerPIN}
          ></i>
        </h3>
        <p className="text-muted">
          (Before Tax, Depreciation, Investments, Rent)
        </p>

        <div
          className={locked ? "card-deck mt-3 blurred-card" : "card-deck mt-3"}
        >
          <MetricsCard
            number={locked ? 0 : showMetric(operationalMargins.income) + "€"}
            title="Income"
            icon="las la-hand-holding-usd card-icon card-icon-purple"
            tooltip="Net Revenue / Total Supplier Costs Paid"
          />

          <MetricsCard
            number={locked ? 0 : showMetric(-operationalMargins.debt) + "€"}
            title="Debt"
            icon="las la-hand-holding-usd card-icon card-icon-purple"
            tooltip="Total Debt Added from Supplier transactions"
          />

          <MetricsCard
            number={locked ? 0 : showMetric(operationalMargins.margin) + "%"}
            title="Margin"
            icon="las la-box card-icon card-icon-purple"
            tooltip="Income / Net Revenue"
          />

          <MetricsCard
            number={
              locked ? 0 : showMetric(operationalMargins.debtPercentage) + "%"
            }
            title="Debt as part of Revenues"
            icon="las la-box card-icon card-icon-purple"
            tooltip="Total Supplier Debt Added / Net Revenue"
          />
        </div>

        <div className="my-3" />

        {/* End of Operational Margins */}
      </div>

      <PINModal
        display={displayPIN}
        trigger={triggerPIN}
        unlockTrigger={() => setLocked(false)}
      />

      <MetricsModal
        display={displayCustomDate}
        modalTrigger={() => setDisplayCustomDate(false)}
        changeFilter={changeFilter}
      />
    </div>
  );
}

export default MetricsV2;
