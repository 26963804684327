import React, { Component } from 'react';
import Select from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import Toggle from 'react-toggle';
import moment from 'moment';
import firebase from '../../firebase';

const firebaseRef = firebase.firestore();

class SupplierTransModal extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            appdate: new Date(),
            dateChanged: false,
            supplier: null,
            amount: null,
            amountPaid: null,
            clinicPaid: null,
            paymentType: null,
            notes: null
        }

        this.baseState = this.state;
    }

    
    componentDidMount() {
        let modal = document.getElementById("myModal7");

        //Close modal on window click.
        window.onclick = (event) => {
            if (event.target === modal) {
                this.closeModal();
            }
        }
    }


    closeModal = () => {
        this.setState(this.baseState);
        this.props.modalTrigger();
    }


    updateDate = (e) => {
        this.setState({ appdate: e });

        if (this.props.transaction) {
            this.setState({ dateChanged: true });
        }
    }


    updateSupplier = (value) => {
        this.setState({ supplier: value });
    }


    updatePaymentType = (value) => {
        this.setState({ paymentType: value });
    }

    
    updateAmount = (e, type) => {
        let value = e.target.value;
        const clinicPaid = this.state.clinicPaid;

        if (value !== '') {
            value = parseFloat(value);
        }

        if (type === 'cost') {
            this.setState({ amount: value });
            
            if (this.props.transaction) {
                //We are in Edit mode.
                if (clinicPaid === null) {
                    //Get property from props.
                    if (this.props.transaction.paid) {
                        this.setState({ amountPaid: value });
                    }
                }else {
                    if (clinicPaid || clinicPaid === null) {
                        this.setState({ amountPaid: value });
                    }
                }
            }else {
                //We are adding a new transaction.
                if (clinicPaid || clinicPaid === null) {
                    this.setState({ amountPaid: value });
                }
            }
        } else if (type === 'paid') {
            this.setState({ amountPaid: value });
        }
    }


    updateNote = (e) => {
        let value = e.target.value;

        this.setState({ notes: value });
    }


    updatePaid = (e) => {
        let value = e.target.checked;

        if (value) {
            if (this.props.transaction) {
                //We are in Edit mode.
                if (this.state.amount === null) {
                    this.setState({ amountPaid: this.props.transaction.amount });
                }else {
                    this.setState({ amountPaid: this.state.amount });
                }
            }else {
                //We are adding a new Transaction.
                this.setState({ amountPaid: this.state.amount });
            }
        }

        this.setState({ clinicPaid: value });
    }

    
    saveTransaction = () => {
        this.setState({ loading: true });

        let date = this.state.appdate;
        let supplier = this.state.supplier;
        let paymentType = this.state.paymentType;
        let amount = this.state.amount;
        let amountPaid = parseFloat(this.state.amountPaid) || 0;
        let clinicPaid = this.state.clinicPaid;
        let notes = this.state.notes;

        let data = {};

        data.date = date;
        
        if (supplier !== null) {
            data.supplier = supplier.value;
        }else {
            alert('You need to select a supplier first.');
            this.setState({ loading: false });
            return;
        }


        if (paymentType !== null ) {
            data.paymentType = paymentType.value;
        }

        if (amount) {
            data.amount = parseFloat(amount);
        }else {
            data.amount = 0;
        }



        if (clinicPaid === null) {
            clinicPaid = true;
        }

        //Calculate arrears.
        if (clinicPaid) {
            data.arrears = 0;
            data.paid = true;
            data.amount_paid = data.amount;
        }else {
            if (amount - amountPaid > 0) {
                //Paid less.
                data.amount_paid = amountPaid;
                data.paid = false;
                data.arrears = data.amount - data.amount_paid;
            }else if (amount - amountPaid < 0) {
                //Paid more.
                data.amount_paid = amountPaid;
                data.paid = true;
                data.arrears = data.amount - data.amount_paid;
            }else {
                //Clinic paid exact amount but switch was not used.
                data.amount_paid = amountPaid;
                data.paid = true;
                data.arrears = 0;
            }
        }


        // if (amount - amountPaid === 0) {
        //     data.paidGross = true;
        // }else {
        //     data.paidGross = false;
        // }

        data.notes = notes;
        data.timestamp_created = firebase.firestore.FieldValue.serverTimestamp();
        data.type = 'supplier';


        // console.log(data);

        const ref = firebaseRef.collection('transactions').doc();
        const key = ref.id;
        const supplierRef = firebaseRef.collection('suppliers').doc(data.supplier);

        data.uuid = key;

        let stateData = {};

        return firebaseRef.runTransaction(transaction => {
            return transaction.get(supplierRef).then(snapshot => {
                if (snapshot.exists) {
                    let oldArrears = snapshot.data().total_arrears || 0;
                    let hasArrears = false;

                    oldArrears = oldArrears + data.arrears;

                    if (oldArrears > 0) {
                        //Has arrears after transaction.
                        hasArrears = true;
                    }

                    stateData.total_arrears = oldArrears;
                    stateData.hasArrears = hasArrears;

                    data.current_arrears = oldArrears;
                    
                    transaction.update(supplierRef, { 'total_arrears': oldArrears, 'hasArrears': hasArrears });
                    transaction.set(ref, data);
                }
            })
        }).then(() => {
            // console.log('Success!!!');
            this.props.addSupplierTransaction(data, undefined ,stateData);
            this.props.showToast('success', 'Transaction saved!');
            this.closeModal();
            this.setState({ loading: false });
        }).catch((error) => {
            this.setState({ loading: false });
            this.props.showToast('error', 'There was an error...');
            console.log(error);
        })
    }


    updateTransaction = async () => {
        this.setState({ loading: true });

        let date = this.state.appdate;
        let dateChanged = this.state.dateChanged;
        let paymentType = this.state.paymentType;
        let amount = this.state.amount;
        let amountPaid = this.state.amountPaid;
        let clinicPaid = this.state.clinicPaid;
        let notes = this.state.notes;

        let data = {};


        //Check date.
        if (dateChanged) {
            data.date = date;
        }

        //Check Payment type.
        if (paymentType) {
            data.paymentType = paymentType.value;
        }

        //Amount of cost.
        if (amount !== null && amount !== '') {
            data.amount = parseFloat(amount);
        }else {
            data.amount = parseFloat(this.props.transaction.amount);
        }

        amount = data.amount;


        //Clinic paid.
        if (clinicPaid === null) {
            clinicPaid = amount >= amountPaid;
        }

        data.paid = clinicPaid;

        //Amount paid.
        if (amountPaid === null) {
            if (clinicPaid) {
                amountPaid = parseFloat(amount);
            }else {
                amountPaid = parseFloat(this.props.transaction.amount_paid);
            }            
        }else {
            //Amount paid has been changed.
            if (amountPaid) {
                if (clinicPaid) {
                    amountPaid = parseFloat(amount);
                }else {
                    amountPaid = parseFloat(amountPaid);
                }
            }else {
                amountPaid = 0;
            }
        }

        if (amount - amountPaid <= 0) {
            //Suplus.
            data.paid = true;
        }

        data.amount_paid = amountPaid;

        //Notes.
        if (notes !== null) {
            if (notes === '') {
                data.notes = null;
            }else {
                data.notes = notes;
            }
        }

        //Arrears.
        data.arrears = parseFloat(amount - amountPaid);

        data.timestamp_updated = firebase.firestore.FieldValue.serverTimestamp();

        const ref = firebaseRef.collection('transactions').doc(this.props.transaction.uuid);
        const supplierRef = firebaseRef.collection('suppliers').doc(this.props.transaction.supplier);

        let stateData = {};
        Object.assign(stateData, this.props.transaction);
        stateData = {...stateData, ...data};
        
        if (dateChanged) {
            stateData.date = firebase.firestore.Timestamp.fromDate(stateData.date);
        }


        let arrearsData = {};

        // Find all transactions that have to change.
        let updateDocs = [];

        if (stateData.current_arrears !== undefined) {
            let doc = await firebaseRef.collection('transactions').where('type', '==', 'supplier').where('supplier', '==', stateData.supplier).where('date', '>=', stateData.date).orderBy('date', 'asc').orderBy('timestamp_created', 'asc').get();

            if (!doc.empty) {
                let docs = doc.docs;    
                docs.forEach(snapshot => {
                    ((snapshot.data().uuid !== stateData.uuid) && 
                    (snapshot.data().timestamp_created.toDate() > stateData.timestamp_created.toDate())) 
                    && updateDocs.push(snapshot.data());
                })
                // console.log(updateDocs);
            }
        }


        // console.log(data);
        return firebaseRef.runTransaction(transaction => {
            return transaction.get(supplierRef).then(snapshot => {
                if (snapshot.exists) {
                    let oldArrears = snapshot.data().total_arrears || 0;
                    let currentArrears = this.props.transaction.arrears;
                    let amount = data.amount;
                    let hasArrears = false;

                    let newArrears = amount - amountPaid;

                    //Calculate new arrears.
                    if (clinicPaid) {
                        oldArrears = oldArrears - currentArrears;
                    }else {
                        if (amount - amountPaid > 0) {
                            //Paid less.
                            oldArrears = oldArrears + (newArrears - currentArrears);
                        }else if (amount - amountPaid < 0) {
                            //Paid more.
                            oldArrears = oldArrears + (newArrears - currentArrears);
                        }
                    }

                    if (oldArrears < 0) {
                        hasArrears = true;
                    }

                    arrearsData.total_arrears = oldArrears;
                    arrearsData.hasArrears = hasArrears;

                    if (stateData.current_arrears !== undefined) {
                        if (newArrears === 0) {
                            // console.log('newArrears = 0')
                            data.current_arrears = stateData.current_arrears - currentArrears;
                        }else if (newArrears > currentArrears) {
                            // console.log('newArrears > currentArrears')
                            data.current_arrears = (newArrears - currentArrears) + stateData.current_arrears;
                        }else {
                            // console.log('newArrears < 0')
                            data.current_arrears = stateData.current_arrears + newArrears - currentArrears;
                        }
                    }

                    // console.log('Data before insertion', data)
                    
                    // Update all subsequent transactions' data.
                    if (updateDocs.length > 0) {
                        console.log('UPDATING SUBSEQUENT TRANSACTIONS');
                        updateDocs.forEach(item => {
                            let newCurrent;
                            if (newArrears === 0) {
                                // console.log('newArrears = 0')
                                newCurrent = item.current_arrears - currentArrears;
                            }else if (newArrears > currentArrears) {
                                // console.log('newArrears > currentArrears')
                                newCurrent = (newArrears - currentArrears) + item.current_arrears;
                            }else {
                                // console.log('newArrears < 0')
                                newCurrent = item.current_arrears + newArrears - currentArrears;
                            }
                            item.current_arrears = newCurrent;
                            transaction.update(firebaseRef.collection('transactions').doc(item.uuid), { 'current_arrears': newCurrent });
                        })
                    }

                    transaction.update(supplierRef, { 'total_arrears': oldArrears, 'hasArrears': hasArrears });
                    transaction.update(ref, data);
                }
            })
        }).then(() => {
            console.log('Success!!!');
            // Update stateData with new current_arrears data.
            if (stateData.current_arrears !== undefined) {
                stateData = {
                    ...stateData,
                    current_arrears: data.current_arrears
                }
            }
            this.props.updateSupplierTransaction(stateData, arrearsData, updateDocs);
            this.props.showToast('success', 'Transaction saved!');
            this.closeModal();
            this.setState({ loading: false });
        }).catch((error) => {
            this.setState({ loading: false });
            this.props.showToast('error', 'There was an error...');
            console.log(error);
        })
    }

    
    render() {
        let supplierOptions = [];
        let paymentOptions = [];

        if (this.props.suppliers !== null) {
            this.props.suppliers.map(item => {
                supplierOptions.push({
                    value: item.uuid,
                    label: item.title
                });
            })
        }


        if (this.props.paymentMethods !== null) {
            this.props.paymentMethods.map(item => {
                paymentOptions.push({
                    value: item.uuid,
                    label: item.title
                })
            })
        }


        return (
            <div id="myModal7" className={this.props.display ? "modal show" : "modal"}>
            <div className="modal-content shadow">

                <div className="d-flex mb-3">
                    <p className="flex-grow-1 h1 font-weight-bold">{this.props.transaction ? "Edit Transaction" : "New Transaction"}</p>
                    <span className="close" onClick={this.closeModal}>&times;</span>
                </div>

                {this.props.transaction ?
                    <span className="font-weight-bold h5 mb-3">Current Total Balance: <span>{this.props.transaction ?  -this.props.suppliers[this.props.suppliers.findIndex(el => el.uuid === this.props.transaction.supplier)].total_arrears + "€" : null}</span></span>
            
                : null

                }


                <div className='form-row'>
                    <div className="col">
                        <label for="appdate">Transaction date: <span className="font-weight-bold">{this.props.transaction && this.state.dateChanged === false ? moment(this.props.transaction.date.toDate()).format('dddd, MMMM Do') : moment(this.state.appdate).format('dddd, MMMM Do')}</span></label>
                        <br></br>
                        <DayPickerInput 
                            value={this.props.transaction && this.state.dateChanged === false ? this.props.transaction.date.toDate() : this.state.appdate}
                            onDayChange={day => this.updateDate(day)} />
                    </div>


                    <div className='col'>
                        <label>Supplier:</label>
                        <Select 
                            value={this.props.transaction && this.state.supplier === null ? supplierOptions[supplierOptions.findIndex(el => el.value === this.props.transaction.supplier)] : this.state.supplier}
                            options={supplierOptions}
                            placeholder="Please select a supplier"
                            isDisabled={this.props.transaction ? true : false}
                            onChange={this.updateSupplier} />
                    </div>

                </div>


                {/* Second Row */}
                <div className='form-row mt-4'>
                    <div className='col'>
                        <label>Payment Type:</label>
                        <Select 
                            value={this.props.transaction && this.state.paymentType === null ? paymentOptions[paymentOptions.findIndex(el => el.value === this.props.transaction.paymentType)] : this.state.paymentType}
                            options={paymentOptions}
                            placeholder="Please select a payment type"
                            onChange={this.updatePaymentType} />
                    </div>


                    <div className='col'>
                        <label>Transaction cost (€)</label>
                        <input 
                            type="number" 
                            value={this.props.transaction && this.state.amount === null ? this.props.transaction.amount : this.state.amount === null ? '' : this.state.amount}
                            onInput={(e) => this.updateAmount(e, 'cost')}
                            className="form-control"
                            placeholder="Enter transaction amount" 
                            required />
                    </div>
                </div>




                {/* Third row */}
                <div className='form-row mt-4'>
                    <div className='col'>
                        <label>Amount paid (€)</label>
                        <input 
                            type="number" 
                            value={this.props.transaction && this.state.amountPaid === null ? this.props.transaction.amount_paid : this.state.amountPaid}
                            onInput={(e) => this.updateAmount(e, 'paid')}
                            className="form-control"
                            disabled={this.props.transaction && this.state.clinicPaid === null ? this.props.transaction.amount === this.props.transaction.amount_paid ? true : false : this.state.clinicPaid !== null ? this.state.clinicPaid ? true : false : true}
                            placeholder="Enter the amount we paid..." 
                            required />
                    </div>


                    <div className="col">
                        <label className="mt-1 mb-2">
                            Did we pay <span className="font-weight-bold">exactly {this.props.transaction && this.state.amount === null ? this.props.transaction.amount + '€' : this.state.amount === null ? "0€" : this.state.amount + '€'} ?</span> 
                            <span className="font-weight-bold">
                                {this.props.transaction && this.state.clinicPaid === null ? this.props.transaction.amount === this.props.transaction.amount_paid ? "YES" : "NO" : this.state.clinicPaid || this.state.clinicPaid === null ? "YES" : "NO" }
                            </span>
                        </label>
                        <br></br>
                        <Toggle 
                            defaultChecked={true}
                            checked={this.props.transaction && this.state.clinicPaid === null ? this.props.transaction.amount === this.props.transaction.amount_paid ? true : false : this.state.clinicPaid || this.state.clinicPaid === null ? true : false}
                            onChange={(e) => this.updatePaid(e)} />
                    </div>
                </div>





                {/* Fourth Row */}
                <div className='mt-4'>
                    <label>Additional notes</label>
                    <textarea 
                        className="form-control" 
                        style={{ resize: 'none' }} 
                        rows="3"
                        placeholder="Enter any additional notes here..."
                        value={this.props.transaction && this.state.notes === null ? this.props.transaction.notes : this.state.notes === null ? "" : this.state.notes}
                        onInput={(e) => this.updateNote(e)} ></textarea>
                </div>


                <div className='mt-4'>
                    {this.state.loading ?
                                
                        <button className="btn btn-primary float-right" type="button" disabled>
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>

                        : 
                        
                        this.props.transaction ?

                        <button type="button" className="btn btn-primary float-right" onClick={this.updateTransaction}>
                            Update Transaction
                        </button>

                        :

                        <button type="button" className="btn btn-primary float-right" onClick={this.saveTransaction}>
                            Save Transaction
                        </button>

                    }
                </div>

            </div>
            </div>
        )
    }
}



export default SupplierTransModal;