import React, { Component } from 'react';
import SettingsMenu from '../Settings/SettingsMenu';
import SettingsTab from '../Settings/SettingsTab';

class Settings extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             selected: 1
        }
    }
    

    changeSelection = (id) => {
        this.setState({ selected: id });
    }


    render() {
        return (
            <div className="container">
                <h1 className="display-4 font-weight-bold mb-5">Settings</h1>

                <div class="row">
                    <div class="col-3">
                        <SettingsMenu 
                            selected={this.state.selected}
                            onClick={this.changeSelection} />
                    </div>

                    <div class="col-9">
                        <SettingsTab
                            selected={this.state.selected}
                            specialties={this.props.specialties}
                            paymentMethods={this.props.paymentMethods}
                            addSpecialty={this.props.addSpecialty}
                            deleteSettings={this.props.deleteSettings}
                            editSettings={this.props.editSettings}
                            employmentType={this.props.employmentType}
                            supplierSource={this.props.supplierSource}
                            addEmployment={this.props.addEmployment}
                            addSettings={this.props.addSettings}
                            showToast={this.props.showToast}
                            user={this.props.user} />
                    </div>
                </div>
            </div>
        )
    }
}



export default Settings;