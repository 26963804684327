import React, { Component } from "react";
// Components.
import SupplierView from "./SupplierView";
import SupplierModal from "../Modals/SupplierModal";
import SupplierTransModal from "../Modals/SupplierTransModal";
import SupplierTable from "./SupplierTable";
import DebtModal from "../Modals/DebtModal";
// Firebase.
import firebase from "../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regularIcons } from "icons/icons";

const firebaseRef = firebase.firestore();

class Suppliers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: 1,
      display: false,
      displayTrans: false,
      supplier: null,
      transaction: null,
      showInactive: null,
      modalDisplay: false,
    };
  }

  triggerModal = (data) => {
    switch (this.state.selected) {
      case 1:
        console.log("Trigger trans modal");
        if (this.state.displayTrans && this.state.transaction) {
          this.setState({ transaction: null });
        } else {
          if (data) {
            this.setState({ transaction: data });
          } else {
            this.setState({ transaction: null });
          }
        }

        this.setState({ displayTrans: !this.state.displayTrans });
        break;
      case 2:
        if (this.state.display && this.state.supplier) {
          this.setState({ supplier: null });
        } else {
          if (data) {
            this.setState({ supplier: data });
          } else {
            this.setState({ supplier: null });
          }
        }

        this.setState({ display: !this.state.display });
        break;
    }
  };

  triggerDebt = () => {
    this.setState({ modalDisplay: !this.state.modalDisplay });
  };

  updateSelected = (value) => {
    // let value = e.target.checked;

    if (value) {
      this.setState({ selected: 2 });
    } else {
      this.setState({ selected: 1 });
    }
  };

  deleteAllTransactions = () => {
    let batch = firebaseRef.batch();

    firebaseRef
      .collection("transactions")
      .where("type", "==", "supplier")
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach((childSnapshot) => {
            batch.delete(
              firebaseRef
                .collection("transactions")
                .doc(childSnapshot.data().uuid)
            );
          });

          batch.commit().then(() => {
            console.log("Data has been wiped out.");
          });
        }
      });
  };

  triggerInactive = () => {
    this.setState({ showInactive: !this.state.showInactive });
  };

  render() {
    return (
      <div style={{ overflow: "auto", height: "100vh", paddingBottom: "5vh" }}>
        <div className="doc-container d-flex flex-column">
          <div className="d-flex">
            <h1 className="font-weight-bold my-4 flex-fill">
              Suppliers
              <button
                type="button"
                className="btn btn-sm btn-primary ml-3"
                onClick={() => this.triggerModal()}
              >
                {this.state.selected === 1 ? "New Transaction" : "New Supplier"}
              </button>
              {this.state.selected === 2 ? (
                <button
                  tyoe="button"
                  className="btn btn-sm btn-warning ml-3"
                  onClick={this.triggerInactive}
                >
                  {this.state.showInactive
                    ? "Show Active Suppliers"
                    : "Show Inactive Suppliers"}
                </button>
              ) : null}
            </h1>

            <div className="flex-grow-1" />

            <div className="h-100 align-self-center">
              <button
                type="button"
                className="btn btn-sm btn-danger d-flex align-items-center"
                onClick={() => this.triggerDebt()}
              >
                <FontAwesomeIcon className="mr-2" icon={regularIcons.COINS} />
                Debt
              </button>
            </div>
          </div>

          <nav className="mb-1">
            <div
              className="nav nav-tabs nav-justified"
              id="nav-tab"
              role="tablist"
            >
              <a
                className="nav-item nav-link sup-tab active"
                id="nav-home-tab"
                data-toggle="tab"
                href="#nav-home"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
                onClick={() => this.updateSelected(false)}
              >
                Transactions
              </a>

              <a
                className="nav-item nav-link sup-tab"
                id="nav-profile-tab"
                data-toggle="tab"
                href="#nav-profile"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
                onClick={() => this.updateSelected(true)}
              >
                Archive
              </a>
            </div>
          </nav>

          {this.state.selected === 1 ? (
            <div>
              <SupplierTable
                data={this.props.supplierData}
                suppliers={this.props.suppliers}
                paymentMethods={this.props.paymentMethods}
                addSupplierTransaction={this.props.addSupplierTransaction}
                resetSupplierTransactions={this.props.resetSupplierTransactions}
                supplierSource={this.props.supplierSource}
                modalTrigger={this.triggerModal}
                deleteSupplierTransaction={this.props.deleteSupplierTransaction}
                loadSupplierData={this.props.loadSupplierData}
              />

              <SupplierTransModal
                display={this.state.displayTrans}
                modalTrigger={this.triggerModal}
                suppliers={this.props.suppliers}
                addSupplierTransaction={this.props.addSupplierTransaction}
                updateSupplierTransaction={this.props.updateSupplierTransaction}
                paymentMethods={this.props.paymentMethods}
                showToast={this.props.showToast}
                transaction={this.state.transaction}
              />
            </div>
          ) : this.state.selected === 2 ? (
            <div>
              <SupplierView
                suppliers={
                  this.props.suppliers !== null
                    ? this.state.showInactive
                      ? this.props.suppliers.filter(
                          (el) => el.status === "inactive"
                        )
                      : this.props.suppliers.filter(
                          (el) => el.status !== "inactive"
                        )
                    : null
                }
                supplierSource={this.props.supplierSource}
                modalTrigger={this.triggerModal}
                deleteSupplier={this.props.deleteSupplier}
                deactivateSupplier={this.props.deactivateSupplier}
                reactivateSupplier={this.props.reactivateSupplier}
              />

              <SupplierModal
                display={this.state.display}
                modalTrigger={this.triggerModal}
                supplierSource={this.props.supplierSource}
                addSupplier={this.props.addSupplier}
                updateSupplier={this.props.updateSupplier}
                showToast={this.props.showToast}
                supplier={this.state.supplier}
              />
            </div>
          ) : null}

          <DebtModal
            display={this.state.modalDisplay}
            modalTrigger={this.triggerDebt}
            suppliers={this.props.suppliers}
          />
        </div>
      </div>
    );
  }
}

export default Suppliers;
