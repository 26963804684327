import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { duotoneIcons } from "../icons/icons";

export interface MenuItem {
  id: number;
  title: string;
  icon: IconDefinition;
}

const menu: Array<MenuItem> = [
  {
    id: 1,
    title: "Appointments",
    icon: duotoneIcons.BOOK,
  },
  {
    id: 2,
    title: "Doctors",
    icon: duotoneIcons.STETHOSCOPE,
  },
  {
    id: 3,
    title: "Patients",
    icon: duotoneIcons.USER,
  },
  {
    id: 4,
    title: "Suppliers",
    icon: duotoneIcons.BOX,
  },
  {
    id: 5,
    title: "Metrics",
    icon: duotoneIcons.METRICS,
  },
  {
    id: 6,
    title: "Settings",
    icon: duotoneIcons.SETTINGS,
  },
];

export default menu;
