import React, { useState, useContext } from 'react';
// Components.
import Step1 from './ModalComponents/Step1';
import Step2 from './ModalComponents/Step2';
import Step3 from './ModalComponents/Step3';
import StepFooter from './ModalComponents/StepFooter';
// Context.
import { appointmentContext } from '../../context/appointmentContext';

function AppointmentModalV2(props) {
    const [step, setStep] = useState(1);

    const appointmentCon = useContext(appointmentContext);
    const { dispatch, state } = appointmentCon;

    const closeModal = () => {
        dispatch({ type: 'CLEAR_STATE' });
        setStep(1);
        props.modalTrigger(false);
    }

    return (
        <div id="myModal3" className={props.display ? "modal show" : "modal"}>
            <div className="modal-content shadow">

                <div className="d-flex mb-4">
                    <p className="flex-grow-1 h1 font-weight-bold">{props.patient ? "Edit Appointment" : "New Appointment"}</p>
                    <span className="close" onClick={closeModal}>&times;</span>
                </div>

                {/* Stepper */}
                <div className='d-flex mb-3'>
                    <div className='step'>
                        <span className='step-number'>1</span>
                        <span className='step-label'>Patient</span>
                    </div>

                    <div className='step-line mx-3'></div>

                    <div className="step">
                        <span className={step >= 2 ? 'step-number' : 'step-number step-disabled'}>2</span>
                        <span className='step-label'>Appointment</span>
                    </div>

                    <div className='step-line mx-3'></div>

                    <div className='step'>
                        <span className={step >= 3 ? 'step-number' : 'step-number step-disabled'}>3</span>
                        <span className='step-label'>Payment</span>
                    </div>
                </div>
                {/* End Stepper */}


                {/* Step Instructions */}
                <div className="mt-3">
                    {step === 1 ?
                        <p>Search for the patient using their name / phone number / email.</p>
                    :step === 2 ?
                        <p>Please enter the details for the appointment.</p>
                    : null
                    }
                </div>
                {/* End Instructions */}


                { step === 1 ? <Step1 doctors={props.doctors} /> 
                : step === 2 ? <Step2 doctors={props.doctors} /> 
                : step === 3 ? <Step3 paymentMethods={props.paymentMethods} /> 
                : null }


                <StepFooter 
                    step={step} 
                    selectPatient={state.selectedPatient?.uuid} 
                    setStep={setStep} 
                    user={props.user} 
                    addAppointment={props.addAppointment} 
                    showToast={props.showToast} 
                    closeModal={closeModal} />

            </div>
        </div>
    )
}



export default AppointmentModalV2;