import { useCallback } from "react";
import firebase from "../../../firebase";
// Components.
import NavbarItem from "./NavbarItem";
import menu from "../../../constants/menu";
// Icons.
import { duotoneIcons } from "../../../icons/icons";

type Props = {};

const Navbar: React.FC<Props> = (props) => {
  const {} = props;

  const signOut = useCallback(() => {
    firebase.auth().signOut();
  }, []);

  return (
    <div className="navbar d-flex flex-column px-4 pt-5">
      <div>
        {menu.map((item) => {
          return <NavbarItem key={item.id} data={item} />;
        })}
      </div>
      <NavbarItem
        data={{ id: 100, title: "Sign Out", icon: duotoneIcons.SIGN_OUT }}
        onPressListener={() => signOut()}
      />
    </div>
  );
};

export default Navbar;
